const FormatThousands = (value: string) => {
  if (value || value !== ""){

    const valueSplit = value.split("."); 
    const cleanValue = valueSplit[0].split(",").join("");
    let tempVal: number = Number(cleanValue);

    var newVal = ""
    if (isNaN(tempVal)) {
      newVal = "0"
      return newVal;
    } else if (tempVal >999 ) {  
      try {
        newVal = cleanValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } catch(error) {
        newVal = `${tempVal}` ;
      }
      if(valueSplit.length > 1) {
        const decimals = valueSplit[1].split(",").join("");
        newVal += `.${decimals}`;
      }
      return newVal;
    } else {
      if (valueSplit.length > 1) {
        const decimals = valueSplit[1].split(",").join("");
        return `${valueSplit[0]}.${decimals}`;
      }
      return value;
    }
  }
  return 
}

export { FormatThousands }