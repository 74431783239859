import { Grid } from "@material-ui/core";
import { useState, useContext, useEffect } from "react";
import Atoms from "../../Atoms";
import { Inner } from "../../Styles/Pages/Content";
import AddDialog from "./Dialog";
import { ContextBase, SocketMethods } from "../../Hooks";

const CardImm = () => {
    const com: any = useContext(ContextBase);

    const [open, setOpen] = useState(false);
    const [currencies, setCurrencies] = useState<Array<string>>([]);
    const [warningDialog, setwarningDialog] = useState({ open: false, message: "", type: "" });
    const [data, setData] = useState(new Array())

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setwarningDialog({ open: true, message: "The transaction has been cancelled", type: "error" })
    };

    const handleClick = (e: any) => {
        setOpen(!open);
        const pair: any = currencies.find((i: any) => i.instrument_id == e);
        SocketMethods.rfsSubscribe(
            {
                "streamType": "outright",
                "instrumentId": e,
                "instrumentPair": pair ? pair.symbol : "",
                "clientRequestId": null,
                "expire_time": -1,
                "is_swap": "0",
                "is_ndf": "0",
                "tenor": "IMM1",
                "tenorFar": "IMM2",
                "date": new Date().getTime()
            }
        );
        setwarningDialog({ open: true, message: "IMM added successfully", type: "success" })
    };

    const onDialogClose = () => {
        setwarningDialog({ open: false, message: "", type: "" })
    }

    useEffect(() => {
        setCurrencies(com.state.instruments);
    }, [com.state.instruments]);

    useEffect(() => {
        if (com.state.subscription.symbol) {
            const newdata = data.slice();
            newdata.push({
                par: com.state.subscription.symbol,
                a: com.state.subscription.buy_spreads[0] ? com.state.subscription.buy_spreads[0].value : "-",
                b: com.state.subscription.sell_spreads[0] ? com.state.subscription.sell_spreads[0].value : "-",
            });
            setData(newdata);
        }
    }, [com.state.subscription]);

    return (
        <Grid container style={{ height: "100%", overflowX: "auto" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
                <Inner>
                    <Atoms.Card
                        HeaderCardHeight
                    >
                        <Atoms.Text size="20" fontFamily="bold">IMM</Atoms.Text>
                    </Atoms.Card>
                    <Atoms.Card
                        bodyCard
                        BodyCardHeight
                    >
                        <Grid alignContent="center" container item direction="column" justifyContent="center" spacing={1}>
                            <Grid container item xs md={4} justifyContent="center">
                                <Grid item>
                                    <Atoms.Text fontFamily="bold" size="28" white>IMM1 - IMM2 SWAP</Atoms.Text>
                                </Grid>
                            </Grid>
                            <Grid container item xs md={4} justifyContent="space-between">
                                <Grid item xs>
                                    <Atoms.Text fontFamily="book" size="24" uppercase></Atoms.Text>
                                </Grid>
                                <Grid item xs>
                                    <Atoms.Text fontFamily="bold" size="24" textCenter uppercase white>BID</Atoms.Text>
                                </Grid>
                                <Grid item xs>
                                    <Atoms.Text fontFamily="bold" size="24" textCenter uppercase white>OFFER</Atoms.Text>
                                </Grid>
                            </Grid>
                            {data.map((item, i) => (
                                <Grid container item xs md={4} justifyContent="space-between" key={i}>
                                    <Grid item xs>
                                        <Atoms.Text fontFamily="book" size="24" uppercase white>{item.par}</Atoms.Text>
                                    </Grid>
                                    <Grid item xs>
                                        <Atoms.Text fontFamily="secondary" size="24" uppercase gray>{item.a}</Atoms.Text>
                                    </Grid>
                                    <Grid item xs>
                                        <Atoms.Text fontFamily="secondary" size="24" uppercase gray>{item.b}</Atoms.Text>
                                    </Grid>
                                </Grid>
                            )
                            )}
                        </Grid>
                    </Atoms.Card>
                    <Atoms.Card
                        FooterCardHeight
                    >
                        <Grid container item>
                            <Atoms.Button fullWidth onClick={handleClickOpen}>ADD</Atoms.Button>
                        </Grid>
                    </Atoms.Card>

                    <AddDialog
                        open={open}
                        onClose={handleClose}
                        handleClick={(e: any) => handleClick(e)}
                        currencies={currencies}
                    />
                    <div>
                        <Atoms.Alert
                            open={warningDialog.open}
                            severity={warningDialog.type}
                            onClose={onDialogClose}
                        >
                            <Atoms.Text size="22" textLeft labelCard>
                                {warningDialog.message}
                            </Atoms.Text>
                        </Atoms.Alert>
                    </div>
                </Inner>
            </Grid>
        </Grid>
    )
}

export default (CardImm)