import { Grid } from "@material-ui/core";
import BodyCardCP from "./Body";
import FooterCardCP from "./footer";
import { Inner } from "../../Styles/Pages/Content";
import HeaderCard from "./header";
import { useCallback, useState, useEffect } from "react";
import { FormatThousands } from "../../Utils/Format";

const CardEDF = () => {

    // const normalSpans = ["TD", "TOM", "SN", "1W", "2W", "3W", "1M", "3M", "9M", "BMF",
    // ];
    const [data, setData] = useState(new Array())
    const [value, setValue] = useState('sell');
    const [currency, setCurrency] = useState('EUR/MXN')
    const [fcr, setFcr] = useState('0')

    const onToggle = (e, val) => {
        setValue(val)
    }

    const onInputChange = (input) => (event) => {
        switch (input) {
            case "currency":
                setCurrency(event.target.value);
                break;
            case "fcr":
                setFcr(event.target.value);
                break;
            default:
        }
    }


    const requestAsProps = {
        value,
        currency,
        data,
        fcr
    }

    return (
        <Grid container style={{ height: "100%", overflowX: "auto" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
                <Inner>
                    <HeaderCard
                        onInputChange={onInputChange}
                        // {...requestAsProps}
                    />
                    <BodyCardCP
                        onInputChange={onInputChange}
                        onToggle={onToggle} //toglebuttom
                        {...requestAsProps}
                        // onSend={send}
                        // onRemove={setDeleteRow}
                    />
                    <FooterCardCP />
                </Inner>
            </Grid>
        </Grid>
    )
}


export default CardEDF