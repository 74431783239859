import { Colors, FontSize, Fonts } from "../../Constants";
import Styled, {css} from "styled-components";
import { KeyboardDateTimePicker } from "@material-ui/pickers";


const ContentInput = Styled.div<any>`
  display: ${props => props.display ? "inline-block" : "contents"};
  flex-direction: ${props => props.column ? "column" : "row"};
  align-items: ${props => props.column ? "flex-start" : "flex-end"};
  color: ${props => Colors[props.theme].primary.light};
  ${FontSize.sz14};

  .react-datepicker-wrapper {
    width: calc(100% - 110px);
  }

  .react-datepicker-popper{

    position: fixed !important;
    width: 244px;
    height: 237px;
    display: block;
    @media screen and (max-width:561px){
      transform:translate3d(30%,70%,0px)!important;
    }

  }
  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
    border-bottom-color:  ${props => Colors[props.theme].calendar.header};
    display: none;
  }
  .react-datepicker__header{
    background-color:  ${props => Colors[props.theme].calendar.header}!important;
    .react-datepicker__current-month{
      color: ${props => Colors[props.theme].calendar.text };
    }
    .react-datepicker__day-name{
      color: ${props => Colors[props.theme].calendar.text };
    }
  }
  .react-datepicker{
    background-color:  ${props => Colors[props.theme].calendar.body}!important;
  }
  .react-datepicker__day{
    color: ${props => Colors[props.theme].calendar.text };
    &:hover{
      background-color:  ${props => Colors[props.theme].calendar.hover}!important;
    }
  }
  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
    background-color: ${props => Colors[props.theme].calendar.selected } !important;
  }

  .react-datepicker-wrapper{
  ${props => props.headerCard && css`
    max-width: 140px !important;
    /* @media screen and (min-width:562px){
      max-width: 240px !important;
    } */
  `}
  }
`;

const DateTimeInput = Styled.div<any>`
  border: 1px solid;
  cursor: pointer;
  border-color:  ${props => {
    if (props.error) {
      return "#ff695d";
    }
    return Colors[props.theme].background.light;
  }};
  border-radius: ${props => props.colorBackground ? "4px" : "0px 4px 4px 0px"};
  background-color:  ${props => {
    if (props.error) {
      return "#ffd8d5";
    }

    if (props.disabled) {
      return Colors[props.theme].disabled.light;
    }

    return Colors[props.theme].background.input;
  }};
  color: ${props => Colors[props.theme].primary.textInput };
  height: 36px;
  padding: 0 5px;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  ${FontSize.sz20};
  text-transform: uppercase;
  font-family: ${Fonts.secondary};

  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: flex-end;

  &:focus{
    outline: none !important;
  }
`;

const DateTimePickerIcon = Styled.div<any>`
  display:flex;
  vertical-align: middle !important;
  @media screen and (max-width:390px){
    display:none;
  }
`;

const DateTimePickerInput = Styled(KeyboardDateTimePicker)`

  .MuiInput-root {
    background: ${props => Colors[props.theme].background.input};
    border-radius: 4px;
    color: ${props => Colors[props.theme].primary.textInput };
    font-family: ${Fonts.primaryBook};
  }

  .MuiIconButton-root {
    color: currentColor;
  }
`

export {ContentInput, DateTimeInput, DateTimePickerIcon, DateTimePickerInput};
