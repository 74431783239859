import { Colors } from "../../Constants";
import Styled from "styled-components";

const Content = Styled.div<any>`
    background-color:${props => Colors[props.theme].background.bodyTable};
    box-sizing: border-box;
    height: 100%;
    display: ${props => props.display ? props.display : ""};
    flex-direction: ${props => props.flexDirection ? props.flexDirection : ""};

    scrollbar-color: ${props => Colors[props.theme].background.scrollFront} ${props => Colors[props.theme].background.scroll};
    scrollbar-width: thin;

    ::-webkit-scrollbar, * ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    }

    ::-webkit-scrollbar-thumb, * ::-webkit-scrollbar-thumb {
    background:  ${props => Colors[props.theme].background.scrollFront};
    }

    ::-webkit-scrollbar-track, * ::-webkit-scrollbar-track {
      background: ${props => Colors[props.theme].background.scroll};
    }

`;

const Inner = Styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export {Content, Inner};
