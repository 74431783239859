const Regex = {
  onlyCurrency: /^[0-9.,\b]+$/,
  onlyNumbers: /^[0-9\b]+$/
}


const Validation = {
  isCurrency: (value: any): boolean => {
    return (value !== '' && Regex.onlyCurrency.test(value));
  },
  isNumber:(value: any): boolean => {
    return (value !== '' && Regex.onlyNumbers.test(value));
  } 
}

export { Validation };