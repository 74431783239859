import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useContext, useState } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ContextBase } from "../../Hooks";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import { DatePikcerInput } from "../../Styles/Atoms/DatePicker";
import MaterialTheme from '../../Styles/Constants/Materialtheme'
import {SimpleDateToString} from '../../Utils/Date'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P]
};
type CustomTypeForMissingOverridesProps = {
  MuiPickersBasePicker: {
    pickerViewLandscape: {
      padding?: string;
    };
  };
};
declare module "@material-ui/core/styles/overrides" {
  interface ComponentNameToClassKey extends overridesNameToClassKey { }
  export interface ComponentNameToClassKey
    extends CustomTypeForMissingOverridesProps { }
}

const DatePickerCell = (props: any) => {

  const com: any = useContext(ContextBase);
  const [selectedDate, setSelectedDate] = useState<Date | number>(new Date());

  const handleDateChange = (date: Date) => {
    const oldData = props.data;
    const field = props.colDef.field;
    const newData = { ...oldData };
    newData[field] = date.toISOString().split("T")[0];
    console.log('onCellEditRequest Date, updating ' + field + ' to ' + date);
    const tx = {
      update: [newData],
    };
    props.api.applyTransaction(tx);
    setSelectedDate(date);
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={createTheme(MaterialTheme[com.state.theme])}>
        <DatePikcerInput {...props} theme={com.state.theme}
          disableToolbar
          variant="inline"
          format="yyyy/MM/dd"
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          InputProps={{
            readOnly: true,
            disableUnderline: true
          }}
          disablePast={props.disablePast}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );

}

export default DatePickerCell
