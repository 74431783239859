import {normalize} from './Config';
export default {
  h5: {
    space: normalize(5),
  },
  h10: {
    space: normalize(10),
  },
  h15: {
    space: normalize(15),
  },
  h20: {
    space: normalize(20),
  },
};
