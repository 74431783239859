import React, { useState } from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import Atoms from "../../Atoms";
import LoaderCard from "../../Atoms/LoaderCard";
import Text from "../../Atoms/Text";
import { LoaderBounce } from "../../Styles/Atoms/LoaderCard";
import { Progress, ContentProgress } from "../../Styles/Atoms/ProgressCard";
import Hidden from "@material-ui/core/Hidden";
import { ContentText } from "../../Styles/Atoms/Text";
import Components from "../../Components";
import Grid from "@material-ui/core/Grid";

import { SimpleDateToString } from "../../Utils/Date";
import { Validation } from "../../Utils/Validations";

const BodyCardClient = (props: any) => {
  const com: any = useContext(ContextBase);

  const onKeyPress = (event: any) => {
    const inputValue = event.target.value;
    const inputName = event.target.name || "input";
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    const cleanValue = inputValue.split(",").join("").split(".").join("");
    const repeatedChars = inputValue.match(/([.|,]{2,})/g);

    if (inputName === "expires") {
      if (!Validation.isNumber(keyValue)) {
        event.preventDefault();
        return;
      }
    } else {
      if (
        cleanValue.length >= 20 ||
        inputValue.indexOf(",,") !== -1 ||
        repeatedChars !== null ||
        !Validation.isCurrency(keyValue)
      ) {
        event.preventDefault();
        return;
      }
    }
  };
  return (
    <Atoms.Card
      fullHeight={props.fullHeight}
      bodyCard
      BodyCardHeight
      CardClient={props.clientView}
      CardMarket={props.marketView}
      BodyCardConfirm={props.requestStatus === "CONFIRM"}
      BodyCardAnswer={props.requestStatus === "ANSWER"}
    >
      {props.marketView && (
        <div className="marketViewer">
          {props.RFQType && props.RFQType === "OUTRIGHT" && (
            <>
              {props.requestStatus === "WAIT" && (
                <span>
                  <Atoms.Text size="28" block textCenter>
                    {props.requestMode === "SELL" && "SELL PRICE REQUESTED"}
                    {props.requestMode === "BUY" && "BUY PRICE REQUESTED"}
                    {props.requestMode === "BOTH" && "BUY/SELL PRICE REQUESTED"}
                  </Atoms.Text>
                  <LoaderCard></LoaderCard>
                  <Atoms.Text
                    fontFamily="book"
                    size="16"
                    block
                    textCenter
                    white
                  >
                    Waiting for reply
                  </Atoms.Text>
                </span>
              )}
              {props.requestStatus === "REQUEST" && (
                <>
                  {props.requestMode !== "BOTH" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <ContentText {...props}>
                            <Text size="18" fontFamily="book" textLeft white>
                              Spot Market:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.spotRate}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("spotRate")}
                              error={props.errors && props.errors.spotRate}
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="18" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.frwdPoints}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("frwdPoints")}
                              error={props.errors && props.errors.frwdPoints}
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="18" fontFamily="book" textLeft white>
                              Client Spread:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.clientSpread}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpread")}
                              error={props.errors && props.errors.clientSpread}
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>

                          <ContentText {...props} important allInInput>
                            <Text size="26" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.allIn}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("allIn")}
                              error={props.errors && props.errors.allIn}
                              maxLength={20}
                              disabled={props.manualAllin}
 
                            />
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textLeft
                              labelCard
                              threeColumn
                            ></Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textCenter
                              color="success"
                            >
                              BUY
                            </Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textCenter
                              color="error"
                            >
                              SELL
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                            >
                              Spot Market:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.spotMarketBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("spotMarketBuy")}
                              error={props.errors && props.errors.spotMarketBuy}
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                            <Atoms.Input
                              type="text"
                              value={props.spotMarketSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("spotMarketSell")}
                              error={
                                props.errors && props.errors.spotMarketSell
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                            >
                              Frwd Points:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.frwdPointsBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("frwdPointsBuy")}
                              error={props.errors && props.errors.frwdPointsBuy}
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                            <Atoms.Input
                              type="text"
                              value={props.frwdPointsSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("frwdPointsSell")}
                              error={
                                props.errors && props.errors.frwdPointsSell
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                              white
                            >
                              Client Spread:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.clientSpreadBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpreadBuy")}
                              error={
                                props.errors && props.errors.clientSpreadBuy
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                            <Atoms.Input
                              type="text"
                              value={props.clientSpreadSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpreadSell")}
                              error={
                                props.errors && props.errors.clientSpreadSell
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>

                          <ContentText {...props} important allInInput>
                            <Text
                              size="26"
                              fontFamily="bold"
                              textLeft
                              threeColumn
                            >
                              ALL-IN:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.allInBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("allInBuy")}
                              error={props.errors && props.errors.allInBuy}
                              maxLength={20}
                              disabled={props.manualAllin}
 
                            />
                            <Atoms.Input
                              type="text"
                              value={props.allInSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("allInSell")}
                              error={props.errors && props.errors.allInSell}
                              maxLength={20}
                              disabled={props.manualAllin}
 
                            />
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <ContentText {...props} seconds>
                    <Text size="18" fontFamily="book" textLeft white>
                      Seconds to answer:
                    </Text>
                    <Atoms.Input
                      type="text"
                      name="expires"
                      value={props.expires}
                      onKeyPress={onKeyPress}
                      onChange={props.onInputChange("expires")}
                      error={props.errors && props.errors.expires}
                      maxLength={10}
                    />
                  </ContentText>
                </>
              )}

              {props.requestStatus === "ANSWER" && (
                <>
                  {props.requestMode !== "BOTH" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <Atoms.TimeCountdown
                            onExpiration={props.onExpiration}
                            expires={props.expires}
                          />
                          <Atoms.ProgressCard expires={props.expires} />
                          <ContentText {...props}>
                            <Text size="22" fontFamily="book" textLeft white>
                              Spot Rate:
                            </Text>
                            <Text
                              size="22"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.spotRate}
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="22" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Text
                              size="22"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.frwdPoints}
                            </Text>
                          </ContentText>
                          <ContentText {...props} important>
                            <Text size="24" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="28"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allIn}
                            </Text>
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <Atoms.TimeCountdown
                            onExpiration={props.onExpiration}
                            expires={props.expires}
                          />
                          <Atoms.ProgressCard expires={props.expires} />
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            BUY
                          </Atoms.Text>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Spot Rate:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.spotMarketBuy}
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.frwdPointsBuy}
                            </Text>
                          </ContentText>
                          <ContentText {...props} important importantMargin>
                            <Text size="18" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allInBuy}
                            </Text>
                          </ContentText>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            SELL
                          </Atoms.Text>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Spot Rate:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.spotMarketSell}
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.frwdPointsSell}
                            </Text>
                          </ContentText>
                          <ContentText {...props} important>
                            <Text size="18" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allInSell}
                            </Text>
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {["CANCEL", "REJECT", "EXPIRED"].includes(
                props.requestStatus
              ) && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={80}
                      width={80}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}
              {props.requestStatus === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.AnimationCard
                      animation={"confirm"}
                      height={100}
                      width={100}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Deal done
                    </Atoms.Text>
                    <Atoms.Text
                      fontFamily="bold"
                      size="18"
                      block
                      textCenter
                      white
                    >
                      {`${props.currencyLabel}`}
                    </Atoms.Text>
                    <ContentText {...props}>
                      <Text size="26" fontFamily="bold" textLeft white>
                        {props.requestMode === "SELL" && "SELL "}
                        {props.requestMode === "BUY" && "BUY "}
                        {props.requestMode === "BOTH" && "BUY/SELL "}
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {`${props.currencyFromLabel}`} {props.amount}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Value Date:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.valueDateSpan && (
                          <span>
                            {props.presetSpanList[props.valueDateSpan]}&nbsp;
                          </span>
                        )}
                        {props.valueDate && SimpleDateToString(props.valueDate)}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Spot Rate:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.spotRate}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Frwd Points:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.frwdPoints}
                      </Text>
                    </ContentText>
                    <ContentText {...props} important>
                      <Text size="26" fontFamily="bold" textLeft>
                        ALL-IN:
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {props.allIn}
                      </Text>
                    </ContentText>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SPOT" && (
            <>
              {props.requestStatus === "WAIT" && (
                <span>
                  <Atoms.Text size="28" block textCenter>
                    {props.requestMode === "SELL" && "SELL PRICE REQUESTED"}
                    {props.requestMode === "BUY" && "BUY PRICE REQUESTED"}
                    {props.requestMode === "BOTH" && "BUY/SELL PRICE REQUESTED"}
                  </Atoms.Text>
                  <LoaderCard></LoaderCard>
                  <Atoms.Text
                    fontFamily="book"
                    size="16"
                    block
                    textCenter
                    white
                  >
                    Waiting for reply
                  </Atoms.Text>
                </span>
              )}
              {["REQUEST", "REQUEST_UPDATE"].includes(props.requestStatus) && (
                <>
                  {props.requestMode !== "BOTH" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <ContentText {...props}>
                            <Text size="18" fontFamily="book" textLeft white>
                              Spot Market:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.spotRate}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("spotRate")}
                              error={props.errors && props.errors.spotRate}
                              maxLength={20}
                              disabled={!props.manualAllin}
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="18" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.frwdPoints}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("frwdPoints")}
                              error={props.errors && props.errors.frwdPoints}
                              maxLength={20}
                              disabled={!props.manualAllin}
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="18" fontFamily="book" textLeft white>
                              Client Spread:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.clientSpread}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpread")}
                              error={props.errors && props.errors.clientSpread}
                              maxLength={20}
                              disabled={!props.manualAllin}
                            />
                          </ContentText>

                          <ContentText {...props} important allInInput>
                            <Text size="26" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.allIn}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("allIn")}
                              error={props.errors && props.errors.allIn}
                              maxLength={20}
                              disabled={props.manualAllin}
                            />
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textLeft
                              labelCard
                              threeColumn
                            ></Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textCenter
                              color="success"
                            >
                              BUY
                            </Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textCenter
                              color="error"
                            >
                              SELL
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                            >
                              Spot Market:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.spotMarketBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("spotMarketBuy")}
                              error={props.errors && props.errors.spotMarketBuy}
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                            <Atoms.Input
                              type="text"
                              value={props.spotMarketSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("spotMarketSell")}
                              error={
                                props.errors && props.errors.spotMarketSell
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                            >
                              Frwd Points:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.frwdPointsBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("frwdPointsBuy")}
                              error={props.errors && props.errors.frwdPointsBuy}
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                            <Atoms.Input
                              type="text"
                              value={props.frwdPointsSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("frwdPointsSell")}
                              error={
                                props.errors && props.errors.frwdPointsSell
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                              white
                            >
                              Client Spread:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.clientSpreadBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpreadBuy")}
                              error={
                                props.errors && props.errors.clientSpreadBuy
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                            <Atoms.Input
                              type="text"
                              value={props.clientSpreadSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpreadSell")}
                              error={
                                props.errors && props.errors.clientSpreadSell
                              }
                              maxLength={20}
                              disabled={!props.manualAllin}
 
                            />
                          </ContentText>

                          <ContentText {...props} important allInInput>
                            <Text
                              size="26"
                              fontFamily="bold"
                              textLeft
                              threeColumn
                            >
                              ALL-IN:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.allInBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("allInBuy")}
                              error={props.errors && props.errors.allInBuy}
                              maxLength={20}
                              disabled={props.manualAllin}
                            />
                            <Atoms.Input
                              type="text"
                              value={props.allInSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("allInSell")}
                              error={props.errors && props.errors.allInSell}
                              maxLength={20}
                              disabled={props.manualAllin}
                            />
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <ContentText {...props} seconds>
                    <Text size="18" fontFamily="book" textLeft white>
                      Seconds to answer:
                    </Text>
                    <Atoms.Input
                      type="text"
                      name="expires"
                      value={props.expires}
                      onKeyPress={onKeyPress}
                      onChange={props.onInputChange("expires")}
                      error={props.errors && props.errors.expires}
                      maxLength={10}
                    />
                  </ContentText>
                </>
              )}
              {["CANCEL", "REJECT", "EXPIRED"].includes(
                props.requestStatus
              ) && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={80}
                      width={80}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {props.requestStatus === "ANSWER" && (
                <>
                  {props.requestMode !== "BOTH" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <Atoms.TimeCountdown
                            onExpiration={props.onExpiration}
                            expires={props.expires}
                          />
                          <Atoms.ProgressCard expires={props.expires} />
                          <ContentText {...props}>
                            <Text size="22" fontFamily="book" textLeft white>
                              Spot Rate:
                            </Text>
                            <Text
                              size="22"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.spotRate}
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="22" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Text
                              size="22"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.frwdPoints}
                            </Text>
                          </ContentText>
                          <ContentText {...props} important>
                            <Text size="24" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="28"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allIn}
                            </Text>
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <Atoms.TimeCountdown
                            onExpiration={props.onExpiration}
                            expires={props.expires}
                          />
                          <Atoms.ProgressCard expires={props.expires} />
                        </Grid>

                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            BUY
                          </Atoms.Text>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Spot Rate:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.spotMarketBuy}
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.frwdPointsBuy}
                            </Text>
                          </ContentText>
                          <ContentText {...props} important importantMargin>
                            <Text size="18" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allInBuy}
                            </Text>
                          </ContentText>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            SELL
                          </Atoms.Text>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Spot Rate:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.spotMarketSell}
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text size="16" fontFamily="book" textLeft white>
                              Frwd Points:
                            </Text>
                            <Text
                              size="16"
                              fontFamily="secondary"
                              textRight
                              block
                              gray
                            >
                              {props.frwdPointsSell}
                            </Text>
                          </ContentText>
                          <ContentText {...props} important>
                            <Text size="18" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allInSell}
                            </Text>
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {props.requestStatus === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.AnimationCard
                      animation={"confirm"}
                      height={100}
                      width={100}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Deal done
                    </Atoms.Text>
                    <Atoms.Text
                      fontFamily="bold"
                      size="18"
                      block
                      textCenter
                      white
                    >
                      {`${props.currencyLabel}`}
                    </Atoms.Text>
                    <ContentText {...props}>
                      <Text size="26" fontFamily="bold" textLeft white>
                        {props.requestMode === "SELL" && "SELL "}
                        {props.requestMode === "BUY" && "BUY "}
                        {props.requestMode === "BOTH" &&
                          `${
                            props.request.buy.status === "FILLED"
                              ? "BUY"
                              : "SELL"
                          } `}
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {`${props.currencyFromLabel}`} {props.amount}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Value Date:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.requestMode === "BOTH" &&
                          `${
                            props.request.buy.status === "FILLED"
                              ? SimpleDateToString(props.request.buy.valueDate)
                              : SimpleDateToString(props.request.sell.valueDate)
                          } `}

                        {props.valueDateSpan && (
                          <span>
                            {props.presetSpanList[props.valueDateSpan]}&nbsp;
                          </span>
                        )}
                        {props.valueDate && SimpleDateToString(props.valueDate)}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Spot Rate:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.buy.price
                                : props.request.sell.price
                            } `
                          : props.spotRate}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Frwd Points:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.frwdPointsBuy
                                : props.frwdPointsSell
                            } `
                          : props.frwdPoints}
                      </Text>
                    </ContentText>
                    <ContentText {...props} important>
                      <Text size="26" fontFamily="bold" textLeft>
                        ALL-IN:
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {props.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.allInBuy
                                : props.allInSell
                            }`
                          : props.allIn}
                      </Text>
                    </ContentText>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SWAP" && (
            <>
              {props.requestStatus === "WAIT" && (
                <span>
                  <Atoms.Text size="28" block textCenter>
                    {props.requestMode === "SELL" && "SELL PRICE REQUESTED"}
                    {props.requestMode === "BUY" && "BUY PRICE REQUESTED"}
                    {props.requestMode === "BOTH" && "BUY/SELL PRICE REQUESTED"}
                  </Atoms.Text>
                  <LoaderCard></LoaderCard>
                  {/* <Atoms.IconCard src={wait}></Atoms.IconCard> */}
                  <Atoms.Text
                    fontFamily="book"
                    size="16"
                    block
                    textCenter
                    white
                  >
                    Waiting for reply
                  </Atoms.Text>
                </span>
              )}

              {props.requestStatus === "REQUEST" && (
                <>
                  {props.requestMode !== "BOTH" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <ContentText {...props}>
                            <Text size="18" fontFamily="book" textLeft white>
                              Client Spread:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.clientSpread}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpread")}
                              error={props.errors && props.errors.clientSpread}
                              maxLength={20}
                            />
                          </ContentText>

                          <ContentText {...props} important allInInput>
                            <Text size="26" fontFamily="bold" textLeft>
                              SWAP POINTS:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.swapPoints}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("swapPoints")}
                              error={props.errors && props.errors.swapPoints}
                              maxLength={20}
                            />
                          </ContentText>

                          <ContentText {...props} seconds>
                            <Text size="18" fontFamily="book" textLeft white>
                              Seconds to answer:
                            </Text>
                            <Atoms.Input
                              type="text"
                              name="expires"
                              value={props.expires}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("expires")}
                              error={props.errors && props.errors.expires}
                              maxLength={20}
                            />
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-between"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textLeft
                              labelCard
                              threeColumn
                            ></Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textCenter
                              color="success"
                            >
                              BUY
                            </Text>
                            <Text
                              size="18"
                              fontFamily="bold"
                              textCenter
                              color="error"
                            >
                              SELL
                            </Text>
                          </ContentText>
                          <ContentText {...props}>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                              white
                            >
                              Client Spread:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.clientSpreadBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpreadBuy")}
                              error={
                                props.errors && props.errors.clientSpreadBuy
                              }
                              maxLength={20}
                            />
                            <Atoms.Input
                              type="text"
                              value={props.clientSpreadSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("clientSpreadSell")}
                              error={
                                props.errors && props.errors.clientSpreadSell
                              }
                              maxLength={20}
                            />
                          </ContentText>

                          <ContentText {...props} important allInInput>
                            <Text
                              size="26"
                              fontFamily="bold"
                              textLeft
                              threeColumn
                            >
                              SWAP:
                            </Text>
                            <Atoms.Input
                              type="text"
                              value={props.swapPointsBuy}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("swapPointsBuy")}
                              error={props.errors && props.errors.swapPointsBuy}
                              maxLength={20}
                              disabled
                            />
                            <Atoms.Input
                              type="text"
                              value={props.swapPointsSell}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("swapPointsSell")}
                              error={
                                props.errors && props.errors.swapPointsSell
                              }
                              maxLength={20}
                              disabled
                            />
                          </ContentText>

                          <ContentText {...props} seconds>
                            <Text
                              size="18"
                              fontFamily="book"
                              textLeft
                              labelCard
                              threeColumn
                              white
                            >
                              Seconds to answer:
                            </Text>

                            <Atoms.Input
                              type="text"
                              name="expires"
                              value={props.expires}
                              onKeyPress={onKeyPress}
                              onChange={props.onInputChange("expires")}
                              error={props.errors && props.errors.expires}
                              maxLength={20}
                            />
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {["CANCEL", "REJECT", "EXPIRED"].includes(
                props.requestStatus
              ) && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={80}
                      width={80}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {props.requestStatus === "ANSWER" && (
                <>
                  {props.requestMode !== "BOTH" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-around"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <Atoms.TimeCountdown
                            onExpiration={props.onExpiration}
                            expires={props.expires}
                          />
                          <Atoms.ProgressCard expires={props.expires} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ContentText {...props} important>
                            <Text size="24" fontFamily="bold" textLeft>
                              SWAP POINTS:
                            </Text>
                            <Text
                              size="24"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.swapPoints}
                            </Text>
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-around"
                        direction="row"
                      >
                        <Grid item xs={12} md={12}>
                          <Atoms.TimeCountdown
                            onExpiration={props.onExpiration}
                            expires={props.expires}
                          />
                          <Atoms.ProgressCard expires={props.expires} />
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            BUY
                          </Atoms.Text>
                          <ContentText {...props} important importantMargin>
                            <Text size="14" fontFamily="bold" textLeft>
                              SWAP POINTS:
                            </Text>
                            <Text
                              size="14"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.swapPointsBuy}
                            </Text>
                          </ContentText>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            SELL
                          </Atoms.Text>
                          <ContentText {...props} important>
                            <Text size="14" fontFamily="bold" textLeft>
                              SWAP POINTS:
                            </Text>
                            <Text
                              size="14"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.swapPointsSell}
                            </Text>
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
              {props.requestStatus === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.AnimationCard
                      animation={"confirm"}
                      height={100}
                      width={100}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Deal done
                    </Atoms.Text>
                    <Atoms.Text
                      fontFamily="bold"
                      size="18"
                      block
                      textCenter
                      white
                    >
                      {`${props.currencyLabel}`}
                    </Atoms.Text>
                    <ContentText {...props}>
                      <Text size="26" fontFamily="bold" textLeft white>
                        {props.requestMode === "SELL" && "SELL "}
                        {props.requestMode === "BUY" && "BUY "}
                        {props.requestMode === "BOTH" &&
                          `${
                            props.request.buy.status === "FILLED"
                              ? "BUY"
                              : "SELL"
                          } `}
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {`${props.currencyFromLabel}`} {props.amount}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Near Leg:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.nearLegSpan && (
                          <span>
                            {props.presetSpanList[props.nearLegSpan]}&nbsp;
                          </span>
                        )}
                        {props.nearLeg && SimpleDateToString(props.nearLeg)}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="18" fontFamily="book" textLeft white>
                        Far Leg:
                      </Text>
                      <Text
                        size="18"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.farLegSpan && (
                          <span>
                            {props.presetSpanList[props.farLegSpan]}&nbsp;
                          </span>
                        )}
                        {props.farLeg && SimpleDateToString(props.farLeg)}
                      </Text>
                    </ContentText>
                    <ContentText {...props} important>
                      <Text size="26" fontFamily="bold" textLeft>
                        SWAP POINTS:
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {props.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.swapPointsBuy
                                : props.swapPointsSell
                            } `
                          : props.swapPoints}
                      </Text>
                    </ContentText>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </div>
      )}

      {props.clientView && (
        <>
          {props.RFQType && props.RFQType === "OUTRIGHT" && (
            <>
              {["UPDATING", "WAIT"].includes(props.requestStatus) && (
                <span>
                  <Atoms.Text size="28" block textCenter>
                    {props.requestMode === "SELL" && "SELL PRICE REQUESTED"}
                    {props.requestMode === "BUY" && "BUY PRICE REQUESTED"}
                    {props.requestMode === "BOTH" && "BUY/SELL PRICE REQUESTED"}
                  </Atoms.Text>
                  <LoaderCard></LoaderCard>
                  <Atoms.Text
                    fontFamily="book"
                    size="16"
                    block
                    textCenter
                    white
                  >
                    Waiting for reply
                  </Atoms.Text>
                </span>
              )}

              {props.requestStatus === "REQUEST" && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"request"}
                      height={45}
                      width={45}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      Complete the options to enable price request
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"request"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Complete the options to enable price request
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {["CANCEL", "REJECT", "EXPIRED"].includes(
                props.requestStatus
              ) && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={80}
                      width={80}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {props.requestStatus === "ANSWER" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.TimeCountdown
                      onExpiration={props.onExpiration}
                      expires={props.expires}
                    />
                    <Atoms.ProgressCard expires={props.expires} />

                    {props.requestMode !== "BOTH" && (
                      <>
                        <ContentText {...props}>
                          <Text size="22" fontFamily="book" textLeft white>
                            Spot Rate:
                          </Text>
                          <Text
                            size="22"
                            fontFamily="secondary"
                            textRight
                            block
                            gray
                          >
                            {props && props.spotRate}
                          </Text>
                        </ContentText>
                        <ContentText {...props}>
                          <Text size="22" fontFamily="book" textLeft white>
                            Frwd Points:
                          </Text>
                          <Text
                            size="22"
                            fontFamily="secondary"
                            textRight
                            block
                            gray
                          >
                            {/* -.0020 */}
                            {props && props.frwdPoints}
                          </Text>
                        </ContentText>
                        <ContentText {...props} important>
                          <Hidden mdUp>
                            <Text size="26" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="26"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allIn}
                            </Text>
                          </Hidden>
                          <Hidden smDown>
                            <Text size="28" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="28"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allIn}
                            </Text>
                          </Hidden>
                        </ContentText>
                      </>
                    )}

                    {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                      <>
                        <Grid
                          container
                          xs={12}
                          alignItems="center"
                          justify="space-around"
                          direction="row"
                        >
                          <Grid item xs={6} md={6}>
                            <Atoms.Text
                              fontFamily="bold"
                              size="20"
                              block
                              textCenter
                              white
                            >
                              BUY
                            </Atoms.Text>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Spot Rate:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.spotMarketBuy}
                              </Text>
                            </ContentText>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Frwd Points:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.frwdPointsBuy}
                              </Text>
                            </ContentText>
                            <ContentText {...props} important importantMargin>
                              <Text size="24" fontFamily="bold" textLeft>
                                ALL IN:
                              </Text>
                              <Text
                                size="24"
                                fontFamily="bold"
                                textRight
                                block
                                white
                              >
                                {props.allInBuy}
                              </Text>
                            </ContentText>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Atoms.Text
                              fontFamily="bold"
                              size="20"
                              block
                              textCenter
                              white
                            >
                              SELL
                            </Atoms.Text>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Spot Rate:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.spotMarketSell}
                              </Text>
                            </ContentText>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Frwd Points:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.frwdPointsSell}
                              </Text>
                            </ContentText>
                            <ContentText {...props} important importantMargin>
                              <Text size="24" fontFamily="bold" textLeft>
                                ALL IN:
                              </Text>
                              <Text
                                size="24"
                                fontFamily="bold"
                                textRight
                                block
                                white
                              >
                                {props.allInSell}
                              </Text>
                            </ContentText>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}

              {props.requestStatus === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.AnimationCard
                      animation={"confirm"}
                      height={100}
                      width={100}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Deal done
                    </Atoms.Text>
                    <Atoms.Text
                      fontFamily="bold"
                      size="18"
                      block
                      textCenter
                      white
                    >
                      {`${props.currencyLabel}`}
                    </Atoms.Text>
                    <ContentText {...props}>
                      <Text size="26" fontFamily="bold" textLeft white>
                        {props.request.requestMode === "SELL" && "SELL "}
                        {props.request.requestMode === "BUY" && "BUY "}
                        {props.request.requestMode === "BOTH" &&
                          `${
                            props.request.buy.status === "FILLED"
                              ? "BUY"
                              : "SELL"
                          } `}
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {`${props.currencyFromLabel}`} {props.amount}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Value Date:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.request.requestMode === "BOTH" &&
                          `${
                            props.request.buy.status === "FILLED"
                              ? SimpleDateToString(props.request.buy.valueDate)
                              : SimpleDateToString(props.request.sell.valueDate)
                          } `}

                        {props.valueDateSpan && (
                          <span>
                            {props.presetSpanList[props.valueDateSpan]}&nbsp;
                          </span>
                        )}
                        {props.valueDate && SimpleDateToString(props.valueDate)}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Spot Rate:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.buy.price
                                : props.request.sell.price
                            }`
                          : props.spotRate}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Frwd Points:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.buy.frwPoints
                                : props.request.sell.frwPoints
                            }`
                          : props.frwdPoints}
                      </Text>
                    </ContentText>
                    <ContentText {...props} important>
                      <Text size="26" fontFamily="bold" textLeft>
                        ALL-IN:
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.allInBuy
                                : props.request.allInSell
                            }`
                          : props.allIn}
                      </Text>
                    </ContentText>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SPOT" && (
            <>
              {["UPDATING", "WAIT"].includes(props.requestStatus) && (
                <span>
                  <Atoms.Text size="28" block textCenter>
                    {props.requestMode === "SELL" && "SELL PRICE REQUESTED"}
                    {props.requestMode === "BUY" && "BUY PRICE REQUESTED"}
                    {props.requestMode === "BOTH" && "BUY/SELL PRICE REQUESTED"}
                  </Atoms.Text>
                  <LoaderCard></LoaderCard>
                  <Atoms.Text
                    fontFamily="book"
                    size="16"
                    block
                    textCenter
                    white
                  >
                    Waiting for reply
                  </Atoms.Text>
                </span>
              )}

              {props.requestStatus === "REQUEST" && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"request"}
                      height={45}
                      width={45}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      Complete the options to enable price request
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"request"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Complete the options to enable price request
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {["CANCEL", "REJECT", "EXPIRED"].includes(
                props.requestStatus
              ) && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={80}
                      width={80}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {props.requestStatus === "ANSWER" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.TimeCountdown
                      onExpiration={props.onExpiration}
                      expires={props.expires}
                    />
                    <Atoms.ProgressCard expires={props.expires} />

                    {props.requestMode !== "BOTH" && (
                      <>
                        <ContentText {...props}>
                          <Text size="22" fontFamily="book" textLeft white>
                            Spot Rate:
                          </Text>
                          <Text
                            size="22"
                            fontFamily="secondary"
                            textRight
                            block
                            gray
                          >
                            {props && props.spotRate}
                          </Text>
                        </ContentText>
                        <ContentText {...props}>
                          <Text size="22" fontFamily="book" textLeft white>
                            Frwd Points:
                          </Text>
                          <Text
                            size="22"
                            fontFamily="secondary"
                            textRight
                            block
                            gray
                          >
                            {/* -.0020 */}
                            {props && props.frwdPoints}
                          </Text>
                        </ContentText>
                        <ContentText {...props} important>
                          <Hidden mdUp>
                            <Text size="26" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="26"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allIn}
                            </Text>
                          </Hidden>
                          <Hidden smDown>
                            <Text size="28" fontFamily="bold" textLeft>
                              ALL-IN:
                            </Text>
                            <Text
                              size="28"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.allIn}
                            </Text>
                          </Hidden>
                        </ContentText>
                      </>
                    )}

                    {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                      <>
                        <Grid
                          container
                          xs={12}
                          alignItems="center"
                          justify="space-around"
                          direction="row"
                        >
                          <Grid item xs={6} md={6}>
                            <Atoms.Text
                              fontFamily="bold"
                              size="20"
                              block
                              textCenter
                              white
                            >
                              BUY
                            </Atoms.Text>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Spot Rate:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.spotMarketBuy}
                              </Text>
                            </ContentText>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Frwd Points:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.frwdPointsBuy}
                              </Text>
                            </ContentText>
                            <ContentText {...props} important importantMargin>
                              <Text size="24" fontFamily="bold" textLeft>
                                ALL IN:
                              </Text>
                              <Text
                                size="24"
                                fontFamily="bold"
                                textRight
                                block
                                white
                              >
                                {props.allInBuy}
                              </Text>
                            </ContentText>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <Atoms.Text
                              fontFamily="bold"
                              size="20"
                              block
                              textCenter
                              white
                            >
                              SELL
                            </Atoms.Text>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Spot Rate:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.spotMarketSell}
                              </Text>
                            </ContentText>
                            <ContentText {...props} importantMargin>
                              <Text size="22" fontFamily="book" textLeft white>
                                Frwd Points:
                              </Text>
                              <Text
                                size="22"
                                fontFamily="secondary"
                                textRight
                                block
                                gray
                              >
                                {props && props.frwdPointsSell}
                              </Text>
                            </ContentText>
                            <ContentText {...props} important importantMargin>
                              <Text size="24" fontFamily="bold" textLeft>
                                ALL IN:
                              </Text>
                              <Text
                                size="24"
                                fontFamily="bold"
                                textRight
                                block
                                white
                              >
                                {props.allInSell}
                              </Text>
                            </ContentText>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}

              {props.requestStatus === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.AnimationCard
                      animation={"confirm"}
                      height={100}
                      width={100}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Deal done
                    </Atoms.Text>
                    <Atoms.Text
                      fontFamily="bold"
                      size="18"
                      block
                      textCenter
                      white
                    >
                      {`${props.currencyLabel}`}
                    </Atoms.Text>
                    <ContentText {...props}>
                      <Text size="26" fontFamily="bold" textLeft white>
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? "BUY "
                                : "SELL "
                            }`
                          : props.requestMode === "SELL"
                          ? "SELL "
                          : "BUY "}
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {`${props.currencyFromLabel}`} {props.amount}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Value Date:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.request.requestMode === "BOTH" &&
                          `${
                            props.request.buy.status === "FILLED"
                              ? SimpleDateToString(props.request.buy.valueDate)
                              : SimpleDateToString(props.request.sell.valueDate)
                          } `}

                        {props.valueDateSpan && (
                          <span>
                            {props.presetSpanList[props.valueDateSpan]}&nbsp;
                          </span>
                        )}
                        {props.valueDate && SimpleDateToString(props.valueDate)}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Spot Rate:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.buy.price
                                : props.request.sell.price
                            }`
                          : props.spotRate}{" "}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Frwd Points:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.buy.frwPoints
                                : props.request.sell.frwPoints
                            }`
                          : props.frwdPoints}{" "}
                      </Text>
                    </ContentText>
                    <ContentText {...props} important>
                      <Text size="26" fontFamily="bold" textLeft>
                        ALL-IN:
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.allInBuy
                                : props.request.allInSell
                            }`
                          : props.allIn}
                      </Text>
                    </ContentText>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SWAP" && (
            <>
              {["UPDATING", "WAIT"].includes(props.requestStatus) && (
                <span>
                  <Hidden mdUp>
                    <Atoms.Text size="24" block textCenter>
                      {props.requestMode === "SELL" && "SELL PRICE REQUESTED"}
                      {props.requestMode === "BUY" && "BUY PRICE REQUESTED"}
                      {props.requestMode === "BOTH" &&
                        "BUY/SELL PRICE REQUESTED"}
                    </Atoms.Text>
                    <LoaderCard></LoaderCard>
                    {/* <Atoms.IconCard src={wait}></Atoms.IconCard> */}
                    <Atoms.Text
                      fontFamily="book"
                      size="16"
                      block
                      textCenter
                      white
                    >
                      Waiting for reply
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.Text size="28" block textCenter>
                      {props.requestMode === "SELL" && "SELL PRICE REQUESTED"}
                      {props.requestMode === "BUY" && "BUY PRICE REQUESTED"}
                      {props.requestMode === "BOTH" &&
                        "BUY/SELL PRICE REQUESTED"}
                    </Atoms.Text>
                    <LoaderCard></LoaderCard>
                    {/* <Atoms.IconCard src={wait}></Atoms.IconCard> */}
                    <Atoms.Text
                      fontFamily="book"
                      size="16"
                      block
                      textCenter
                      white
                    >
                      Waiting for reply
                    </Atoms.Text>
                  </Hidden>
                </span>
              )}

              {props.requestStatus === "REQUEST" && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"request"}
                      height={45}
                      width={45}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      Complete the options to enable price request
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"request"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Complete the options to enable price request
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {["CANCEL", "REJECT", "EXPIRED"].includes(
                props.requestStatus
              ) && (
                <Grid item xs={12} md={12}>
                  <Hidden mdUp>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={60}
                      width={60}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="24" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                  <Hidden smDown>
                    <Atoms.AnimationCard
                      animation={"cancel"}
                      height={80}
                      width={80}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      {`The transaction has ${
                        props.requestStatus === "CANCEL"
                          ? "been canceled"
                          : props.requestStatus === "EXPIRED"
                          ? "expired"
                          : "rejected"
                      }.`}
                    </Atoms.Text>
                  </Hidden>
                </Grid>
              )}

              {props.requestStatus === "ANSWER" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-around"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.TimeCountdown
                      onExpiration={props.onExpiration}
                      expires={props.expires}
                    />
                    <Atoms.ProgressCard expires={props.expires} />
                  </Grid>

                  {props.requestMode !== "BOTH" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-around"
                        direction="row"
                      >
                        <ContentText {...props} important>
                          <Text size="24" fontFamily="bold" textLeft>
                            SWAP POINTS:
                          </Text>
                          <Text
                            size="24"
                            fontFamily="bold"
                            textRight
                            block
                            white
                          >
                            {props.swapPoints}
                          </Text>
                        </ContentText>
                      </Grid>
                    </>
                  )}

                  {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                    <>
                      <Grid
                        container
                        xs={12}
                        alignItems="center"
                        justify="space-around"
                        direction="row"
                      >
                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            SELL
                          </Atoms.Text>
                          <ContentText {...props} important importantMargin>
                            <Text size="14" fontFamily="bold" textLeft>
                              SWAP POINTS:
                            </Text>
                            <Text
                              size="14"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.swapPointsSell}
                            </Text>
                          </ContentText>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Atoms.Text
                            fontFamily="bold"
                            size="20"
                            block
                            textCenter
                            white
                          >
                            BUY
                          </Atoms.Text>
                          <ContentText {...props} important importantMargin>
                            <Text size="14" fontFamily="bold" textLeft>
                              SWAP POINTS:
                            </Text>
                            <Text
                              size="14"
                              fontFamily="bold"
                              textRight
                              block
                              white
                            >
                              {props.swapPointsBuy}
                            </Text>
                          </ContentText>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              )}

              {props.requestStatus === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="space-between"
                  direction="row"
                >
                  <Grid item xs={12} md={12}>
                    <Atoms.AnimationCard
                      animation={"confirm"}
                      height={100}
                      width={100}
                    ></Atoms.AnimationCard>
                    <Atoms.Text size="28" textCenter block>
                      Deal done
                    </Atoms.Text>
                    <Atoms.Text
                      fontFamily="bold"
                      size="18"
                      block
                      textCenter
                      white
                    >
                      {`${props.currencyLabel}`}
                    </Atoms.Text>
                    <ContentText {...props}>
                      <Text size="26" fontFamily="bold" textLeft white>
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? "BUY"
                                : "SELL"
                            }`
                          : props.requestMode}
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {`${props.currencyFromLabel}`} {props.amount}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Near Leg:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.nearLegSpan && (
                          <span>
                            {props.presetSpanList[props.nearLegSpan]}&nbsp;
                          </span>
                        )}
                        {props.nearLeg &&
                          SimpleDateToString(
                            props.nearLeg !== undefined ? props.nearLeg : ""
                          )}
                      </Text>
                    </ContentText>
                    <ContentText {...props}>
                      <Text size="22" fontFamily="book" textLeft white>
                        Far Leg:
                      </Text>
                      <Text
                        size="22"
                        fontFamily="secondary"
                        textRight
                        block
                        gray
                      >
                        {props.farLegSpan && (
                          <span>
                            {props.presetSpanList[props.farLegSpan]}&nbsp;
                          </span>
                        )}
                        {props.farLeg &&
                          SimpleDateToString(
                            props.farLeg !== undefined ? props.farLeg : ""
                          )}
                      </Text>
                    </ContentText>
                    <ContentText {...props} important>
                      <Text size="26" fontFamily="bold" textLeft>
                        SWAP POINTS:
                      </Text>
                      <Text size="26" fontFamily="bold" textRight block white>
                        {props.request.requestMode === "BOTH"
                          ? `${
                              props.request.buy.status === "FILLED"
                                ? props.request.swapPointsBuy
                                : props.request.swapPointsSell
                            }`
                          : props.swapPoints}
                      </Text>
                    </ContentText>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Atoms.Card>
  );
};

export default BodyCardClient;
