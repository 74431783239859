import { Grid } from "@material-ui/core"
import { Key, useState } from "react"
import Components from ".."
import Atoms from "../../Atoms"
import { BlockInner, Box, ContentInner, Label } from "../../Styles/Pages/Content/BodyCard"
import Table from "../TableAg"
import OrderDialog from "./Dialog"

const BodyCardEDF = (props: any) => {

    const onBtnRender = (e: any) => {
        props.onRemove(e)
    }

    const [open, setOpen] = useState(false);
    const [side, setSide] = useState<string>();
    const [MidBid, setMidBid] = useState(false);
    const [columns, setColumns] = useState<Array<any>>([
        {
            headerName: "Total",
            field: "total",
            minWidth: "70",
            flex: 1
        },
        {
            headerName: "Showing",
            field: "showing",
            minWidth: "70",
            flex: 1
        },
        {
            headerName: "Time",
            field: "time",
            minWidth: "120",
            flex: 1
        },
        {
            headerName: "",
            field: "action",
            minWidth: "50",
            flex: 1,
            cellRenderer: "cellButtonRenderer",
            cellRendererParams: {
                clicked: onBtnRender,
            }
        },
    ]);


    const handleClickOpen = (side: string) => {
        setOpen(true);
        setSide(side)
    };

    const handleClose = () => {
        setOpen(false);
        setMidBid(false);
        setSide(undefined)
    };

    const handleClickOpenMid = (side: string) => {
        setOpen(true);
        setMidBid(true);
        setSide(side)
    }

    return (
        <Atoms.Card
            bodyCard
            BodyCardHeight
            fullHeight
        >
                {/*props.currency &&
                    <ContentInner>
                        <BlockInner>
                            <Box className="bid">
                                <div className="content-button">
                                    <div>
                                        <Atoms.Button color="success" simple onClick={() => handleClickOpen("BUY")}>
                                            My Bid
                                        </Atoms.Button>
                                    </div>
                                    <div>
                                        <Atoms.Button variant="outlined" simple color="success" onClick={() => handleClickOpenMid("BUY")}>
                                            midBid
                                        </Atoms.Button>
                                    </div>
                                </div>
                                <div>
                                    <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white>BID</Atoms.Text>
                                    <Label className="label">
                                        <small>{props.bid}</small>
                                    </Label>
                                </div>

                            </div>
                            <div>
                                <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white>BID</Atoms.Text>
                                <Label className="label hover" cursor="pointer">
                                    <small>726</small>.00
                                </Label>
                            </div>
                        </Box>
                        <Box className="date">
                            <Atoms.Text block textCenter size="22" white>Mar</Atoms.Text>
                            <Atoms.Text block textCenter size="22" white>11 2022</Atoms.Text>
                        </Box>
                        <Box className="offer">
                            <div>
                                <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white>OFFER</Atoms.Text>
                                <Label className="label hover" cursor="pointer">
                                    <small>726</small>.20
                                </Label>
                            </div>
                            <div className="content-button">
                                <div>
                                    <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white>OFFER</Atoms.Text>
                                    <Label className="label">
                                        <small>{props.offer}</small>
                                    </Label>
                                </div>
                                <div className="content-button">
                                    <div>
                                        <Atoms.Button color="error" simple onClick={() => handleClickOpen("SELL")}>
                                            My Bid
                                        </Atoms.Button>
                                    </div>
                                    <div>
                                        <Atoms.Button variant="outlined" color="error" simple onClick={() => handleClickOpenMid("SELL")}>
                                            midBid
                                        </Atoms.Button>
                                    </div>
                                </div>
                            </Box>
                        </BlockInner>

                        <Grid alignItems="center" container item justifyContent="center" spacing={2}>
                            <Grid container item justifyContent="center" spacing={2}>
                                <Grid container item xs sm={6} md={4} justifyContent="center" spacing={2}>
                                    <Atoms.Text block textCenter size="26" uppercase fontFamily="bold" white>Notional:</Atoms.Text>
                                    <Grid container item justifyContent="center">
                                        <Atoms.Input
                                            headerTop
                                            textCenter
                                            type="text"
                                            onChange={props.onInputChange('national')}
                                            value={props.national}
                                        />
                                    </Grid>


                                <Grid container item justifyContent="space-around">
                                    <Label cursor="pointer" onClick={props.onInputChange('1m')}>1 Mio</Label>
                                    <Label cursor="pointer" onClick={props.onInputChange('5m')}>5 Mio</Label>
                                    <Label cursor="pointer" onClick={props.onInputChange('10m')}>10 Mio</Label>
                                </Grid>

                                    <Grid className="content-text">
                                        <Atoms.Text block fontFamily="book" size="22" textCenter uppercase white> Tom Fix / 1M:</Atoms.Text>
                                        <Atoms.Text block fontFamily="book" size="22" textCenter uppercase gray> 0.005 0.15</Atoms.Text>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item xs sm={8} justifyContent="center">
                                <Table
                                    rows={props.data}
                                    columns={columns}
                                    pagination={true}
                                    paginationPageSize={5}
                                    height="320px"
                                />
                            </Grid>
                        </Grid>
                    </ContentInner>*/
}
            <OrderDialog
                open={open}
                MidBid={MidBid}
                onClose={handleClose}
                onSend={props.onSend}
                side={side}
            />

        </Atoms.Card>
    )
}

export default BodyCardEDF