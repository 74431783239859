import { Colors } from "../../Constants";
import Styled from "styled-components";

const ContentUserView = Styled.div<any>`
    height: 100%;
    box-sizing: border-box;
    padding: 2rem 1rem;
    background-color:${props => Colors[props.theme].background.body1};
    scrollbar-color: ${props => Colors[props.theme].background.scrollFront} ${props => Colors[props.theme].background.scroll};
    scrollbar-width: thin;

    ::-webkit-scrollbar, * ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    }

    ::-webkit-scrollbar-thumb, * ::-webkit-scrollbar-thumb {
    background:  ${props => Colors[props.theme].background.scrollFront};
    }

::-webkit-scrollbar-track, * ::-webkit-scrollbar-track {
  background: ${props => Colors[props.theme].background.scroll};
}
`;

export {ContentUserView};
