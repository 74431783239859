const SortBy= (a: any, b: any): number => {

  if (a.symbol < b.symbol) return -1;
  if (a.symbol > b.symbol) return 1;
  return 0;
};
const SortByKey = (a: any, b: any, key: string): number => {

  var keyA = a[key],
    keyB = b[key];
  // Compare the 2 dates
  if (keyA < keyB) return -1;
  if (keyA > keyB) return 1;
  return 0;
};

export {SortBy, SortByKey};