import React from "react";
import { useContext } from "react";
import Countdown from 'react-countdown';
import Atoms from '../';

import { ContextBase } from "../../Hooks";

const TimeCountdown = (props:any) => {
  let com: any = useContext(ContextBase);
  
  const renderer = ({minutes, seconds}: any) => {
    return (
    <Atoms.Text fontFamily="secondary" size="60" textCenter block>
    {minutes}m {seconds}s
    </Atoms.Text>
    )
  }

  return (
    <Countdown 
      autoStart 
      date={Date.now() + (props.expires * 1000)} 
      renderer={renderer}
      onComplete={props.onExpiration}
    />
  )
};


export default TimeCountdown;