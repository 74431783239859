import Atoms from "../../Atoms"
import { ContentButton, HeaderContent, TopBar } from "../../Styles/Components/HeaderCard"

const HeaderCard = (props) => {

    return (
        <Atoms.Card
            HeaderCardHeight
        >
            <HeaderContent>
                <TopBar>
                    <ContentButton justifyContent="center">
                        <Atoms.Select name="select" alignItems noMargin display simple lg onChange={props.onInputChange("currency")}>
                            <Atoms.Option value="EUR/MXN">EUR/MXN</Atoms.Option>
                            <Atoms.Option value="EUR/PLZ">EUR/PLZ</Atoms.Option>
                            <Atoms.Option value="EUR/USD">EUR/USD</Atoms.Option>
                        </Atoms.Select>
                    </ContentButton>
                </TopBar>
            </HeaderContent>
        </Atoms.Card>
    )
}

export default HeaderCard