import Component1, { ComponetDummy } from "./Component1";
import Table from "./TableAg";
import InputPanel from "./InputPanel";
import TopCardClient from "./TopCardClient";
import HeaderCardClient from "./HeaderCardClient";
import HeaderProcessCardClient from "./HeaderCardClient";
import FooterCardClient from "./FooterCardClient";
import BodyCardClient from "./BodyCardClient";
import CardClient from "./CardClient";
import CardMarket from "./CardMarket";
import CardEDF from "./CardEDF";
import CardCP from "./CardCP";
import CardBlockTrades from "./CardTrades";
import CardImm from "./CardIMM";
import DatePickerCell from "./DatePickerCellEditor";
import DatePicker from "./DatePicker";

export default {
  Component1,
  Table,
  ComponetDummy,
  InputPanel,
  TopCardClient,
  HeaderCardClient,
  FooterCardClient,
  BodyCardClient,
  CardClient,
  CardMarket,
  CardEDF,
  CardCP,
  CardBlockTrades,
  CardImm,
  DatePickerCell,
  DatePicker,
};
