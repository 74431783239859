import { Grid } from "@material-ui/core";
import { useState, useContext, useEffect } from "react";
import { Inner } from "../../Styles/Pages/Content";
import HeaderCard from "../HeaderCard";
import BodyCardTrades from "./Body";
import FooterCardTrades from "./Footer";
import { FormatThousands } from "../../Utils/Format";
import { ContextBase, SocketMethods } from "../../Hooks";

const lp_list = [
    { "id": 1, "name": "lp1" },
    { "id": 2, "name": "lp2" },
    { "id": 3, "name": "lp3" }
]
let isselect = 0;

const CardBlockTrades = () => {
    const com: any = useContext(ContextBase);

    const normalSpans = ["TODAY", "TOM", "SN", "1W", "2W", "3W", "1M", "3M", "9M", "BMF"];
    const [currencies, setCurrencies] = useState<Array<string>>([]);
    const [value, setValue] = useState<string>('OUTRIGHT');
    const [currency, setCurrency] = useState<any>(0)
    const [presetSpanList, setPresetSpanList] = useState<Array<string>>(normalSpans);
    const [valueDate, setValueDate] = useState<number>();
    const [nearLeg, setNearLeg] = useState<number>();
    const [farLeg, setFarLeg] = useState<number>();
    const [valueDateSpan, setValueDateSpan] = useState<string>('');
    const [nearLegSpan, setNearLegSpan] = useState<string>('');
    const [farLegSpan, setFarLegSpan] = useState<string>('');
    const [notional, setNotional] = useState<string | number>();
    const [view, setView] = useState(true)
    const [requestStatus, setRequestStatus] = useState<string>();
    const [requestType, setRequestType] = useState<string>();
    const [bid, setBid] = useState("-");
    const [offer, setOffer] = useState("-");
    const [timestamp, settimestamp] = useState("-");
    const [client_order_id, setclient_order_id] = useState(0);

    const onToggle = (e: any, val: any) => {
        setValue(val)
    }

    const onInputChange = (input: string) => (event: any): void => {
        switch (input) {
            case "currency":
                setCurrency(event.target.value);
                break;
            case "notional":
                if (`${event.target.value}`.toLowerCase().includes("m")) {
                    setNotional(
                        FormatThousands(
                            (parseInt(`${event.target.value}`.toLowerCase().replace("m", "").replace(",", "")) * 1000000).toString()
                        )
                    );
                } else {
                    setNotional(FormatThousands(event.target.value));
                }
                break;
            case "20m":
                setNotional(FormatThousands("20000000"));
                break;
            case "50m":
                setNotional(FormatThousands("50000000"));
                break;
            case "100m":
                setNotional(FormatThousands("100000000"));
                break;
            case "valueDate":
                setValueDate(event.getTime());
                initSuscription(event.getTime());
                break;
            case "nearLeg":
                setNearLeg(event.getTime());
                initSuscription(event.getTime())
                break;
            case "farLeg":
                setFarLeg(event.getTime());
                break;
            default:
        }
    }

    const setDate = (input: string) => (event: any) => {
        if (!presetSpanList[parseInt(event.target.value)]) {
            return;
        }
        const selected: string = presetSpanList[parseInt(event.target.value)];

        const isSetteable: boolean = !["TOM", "SN", "BMF"].includes(selected);

        switch (input) {
            case "valueDate":
                if (isselect == 2 && valueDateSpan !== event.target.value)
                    isselect = 0
                isselect = isselect + 1;
                setValueDateSpan(event.target.value);
                if (isSetteable) {
                    setValueDate(getPeriodSpan(selected))
                    initSuscription(getPeriodSpan(selected), selected)
                }
                break;
            case "nearLeg":
                if (isselect == 2 && valueDateSpan !== event.target.value)
                    isselect = 0
                isselect = isselect + 1;
                setNearLegSpan(event.target.value);
                if (isSetteable) {
                    setNearLeg(getPeriodSpan(selected));
                    initSuscription(getPeriodSpan(selected), selected);
                }
                break;
            case "farLeg":
                setFarLegSpan(event.target.value);
                if (isSetteable) {
                    setFarLeg(getPeriodSpan(selected));
                }
                break;
            default:
                break;
        }
        //setErrors({ ...errors, [input]: false });
    };

    const getPeriodSpan = (spanString: string): number => {
        const now: Date = new Date();
        let span: number;

        switch (spanString) {
            case "TODAY":
                now.getTime();
                break;
            case "1W":
                now.setDate(now.getDate() - 7);
                break;
            case "2W":
                now.setDate(now.getDate() - 14);
                break;
            case "3W":
                now.setDate(now.getDate() - 21);
                break;
            case "1M":
                now.setMonth(now.getMonth() - 1);
                break;
            case "3M":
                now.setMonth(now.getMonth() - 3);
                break;
            case "9M":
                now.setMonth(now.getMonth() - 9);
                break;
            case "TOM":
                break; // ANY DATE | EDGEWATER'll IMPLEMENT
            case "SN":
                break; // ANY DATE | EDGEWATER'll IMPLEMENT
            case "BMF":
                break; // ANY DATE | EDGEWATER'll IMPLEMENT
            default:
                break;
        }

        span = now.getTime();
        return span;
    };

    const initSuscription = (date: number, datespan?: string) => {
        const pair: any = currencies.find((e: any) => e.instrument_id === parseInt(currency))
        let request: any = {
            "streamType": value,
            "instrumentId": parseInt(currency),
            "instrumentPair": pair ? pair.symbol : "",

            // "instrument_id": parseInt(currency),
            // "amount": notional,
            // "currency": pair ? pair.symbol : "",
            // "type": value,
            // "side": requestType,
            // "status": "WAIT",
            // "currencyFrom": 1,
            // "currencyTo": 0,
            // "currencyFromLabel": currency,
            // "currencyToLabel": "",
            // "RFQType": value,
            // "requestMode": requestType,
        }
        if (value === "OUTRIGHT") {
            request = {
                ...request,
                date,
                tenor: datespan
            }
            SocketMethods.rfsSubscribe(request);
        } else if (value === "SWAP") {
            const tenorFarLeg: string = presetSpanList[parseInt(farLegSpan)];
            request = {
                ...request,
                dateFar: farLeg,
                date,
                tenor: datespan,
                tenorFarLeg,
                "clientRequestId": null
            }
            SocketMethods.rfsSubscribe(request);
        }
    }

    const sendRequest = (requestType: string) => {
        const pair: any = currencies.find((e: any) => e.instrument_id === parseInt(currency))
        let request: any = {
            status: "REQUEST",
            currency: pair ? pair.symbol : "",
            // currencyFrom,
            // currencyTo,
            // currencyFromLabel,
            // currencyToLabel,
            RFQType: value,
            instrument_id: Number(currency),
            type: value,
            requestMode: requestType,
            side: requestType,
            expires: 10000,
            amount: notional,
            // spotRate,
            // frwdPoints,
            // allIn,
            // tradeId,
        };

        if (value === "OUTRIGHT") {
            const tenor: string = presetSpanList[parseInt(valueDateSpan)];
            request = {
                ...request,
                valueDate,
                tenor,
                // TODO
                // valueDateSpan,
            };
            if (requestType === "rfq") {
                const tenornearLeg: string = presetSpanList[parseInt(nearLegSpan)];
                const tenorfarLeg: string = presetSpanList[parseInt(farLegSpan)];
                request = {
                    ...request,
                    side: "SELL",
                    nearLeg,
                    farLeg,
                    nearLegSpan: tenornearLeg,
                    farLegSpan: tenorfarLeg,
                };
            }
        }

        if (value === "SWAP") {
            const tenorNearLeg: string = presetSpanList[parseInt(nearLegSpan)];
            const tenorFarLeg: string = presetSpanList[parseInt(farLegSpan)];
            request = {
                ...request,
                farLeg,
                nearLeg,
                // frwdPoints,//no indicados
                // swapPoints,//no indicados
                // TODO

                tenorNearLeg,
                tenorFarLeg,
            };
            if (requestType === "rfq") {
                request = {
                    ...request,
                    side: "SELL",
                    nearLeg,
                    farLeg,
                    nearLegSpan: tenorNearLeg,
                    farLegSpan: tenorFarLeg,
                };
            }
        }
        SocketMethods.rfqSubscribe(request);
    }

    const cancelRequest = () => {
        SocketMethods.rfsCancelOrder({
            msg_type: 'ORDER_REPLACE',
            is_cancel: 1,
            client_order_id: client_order_id
        });
    }

    const onStatusChange = (status: string) => {
        switch (status) {
            case "bid":
            case "rfq":
            case "offer":
                if ((value === "OUTRIGHT" && valueDate == undefined)
                    ||
                    (value === "SWAP" && (nearLeg === undefined || farLeg === undefined))
                    ||
                    (status === "rfq" && (nearLeg === undefined || farLeg === undefined))) {
                    alert("Please select dates")
                    return false;
                }
                if (notional === undefined) {
                    alert("Please select notional amount")
                    return false;
                }
                sendRequest(status);
                setRequestStatus("send");
                setRequestType(status);
                setView(false);
                break;
            case "canceled":
                setRequestStatus(status);
                cancelRequest();
                break;
            case "back":
                setRequestStatus('');
                setView(true);
                break;
            case "waiting":
                setRequestStatus(status);
                break;
            case "expired":
                setRequestStatus(status);
                break;
            case "rejected":
                setRequestStatus(status);
                break;
            case "done":
                setRequestStatus(status);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setCurrencies(com.state.instruments);
    }, [com.state.instruments]);

    useEffect(() => {
        if (com.state.RFQ_UPDATE.asks) {
            const ask = com.state.RFQ_UPDATE.asks;
            const bid = com.state.RFQ_UPDATE.bids
            if (ask[0] && bid[0]) {
                setBid(ask[0].price);
                setOffer(bid[0].price);
                settimestamp(com.state.RFQ_UPDATE.timestamp)
            }
            setclient_order_id(com.state.RFQ_UPDATE.client_request_id)
        }
    }, [com.state.RFQ_UPDATE]);

    const requestAsProps = {
        value,
        currencies,
        valueDate,
        valueDateSpan,
        notional,
        nearLeg,
        farLeg,
        nearLegSpan,
        farLegSpan,
        currency,
        requestStatus,
        view,
        requestType,
        presetSpanList,
        lp_list,
        bid,
        offer,
        timestamp
    }

    return (
        <Grid container style={{ height: "100%", overflowX: "auto" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
                <Inner>
                    <HeaderCard
                        onInputChange={onInputChange}
                        onSetToday={setDate}
                        onToggle={onToggle}
                        {...requestAsProps}
                    />
                    <BodyCardTrades
                        onInputChange={onInputChange}
                        {...requestAsProps}
                        onStatusChange={onStatusChange}
                    />
                    <FooterCardTrades
                        requestStatus={requestStatus}
                        requestType={requestType}
                        onStatusChange={onStatusChange}
                    />
                </Inner>
            </Grid>
        </Grid>
    )
}

export default (CardBlockTrades)