import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {Card} from "../../Styles/Atoms/Card";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (<Card theme={com.state.theme}
    TopCardHeight={props.TopCardHeight}
    BodyCardHeight={props.BodyCardHeight}
    BodyCardConfirm={props.BodyCardConfirm}
    BodyCardAnswer={props.BodyCardAnswer}
    HeaderCardHeight={props.HeaderCardHeight}
    FooterCardHeight={props.FooterCardHeight}
    CardClient={props.CardClient}
    CardMarket={props.CardMarket}
    bodyCard={props.bodyCard}
    fullHeight={props.fullHeight}
    BodyDialog={props.BodyDialog}
  >{props.children}</Card>);
};
