import { Colors, FontSize, Fonts } from "../../Constants";

import Styled, {css, keyframes} from "styled-components";


const Bouncing = keyframes`
0% {
  top: 36px;
  left: 36px;
  width: 0;
  height: 0;
  opacity: 1;
}
100% {
  top: 0px;
  left: 0px;
  width: 72px;
  height: 72px;
  opacity: 0;
}
`;

const LoaderBounce = Styled.div<any>`
display: block;
position: relative;
width: 80px;
height: 80px;
margin: 0 auto;
  div {
    position: absolute;
    border: 4px solid #E96815;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation: ${Bouncing} 1s linear;
    animation-iteration-count: infinite;
    &:nth-child(2) {
      animation-delay: -0.5s;

    }
  }
  

`;


export {LoaderBounce, Bouncing};