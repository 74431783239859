import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";

import { ContextBase } from "../../Hooks";
import Atoms from "../../Atoms";
import Components from "../../Components";
import { Validation } from "../../Utils/Validations";
import { SimpleDateToString } from "../../Utils/Date";
import { Currency } from "../../Models/Components/CardClient";
import { SortBy, SortByKey } from "../../Utils/Sort";

const HeaderCardClient = (props: any) => {
  const com: any = useContext(ContextBase);

  const onKeyPress = (event: any) => {
    const inputValue = event.target.value;
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    const cleanValue = inputValue.split(",").join("").split(".").join("");
    const repeatedChars = inputValue.match(/([.|,]{2,})/g);
    if (
      cleanValue.length >= 20 ||
      inputValue.indexOf(",,") !== -1 ||
      repeatedChars !== null ||
      !Validation.isCurrency(keyValue)
    ) {
      event.preventDefault();
      return;
    }
  };

  const onCurChange = (event: any) => {
    const inputValue = event.target.value;
    if (props.onCurrencyChange) {
      props.onCurrencyChange(inputValue);
    }
  };
  return (
    <Atoms.Card
      fullHeight={props.fullHeight}
      HeaderCardHeight
      CardClient={props.clientView}
      CardMarket={props.marketView}
    >
      {props.clientView && (
        <>
          {props.RFQType && props.RFQType === "OUTRIGHT" && (
            <>
              {props.request.status === "REQUEST" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8} className="alignRight">
                    <Atoms.Select
                      name="select"
                      column
                      display
                      value={props.currencyFrom}
                      onChange={onCurChange}
                    >
                      {props.currenciesAvailable &&
                        props.currenciesAvailable.length > 0 &&
                        props.currenciesAvailable
                          .sort(SortByKey)
                          .map((currency: Currency, curIdx: number) => (
                            <Atoms.Option value={curIdx}>
                              {currency}
                            </Atoms.Option>
                          ))}
                    </Atoms.Select>
                    <Atoms.Input
                      headerCard
                      type="text"
                      value={props.amount}
                      error={props.errors && props.errors.amount}
                      onKeyPress={onKeyPress}
                      onChange={props.onInputChange("amount")}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8} className="alignRight">
                    <Atoms.Select
                      name="select"
                      column
                      display
                      value={props.valueDateSpan}
                      onChange={props.onSetToday("valueDate")}
                    >
                      <Atoms.Option value={null}></Atoms.Option>
                      {props.presetSpanList &&
                        props.presetSpanList.length > 0 &&
                        props.presetSpanList.map(
                          (span: Currency, spanIdx: number) => (
                            <Atoms.Option value={spanIdx}>{span}</Atoms.Option>
                          )
                        )}
                    </Atoms.Select>
                    {/* <Atoms.ButtonInline type="button" onClick={props.onSetToday('valueDate')} arrowIcon>TD</Atoms.ButtonInline> */}
                    <Atoms.DatePicker
                      headerCard
                      dateFormat="dd/MM/yyyy"
                      selected={props.valueDate}
                      onChange={props.onInputChange("valueDate")}
                      error={props.errors && props.errors.valueDate}
                    />
                  </Grid>
                </Grid>
              )}

              {["WAIT", "CANCEL", "REJECT", "ANSWER", "EXPIRED"].includes(
                props.request.status
              ) && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      {props.requestMode === "SELL" && "SELL "}
                      {props.requestMode === "BUY" && "BUY "}
                      {props.requestMode === "BOTH" && "BUY/SELL "}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyLabel}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyFromLabel}
                      {": "}
                      {props.amount}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      {props.valueDateSpan && (
                        <span>
                          {props.presetSpanList[props.valueDateSpan]}&nbsp;
                        </span>
                      )}
                      {props.valueDate && SimpleDateToString(props.valueDate)}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" fontFamily="book" textLeft labelCard>
                      TRADE ID:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      labelCard
                    >
                      {props.tradeId}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SPOT" && (
            <>
              {props.request.status === "REQUEST" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8} className="alignRight">
                    <Atoms.Select
                      name="select"
                      column
                      display
                      value={props.currencyFrom}
                      onChange={onCurChange}
                    >
                      {props.currenciesAvailable &&
                        props.currenciesAvailable.length > 0 &&
                        props.currenciesAvailable
                          .sort(SortByKey)
                          .map((currency: Currency, curIdx: number) => (
                            <Atoms.Option value={curIdx}>
                              {currency}
                            </Atoms.Option>
                          ))}
                    </Atoms.Select>
                    <Atoms.Input
                      headerCard
                      type="text"
                      value={props.amount}
                      error={props.errors && props.errors.amount}
                      onKeyPress={onKeyPress}
                      onChange={props.onInputChange("amount")}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8} className="alignRight">
                    {/* <Atoms.Select name="select" column display value={props.valueDateSpan} onChange={props.onSetToday('valueDate')}>
                  <Atoms.Option value={null}></Atoms.Option >
                  {(props.presetSpanList && props.presetSpanList.length > 0) && props.presetSpanList
                  .map((span: Currency, spanIdx: number)=>(<Atoms.Option value={spanIdx}>{span}</Atoms.Option >))}
                </Atoms.Select> */}
                    <Atoms.ButtonInline
                      type="button"
                      onClick={props.onSetToday("valueDate")}
                      arrowIcon
                      disabled
                    >
                      SPOT
                    </Atoms.ButtonInline>
                    <Atoms.DatePicker
                      headerCard
                      dateFormat="dd/MM/yyyy"
                      selected={props.valueDate}
                      onChange={props.onInputChange("valueDate")}
                      error={props.errors && props.errors.valueDate}
                      disabled
                    />
                  </Grid>
                </Grid>
              )}

              {["WAIT", "CANCEL", "REJECT", "ANSWER", "EXPIRED"].includes(
                props.request.status
              ) && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      {props.requestMode === "SELL" && "SELL "}
                      {props.requestMode === "BUY" && "BUY "}
                      {props.requestMode === "BOTH" && "BUY/SELL "}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyLabel}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyFromLabel}
                      {": "}
                      {props.amount}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      {props.valueDateSpan && (
                        <span>
                          {props.presetSpanList[props.valueDateSpan]}&nbsp;
                        </span>
                      )}
                      {props.valueDate && SimpleDateToString(props.valueDate)}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" fontFamily="book" textLeft labelCard>
                      TRADE ID:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      labelCard
                    >
                      {props.tradeId}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SWAP" && (
            <>
              {props.request.status === "REQUEST" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      NEAR LEG:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8} className="alignRight">
                    <Atoms.Select
                      name="select"
                      column
                      display
                      value={props.nearLegSpan}
                      onChange={props.onSetToday("nearLeg")}
                    >
                      <Atoms.Option value={null}></Atoms.Option>
                      {props.presetSpanList &&
                        props.presetSpanList.length > 0 &&
                        props.presetSpanList.map(
                          (span: Currency, spanIdx: number) => (
                            <Atoms.Option value={spanIdx}>{span}</Atoms.Option>
                          )
                        )}
                    </Atoms.Select>
                    <Atoms.DatePicker
                      headerCard
                      error={props.errors && props.errors.nearLeg}
                      dateFormat="dd/MM/yyyy"
                      selected={props.nearLeg}
                      onChange={props.onInputChange("nearLeg")}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      FAR LEG:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8} className="alignRight">
                    <Atoms.Select
                      name="select"
                      column
                      display
                      value={props.farLegSpan}
                      onChange={props.onSetToday("farLeg")}
                    >
                      <Atoms.Option value={null}></Atoms.Option>
                      {props.presetSpanList &&
                        props.presetSpanList.length > 0 &&
                        props.presetSpanList.map(
                          (span: Currency, spanIdx: number) => (
                            <Atoms.Option value={spanIdx}>{span}</Atoms.Option>
                          )
                        )}
                    </Atoms.Select>
                    <Atoms.DatePicker
                      headerCard
                      error={props.errors && props.errors.farLeg}
                      dateFormat="dd/MM/yyyy"
                      selected={props.farLeg}
                      onChange={props.onInputChange("farLeg")}
                    />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8} className="alignRight">
                    <Atoms.Select
                      name="select"
                      column
                      display
                      value={props.currencyFrom}
                      onChange={onCurChange}
                    >
                      {props.currenciesAvailable &&
                        props.currenciesAvailable.length > 0 &&
                        props.currenciesAvailable
                          .sort(SortByKey)
                          .map((currency: Currency, curIdx: number) => (
                            <Atoms.Option value={curIdx}>
                              {currency}
                            </Atoms.Option>
                          ))}
                    </Atoms.Select>
                    <Atoms.Input
                      headerCard
                      error={props.errors && props.errors.amount}
                      type="text"
                      value={props.amount}
                      onKeyPress={onKeyPress}
                      onChange={props.onInputChange("amount")}
                    />
                  </Grid>
                </Grid>
              )}

              {["WAIT", "CANCEL", "REJECT", "ANSWER", "EXPIRED"].includes(
                props.request.status
              ) && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      {props.requestMode === "SELL" && "SELL "}
                      {props.requestMode === "BUY" && "BUY "}
                      {props.requestMode === "BOTH" && "BUY/SELL "}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyLabel}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyFromLabel}
                      {": "}
                      {props.amount}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      NEAR LEG:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="light"
                      textRight
                      block
                      gray
                    >
                      {props.nearLegSpan && (
                        <span>
                          {props.presetSpanList[props.nearLegSpan]}&nbsp;
                        </span>
                      )}
                      {props.nearLeg && SimpleDateToString(props.nearLeg)}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      FAR LEG:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="light"
                      textRight
                      block
                      gray
                    >
                      {props.farLegSpan && (
                        <span>
                          {props.presetSpanList[props.farLegSpan]}&nbsp;
                        </span>
                      )}
                      {props.farLeg && SimpleDateToString(props.farLeg)}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" fontFamily="book" textLeft labelCard>
                      TRADE ID:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      labelCard
                    >
                      {props.tradeId}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}

      {props.marketView && (
        <>
          {props.RFQType && props.RFQType === "OUTRIGHT" && (
            <>
              {["REQUEST", "ANSWER", "CANCEL", "EXPIRED"].includes(
                props.request.status
              ) && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      CLIENT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.client}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      USER:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.clientUser}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      {props.requestMode === "SELL" && "SELL "}
                      {props.requestMode === "BUY" && "BUY "}
                      {props.requestMode === "BOTH" && "BUY/SELL "}
                      {`:`}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyLabel}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyFromLabel}
                      {": "}
                      {props.amount}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      {props.valueDateSpan && (
                        <span>
                          {props.presetSpanList[props.valueDateSpan]}&nbsp;
                        </span>
                      )}
                      {props.valueDate && SimpleDateToString(props.valueDate)}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "WAIT" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      SELL
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      USD/MXN
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      USD: 1,550,000.00
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      TD: 1/10/2020
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      CLIENT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.client}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      USER:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.clientUser}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" fontFamily="bold" textLeft labelCard>
                      TRADE ID:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      labelCard
                    >
                      {props.tradeId}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SPOT" && (
            <>
              {["REQUEST", "ANSWER",  "CANCEL", "EXPIRED"].includes(
                props.request.status
              ) && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      CLIENT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.client}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      USER:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.clientUser}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      {props.requestMode === "SELL" && "SELL "}
                      {props.requestMode === "BUY" && "BUY "}
                      {props.requestMode === "BOTH" && "BUY/SELL "}
                      {`:`}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyLabel}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyFromLabel}
                      {": "}
                      {props.amount}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      {props.valueDateSpan && (
                        <span>
                          {props.presetSpanList[props.valueDateSpan]}&nbsp;
                        </span>
                      )}
                      {props.valueDate && SimpleDateToString(props.valueDate)}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "WAIT" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      SELL
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      USD/MXN
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      USD: 1,550,000.00
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      TD: 1/10/2020
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      CLIENT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.client}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      USER:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.clientUser}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" fontFamily="bold" textLeft labelCard>
                      TRADE ID:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      labelCard
                    >
                      {props.tradeId}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}
            </>
          )}

          {props.RFQType && props.RFQType === "SWAP" && (
            <>
              {["REQUEST", "ANSWER", "CANCEL", "EXPIRED"].includes(
                props.request.status
              ) && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      CLIENT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.request.client}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      USER:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.request.clientUser}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      {props.requestMode === "SELL" && "SELL"}
                      {props.requestMode === "BUY" && "BUY"}
                      {props.requestMode === "BOTH" && "BUY/SELL"}
                      {`:`}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyLabel}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      {props.currencyFromLabel}
                      {": "}
                      {props.amount}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      NEAR LEG:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      {props.nearLegSpan && (
                        <span>
                          {props.presetSpanList[props.nearLegSpan]}&nbsp;
                        </span>
                      )}
                      {props.nearLeg && SimpleDateToString(props.nearLeg)}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      FAR LEG:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      {props.farLegSpan && (
                        <span>
                          {props.presetSpanList[props.farLegSpan]}&nbsp;
                        </span>
                      )}
                      {props.farLeg && SimpleDateToString(props.farLeg)}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}

              {props.request.status === "WAIT" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      CLIENT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      EDGEWATER FORCE SALES
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      USER:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      JUAN MANUEL LOZANO
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      SELL:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      USD/MXN
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      AMOUNT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="bold"
                      textRight
                      block
                      labelCard
                    >
                      USD: 1,550,000.00
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      VALUE DATE:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      gray
                    >
                      TD: 1/10/2020
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}
              {props.request.status === "CONFIRM" && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justify="center"
                  direction="row"
                >
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      CLIENT:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.client}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" textLeft labelCard>
                      USER:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text size="18" fontFamily="bold" textRight block>
                      {props.clientUser}
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Atoms.Text size="18" fontFamily="bold" textLeft labelCard>
                      TRADE ID:
                    </Atoms.Text>
                  </Grid>
                  <Grid item xs={8} md={8}>
                    <Atoms.Text
                      size="18"
                      fontFamily="secondary"
                      textRight
                      block
                      labelCard
                    >
                      {props.tradeId}
                    </Atoms.Text>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Atoms.Card>
  );
};

export default HeaderCardClient;
