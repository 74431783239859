import {Colors, FontSize, Fonts} from '../../Constants';
import styled, {css} from 'styled-components';
import Typography from '@material-ui/core/Typography';

const ContentText = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  margin-top: 3px;

  input{
    border-radius:4px;
    max-width: ${props => props.maxWidth ? props.maxWidth : "100px"};
    ${FontSize.sz18};
    font-family:${Fonts.primaryMedium};
    text-align:right;
    margin: 5px 0px;
    ${props => props.seconds && css`
      max-width: 60px;
    `}
  }

  ${props => props.important && css`
  border: 1px solid;
  border-color: #E96815;
  border-radius: 4px;
  padding: 5px 10px 5px;
  margin: 5px 0px 0px 0px;
  width: inherit;
  `}
  ${props => props.importantMargin && css`
  margin: 5px 5px 0px 0px;
  `}

  ${props => props.allInInput && css`
  margin: 10px -10px 10px;
  `}
 
  

`;
const Text = styled(Typography)<any>`
  color: #E96815;
  font-family:${Fonts.primaryMedium};
  display:inline-block;
  margin-bottom: 0px !important;
  ${FontSize.sz14};

  ${props => props.hiddenMovile && css`
  @media screen and (max-width:370px){
  display:none !important;
  }
  `}

  ${props => props.threeColumn && css`
  min-width: 42%;
  `}


  ${props => props.noMargin && css`
  margin: 0 !important;
  `}
  ${props => props.textNoWrap && css`
  white-space: nowrap !important;
  `}
  ${props => props.textCenter && css`
    text-align: center !important;
  `}
  ${props => props.textRight && css`
    text-align: right !important;
  `}
  ${props => props.textLeft && css`
    text-align: left !important;
  `}
  ${props => props.uppercase && css`
    text-transform: uppercase !important;
  `}
  ${props => props.block && css`
    display:block;
  `}

  ${props => props.mr && css`
    margin-right: 0.5em !important;
  `}

  ${props => props.titleCard && css`
    color: ${props => Colors[props.theme].primary.title} !important;
  `}
  ${props => props.labelCard && css`
    color: #ffffff !important;
  `}
  ${props => props.white && css`
  color: ${props => Colors[props.theme].primary.invert} !important;
  `}
  ${props => props.gray && css`
  color: ${props => Colors[props.theme].disabled.main};
  `}
  ${props => props.bold && css`
  font-family:${Fonts.primaryBold};
  `}

  ${props => props.fontFamily === "bold" && css`
    font-family:${Fonts.primaryBold};
  `}
  ${props => props.fontFamily === "book" && css`
    font-family:${Fonts.primaryBook};
  `}
  ${props => props.fontFamily === "secondary" && css`
    font-family:${Fonts.secondary};
  `}

  ${props => props.size === "9" && css`
    /* font-size: 9px !important; */
    ${FontSize.sz9};
  `}
  ${props => props.size === "10" && css`
    /* font-size: 10px !important; */
    ${FontSize.sz10};
  `}
  ${props => props.size === "11" && css`
    /* font-size: 11px !important; */
    ${FontSize.sz11};
  `}
  ${props => props.size === "12" && css`
    /* font-size: 12px !important; */
    ${FontSize.sz12};
  `}
  ${props => props.size === "14" && css`
    /* font-size: 14px !important; */
    ${FontSize.sz14};
  `}
  ${props => props.size === "16" && css`
    /* font-size: 16px !important; */
    ${FontSize.sz16};
  `}
  ${props => props.size === "18" && css`
    /* font-size: 18px !important; */
    ${FontSize.sz18};
  `}
  ${props => props.size === "20" && css`
    /* font-size: 20px !important; */
    ${FontSize.sz20};
  `}
  ${props => props.size === "22" && css`
    /* font-size: 22px !important; */
    ${FontSize.sz22};
  `}
  ${props => props.size === "24" && css`
    /* font-size: 24px !important; */
    ${FontSize.sz24};
  `}
  ${props => props.size === "26" && css`
    /* font-size: 26px !important; */
    ${FontSize.sz26};
  `}
  ${props => props.size === "28" && css`
    /* font-size: 28px !important; */
    ${FontSize.sz28};
  `}
  ${props => props.size === "60" && css`
  /* font-size: 60px !important; */
  ${FontSize.sz60};
  `}
  ${props => props.size === "75" && css`
  /* font-size: 75px !important; */
  ${FontSize.sz75};
  line-height: 1.1 !important;
  `}

  ${props => props.color === "primaryMain" && css`
    color: ${props => Colors[props.theme].primary.main};
  `}
  ${props => props.color === "primaryLight" && css`
    color: ${props => Colors[props.theme].primary.light};
  `}
  ${props => props.color === "secondary" && css`
    color: ${props => Colors[props.theme].secondary.light};
  `}
  ${props => props.color === "error" && css`
    color: ${props => Colors[props.theme].error.light}!important;
  `}
  ${props => props.color === "warning" && css`
    color: ${props => Colors[props.theme].warning.light};
  `}
  ${props => props.color === "success" && css`
    color: ${props => Colors[props.theme].success.light} !important;
  `}
  ${props => props.color === "info" && css`
    color: ${props => Colors[props.theme].info.light};
  `}
`

export {ContentText,Text};
