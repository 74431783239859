import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  useCallback,
} from "react";
import Components from "../../Components";
import Atoms from "../../Atoms";
import { ContextBase, SocketMethods } from "../../Hooks";
import { Currency, Request } from "../../Models/Components/CardClient";
import { aRows } from "../../Utils/MockData";
import moment from "moment";

const MarketView = () => {
  const com: any = useContext(ContextBase);

  useEffect(() => {
    SocketMethods.rfqmaker(com.state, com.dispatch);
    if (localStorage.getItem("rfq_theme_default")) {
      com.dispatch({
        type: "CHANGE_THEME_MODE",
        payload: { theme: localStorage.getItem("rfq_theme_default") },
      });
    }
  }, []);

  const aColumns = [
    {
      headerName: "Status",
      minWidth: "190",
      field: "status",
      cellRenderer: "cellIconRenderer",
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
      // https://www.ag-grid.com/react-data-grid/filter-quick/
      // getQuickFilterText: function (params) {
      //   return params.value.name;
      // },

      // cellRendererParams: { checkbox: true },
    },

    {
      headerName: "Type",
      field: "type",
      minWidth: "120",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Quoting",
      field: "makerOrganization",
      minWidth: "130",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Client",
      field: "organization",
      minWidth: "170",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Client User",
      field: "username",
      minWidth: "150",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "CCY Pair",
      field: "currency",
      minWidth: "135",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Base Amount",
      field: "amount",
      minWidth: "160",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) => `$${params.value}`,
    },
    {
      headerName: "Bank Side",
      field: "side",
      minWidth: "140",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "All-In",
      field: "allIn",
      minWidth: "130",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Date",
      field: "date",
      minWidth: "130",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) =>
        `${moment(params.value).format("DD/MM/YYYY")}`,
    },
    {
      headerName: "Creation Time",
      field: "date",
      minWidth: "160",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) =>
        `${moment(params.value).format("hh:mm:ss")}`,
    },
    {
      headerName: "Execution Time",
      field: "executionTime",
      minWidth: "130",
      sortable: true,
      sort: "asc",
      resizable: true,
      flex: 1,
    },
  ];

  const aRowClassRules = {
    // 'row-highlight-new': (params: any): boolean =>  (params.data.status === 'New'),
    "row-highlight-new": function (params: any) {
      console.log(params.data);
      return params.data.status=== "NEW";
    },
    "row-highlight-dealing": function (params: any) {
      return params.data.status=== "DEALING";
    },
    "row-highlight-rejected": function (params: any) {
      return params.data.status=== "REJECTED";
    },
    "row-highlight-user-cancel": function (params: any) {
      return params.data.status=== "CANCELED";
    },
    "row-highlight-trader-cancel": function (params: any) {
      return params.data.status=== "CANCELED";
    },

    "row-highlight-done": function (params: any) {
      return (
        params.data.status=== "Done" ||
        params.data.status=== "FILLED"
      );
    },
  };

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<any>>(aColumns);
  // const [rows, setRows] = useState<Array<Request>>(aRows);
  const [rows, setRows] = useState<Array<Request>>([]);

  const [rowClassRules, setRowClassRules] = useState<any>(aRowClassRules);
  useEffect(() => {
    if (com.state.ordersUpdate !== false) {
      console.log("---> update", com.state.ordersUpdate);
      const orderupdate = com.state.orders.findIndex(
        (item: any) =>
          item.client_order_id === com.state.ordersUpdate.client_order_id
      );
      if (orderupdate === -1) return;
      let tempOrds = com.state.orders;
      tempOrds[orderupdate] = com.state.ordersUpdate;
      console.log(com.state.order_dealing);
      if (
        com.state.order_dealing !== false &&
        com.state.order_dealing.client_order_id ===
          com.state.ordersUpdate.client_order_id
      ) {
        console.log(
          com.state.ordersUpdate.status === "CANCELED"
            ? { ...com.state.ordersUpdate, requestStatus: "CANCEL" }
            : com.state.ordersUpdate
        );
        switch (com.state.ordersUpdate.status) {
          case "REJECTED":
            setRequestRow({
              ...com.state.ordersUpdate,
              requestStatus: "REJECT",
            });
            break;
          case "CANCELLED":
            setRequestRow({
              ...com.state.ordersUpdate,
              requestStatus: "CANCEL",
            });
            break;
          case "FILLED":
            console.log("FILLED");
            setRequestRow({
              ...com.state.ordersUpdate,
              requestStatus: "CONFIRM",
            });
            break;
          default:
            setRequestRow(com.state.ordersUpdate);
            break;
        }
      }
      com.dispatch({ type: "RFQ_MAKER_ORDERS_UPDATE_RESET", payload: false });
      setRows([]);
      com.dispatch({ type: "RFQ_MAKER_UPDATE_ORDER", payload: tempOrds });
    } else {
      setRows(com.state.orders);
    }
  }, [com.state.ordersUpdate, com.state.orders]);

  const [show, setShow] = useState(true);
  const [request, setRequest] = useState<any>();
  const [requestRow, setRequestRow] = useState<any>();

  const [requestTimeout, setRequestTimeout] = useState<any>();
  // const [currencies, setCurrencies] = useState<Currency[]>([
  //   {label: 'EUR', values:['USB', 'PLZ', 'MXN', 'USD']},
  //   {label: 'USD', values:['USB', 'PLZ', 'MXN', 'CAD']},
  // ]);
  const [currencies, setCurrencies] = useState<Array<string>>([
    // "EUR/USD",
    // "EUR/PLZ",
    // "EUR/MXN",
    // "USD/CAD",
    // {label: 'PLZ', values:['USB', 'EUR', 'MXN']},
  ]);
  useEffect(() => {
    setCurrencies(com.state.instruments);
  }, [com.state.instruments]);
  const [role, setRole] = useState<string>(
    localStorage.getItem("rfq_user_role") || "USER"
  );

  const onRowSelection = (event: Array<Request>): void => {
    console.log("On Row Selection", event);
  };

  const onRowClick = async (row: Request | any): Promise<void> => {
    console.log("rowclick", row, displayDialog);
    if (["REQUEST", "DEALING", "NEW"].includes(row.status)) {
      //  setRequest(row);
      if (row.status === "DEALING") {
        handleSendRequest(row, true);
      } else {
        row.realstatus = row.status;
        row.status = "REQUEST";
        setRequestRow(row);
      }

      setDisplayDialog(true);
    }
  };

  const onDialogClose = () => {
    // if (requestTimeout) {
    //   clearTimeout(requestTimeout);
    // }
    console.log("onDialogClose");
    setDisplayDialog(false);
  };

  const onRequestStepChange = (status: string, data: any) => {
    if (requestTimeout) {
      clearTimeout(requestTimeout);
    }
    switch (status) {
      case "SEND":
        console.log("SEND", data);
        handleSendRequest(data);
        break; // TO NEW
      case "CANCEL":
        handleCancelRequest(data);
        break; // USER CANCEL
      case "MODIFY_QUOTES":
        handleModifyRequest(data);
        break; // USER CANCEL
      case "BACK":
        handleGoBackRequest(data);
        break; // NO STATUS
    }
  };

  const handleModifyRequest = async (data: any) => {
    data.status = "REQUEST_UPDATE"; // <- request_Updated
    // await setRequest(data);
    SocketMethods.rfqMakerOrderUpdate({
      client_order_id: data.client_order_id,
    });
    await setRequestRow(data);
  };

  const handleSendRequest = async (data: any, inner?: boolean) => {
    data.status = "ANSWER";
    data.requestStatus = "ANSWER";

    if (!data.expires) {
      data.expires = 5;
      data.expireTime = 5;
    }
    data.expires = Number(data.expires);
    console.log("handlesendrequest", data);
    // setRequest(data);
    SocketMethods.rfqMakerOrdeCreate({
      ...data,
      client_order_id: data.tradeId,
    });
    com.dispatch({ type: "RFQ_MAKER_DEALING", payload: data });
    setRequestRow(data);

    // await new Promise((resolve) => {
    //   const timeout = setTimeout(resolve, data.expires * 1000);
    //   setRequestTimeout(timeout);
    // });

    // await handleDealRequest(data, inner);// for expires
  };

  const handleDealRequest = (data: any, inner?: boolean): void => {
    if (data.fixedResponse && data.fixedResponse === "EXPIRED") {
      data.status = "EXPIRED";
      const clone = JSON.parse(JSON.stringify(data));
      setRequest(clone);
      return;
    }

    data.status = "CONFIRM";
    data.tradeId = "1147:5588462";
    if (request && request.requestMode === "BOTH") {
      data.requestMode = "SELL";
    }

    if (request && request.requestMode === "BOTH" && role === "ADMIN") {
      if (data.RFQType === "OUTRIGHT" || data.RFQType === "SPOT") {
        const mode = data.requestMode === "SELL" ? "Sell" : "Buy";
        data.spotRate = data[`spotMarket${mode}`];
        data.frwdPoints = data[`frwdPoints${mode}`];
        data.allIn = data[`allIn${mode}`];
      }
      if (data.RFQType === "SWAP") {
        const mode = data.requestMode === "SELL" ? "Sell" : "Buy";
        data.swapPoints = data[`swapPoints${mode}`];
      }
    }

    const clone = JSON.parse(JSON.stringify(data));

    setRequest(clone);
  };

  const handleCancelRequest = async (data: any) => {
    console.log("cancel", data);
    if (requestTimeout) {
      clearTimeout(requestTimeout);
    }

    if (["DEALING", "REQUEST_UPDATE"].includes(data.status)) {
      SocketMethods.rfqMakerOrderCancel(data);
      console.log("cancel order");
    }
    data.status = "CANCEL";

    await setRequest(data);
    await setRequestRow(data);
  };

  const handleGoBackRequest = async (data: any) => {
    if (requestTimeout) {
      clearTimeout(requestTimeout);
    }
    onDialogClose();
  };

  const gridRef = useRef<any>();
  const onFilterTextBoxChanged = useCallback((textInput) => {
    if (gridRef.current != undefined) {
      gridRef.current.api.setQuickFilter(textInput.target.value);
    }
  }, []);

  return (
    <div>
      <Atoms.InputSearch
        id="filter-text-box"
        type="text"
        placeholder="Search..."
        onChange={onFilterTextBoxChanged}
      />
      <Components.Table
        rows={rows}
        columns={columns}
        rowClassRules={aRowClassRules}
        rowSelection={"single"}
        onRowSelection={onRowSelection}
        onRowClick={onRowClick}
        pagination={true}
        reference={gridRef}
      />
      <Atoms.Dialog open={displayDialog}
        showButton={show}
        buttonClose={onDialogClose}
      >
        <Components.CardClient
          marketView
          onClose={onDialogClose}
          role={role}
          currencies={currencies}
          request={requestRow}
          onWidgetStepChange={onRequestStepChange}
        />
      </Atoms.Dialog>
    </div>
  );
};

export default MarketView;
