import { Colors, FontSize, Fonts } from "../../Constants";
import Styled, {css, keyframes} from "styled-components";
import LinearProgress from '@material-ui/core/LinearProgress';

const ContentProgress = Styled.div<any>`
  background: ${props => Colors[props.theme].primary.card};
  padding: 7px;
  border-radius:20px;
  width: 60%;
  margin:0 auto;
  margin-bottom: 25px;
  box-shadow: 1px 1px 8px -3px rgba(255,255,255,0.75);
-webkit-box-shadow: 1px 1px 8px -3px rgba(255,255,255,0.75);
-moz-box-shadow: 1px 1px 8px -3px rgba(255,255,255,0.75);
`;

const Progress = Styled(LinearProgress)<any>`
&& {
  height:7px !important;
  align-items:center;
  & .MuiLinearProgress-bar{
    
  }
  & .MuiLinearProgress-bar1Buffer{
    background: ${props => Colors[props.theme].success.main} !important;
  }
  & .MuiLinearProgress-bar2Buffer{
    background: ${props => Colors[props.theme].disabled.main} !important;
  }
  & .MuiLinearProgress-dashedColorPrimary{
    background-image: radial-gradient(rgb(206, 206, 206) 0%, rgb(206, 206, 206) 16%, transparent 42%);
    top: 1px !important;
  }
}
`;


export {ContentProgress, Progress};