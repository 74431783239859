import React, { useEffect, useContext, useState, MouseEvent } from "react";
import Components from "../../Components";
import Atoms from "../../Atoms";
import Grid from "@material-ui/core/Grid";
import { ContentClientView } from "../../Styles/Pages/ClientView";
import { ContextBase, SocketMethods } from "../../Hooks";
import { Currency, Request } from "../../Models/Components/CardClient";

import { ToggleButtonGorupStyle } from "../../Styles/Atoms/Button";
import { rfqsubscribe } from "../../Hooks/Interfaces/requests";
const ClientView = () => {
  const com: any = useContext(ContextBase);
  // console.log(SocketMethods);

  useEffect(() => {
    SocketMethods.rfqmaker(com.state, com.dispatch);
    if (localStorage.getItem("rfq_theme_default")) {
      com.dispatch({
        type: "CHANGE_THEME_MODE",
        payload: { theme: localStorage.getItem("rfq_theme_default") },
      });
    }
    // SocketMethods.instruments();
  }, []);
  const InitialRequest: Request = { status: "REQUEST", RFQType: "OUTRIGHT" };
  const [requesTimeout, setRequestTimeout] = useState<any>();
  const [request, setRequest] = useState<Request>(InitialRequest);

  const [currencies, setCurrencies] = useState<Array<string>>([
    // "EUR/USD",
    // "EUR/PLZ",
    // "EUR/MXN",
    // {label: 'PLZ', values:['USB', 'EUR', 'MXN']},
  ]);
  useEffect(() => {
    // let x = com.state.instruments.map((i: any) => i.display_name);
    // com.state.instruments.map((i: any) => console.log(i.display_name));
    // console.log(x);
    // setCurrencies(x);
    setCurrencies(com.state.instruments);
  }, [com.state.instruments]);
  useEffect(() => {
    if (
      com.state.ordersUpdate !== false &&
      com.state.ordersUpdate.status == "CANCELED"
    ) {
      console.log(com.state.ordersUpdate, "CANCELED");

      handleCancelRequest(com.state.ordersUpdate);
      com.dispatch({ type: "RFQ_TAKER_CANCEL_ADMIN", payload: false });
    }
  }, [com.state.ordersUpdate]);

  useEffect(() => {
    if (com.state.order_dealing !== false)
      handleDealRequest(com.state.order_dealing);
    //order dealing entra al update
  }, [com.state.order_dealing]);
  const [role, setRole] = useState<string>(
    localStorage.getItem("rfq_user_permissions") || "USER"
  );
  const [warningDialog, setWarningDialog] = useState<boolean>(false);

  const onWidgetStepChange = (status: string, data: any) => {
    switch (status) {
      case "REQUEST":
        handleInitialRequest(data);
        break; // TO NEW
      case "CANCEL":
        handleCancelRequest(data);
        break; // USER CANCEL
      case "REJECT":
        handleRejectRequest(data);
        break; // USER REJECT
      case "BOTH":
        handleBothActionRequest(data);
        break; // USER REJECT
      case "ACCEPT":
        handleConfirmRequest(data);
        break; // USER ACCEPT
      case "BACK":
        handleGoBackInitial(data);
        break; // NO STATUS
      case "EXPIRED":
        handleExpiredRequest(data);
        break;
      default:
        break;
    }
  };

  const validateInitialRequest = (data: any) => {
    let isValid = true;

    if (data.RFQType === "OUTRIGHT" || data.RFQType === "SPOT") {
      const amount =
        data.amount !== "" && data.amount !== 0 && !isNaN(data.amount);
      const valueDate =
        !!data.valueDate && data.valueDate !== "" && data.valueDate !== 0;
      const currency = data.currencyFrom !== null && data.currencyTo !== null;
      isValid = amount && valueDate && currency;
    }

    if (data.RFQType === "SWAP") {
    }

    return isValid;
  };

  const handleInitialRequest = async (data: any): Promise<void> => {
    console.log("initial request", data);
    data.status = "WAIT";

    // const valid:boolean = validateInitialRequest(data);

    // if (!valid) {
    //   setWarningDialog(true);
    //   return;
    // }
    setRequest(data);

    await new Promise((resolve) => {
      setRequestTimeout(setTimeout(resolve, 2000));
    });
    const clone = JSON.parse(JSON.stringify(data));
    // console.log(clone);
    // debugger
    SocketMethods.rfqSubscribe(clone); // creacion de rfq <----
    // await handleDealRequest(clone);// call from deal socket
  };

  const handleDealRequest = async (data: any): Promise<void> => {
    // const expires = 30;
    console.log(data);

    if (com.state.order_dealing.status === "UPDATING") data.status = "WAIT";
    else data.status = "ANSWER"; // service status are DEALING
    if (data.RFQType === "OUTRIGHT" || data.RFQType === "SPOT") {
      // if (data.requestMode === "BOTH" && role === "ADMIN") {
      //   data.spotMarketBuy = "18.7830"; //1
      //   data.spotMarketSell = "18.7840";
      //   data.frwdPointsBuy = "-0.0020"; //
      //   data.frwdPointsSell = "-0.0020"; //
      //   data.allInBuy = "18.7810";
      //   data.allInSell = "18.7820";
      // }
      //  else {
      // data.spotRate = "18.7830";
      // data.frwdPoints = "-0.0020"; //
      // data.allIn = "18.7810";
      // }
    }

    if (data.RFQType === "SWAP") {
      // if (data.requestMode === "BOTH" && role === "ADMIN") {
      //   data.swapPointsBuy = "-0.0020";
      //   data.swapPointsSell = "-0.0030";
      // } else {
      //   data.swapPoints = "-0.0020";
      // }
    }
    await setRequest(data);
  };

  const handleExpiredRequest = async (data: any) => {
    if (requesTimeout) {
      clearTimeout(requesTimeout);
    }

    data.status = "EXPIRED";
    console.log("expirado");
    SocketMethods.rfqClientOrderCancel(com.state.order);
    await setRequest(data);
  };

  const handleCancelRequest = async (data: any) => {
    if (requesTimeout) {
      clearTimeout(requesTimeout);
    }

    data.requestMode = undefined;
    // get request order from redux for cancel
    // debugger
    if (data.status !== "CANCELED" && data.status !== "CANCEL") {
      SocketMethods.rfqClientOrderCancel(com.state.order);
    }
    data.status = "CANCEL"; // cstatus o requeststatus?
    await setRequest(data);
  };

  const handleRejectRequest = async (data: any) => {
    if (data.status !== "CANCELED" && data.status !== "CANCEL") {
      SocketMethods.rfqClientOrderCancel(com.state.order_dealing);
    }
    data.status = "REJECT";
    data.requestMode = undefined;

    await setRequest(data);
  };

  const handleGoBackInitial = async (data: any) => {
    await setRequest(InitialRequest);
  };

  const handleConfirmRequest = async (data: any) => {
    SocketMethods.rfqClientOrderConfirm({
      // ...data,
      // client_order_id: data.tradeId,
      ...com.state.order_dealing,
      side:
        com.state.order_dealing.type === "BOTH"
          ? data.side
          : com.state.order_dealing.side,
      accept: true,
    });
    console.log(data);
    if (com.state.order_dealing.type === "BOTH") {
      let tempd = com.state.order_dealing;
      if (data.side === "BUY") tempd.buy.status = "FILLED";
      else tempd.sell.status = "FILLED";
      data = tempd;
    }
    if (data.tradeId === undefined) {
      data.tradeId = com.state.order_dealing.client_order_id;
    }
    data.status = "CONFIRM";
    // data.tradeId = "1147:5588462";

    // if (data.RFQType === "SWAP") {
    //   // data.swapPoints = "-0.0020";
    // }

    // if (request.requestMode === "BOTH" && role === "ADMIN") {
    //   if (data.RFQType === "OUTRIGHT") {
    //     const mode = data.requestMode === "SELL" ? "Sell" : "Buy";
    //     data.spotRate = data[`spotMarket${mode}`];
    //     data.frwdPoints = data[`frwdPoints${mode}`];
    //     data.allIn = data[`allIn${mode}`];
    //   }
    //   if (data.RFQType === "SWAP") {
    //     const mode = data.requestMode === "SELL" ? "Sell" : "Buy";
    //     data.swapPoints = data[`swapPoints${mode}`];
    //   }
    // }
    await setRequest(data);
  };

  const handleBothActionRequest = (data: any) => {};

  const onDialogClose = (dialog: string) => (event: any) => {
    setWarningDialog(false);
  };

  return (
    <ContentClientView theme={com.state.theme}>
      <Grid container xs={12} style={{ height: "100%", overflowX: "auto" }}>
        <Components.CardClient
          clientView
          currencies={currencies}
          role={role}
          request={request}
          onWidgetStepChange={onWidgetStepChange}
        />
        <Atoms.Dialog
          overlay={"top"}
          open={warningDialog}
          onClose={onDialogClose("warning")}
        >
          <Atoms.Text size="22" textLeft labelCard>
            AMOUNT
          </Atoms.Text>
        </Atoms.Dialog>
      </Grid>
    </ContentClientView>
  );
};

export default ClientView;
