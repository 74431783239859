interface RequestWidget {
  step: string;
  status: string;
  view: "CLIENT" | "MARKET";
  mode: "OUTRIGHT" | "SWAP";
}

interface user {
  userId: number;
  role: "admin" | "user";
}

interface InitialState {
  theme: "black" | "lightBlue" | "blue" | "green";
  requestWidget?: RequestWidget;
  user?: user;
  instruments?: any;
  subscription?: any;
  RFQ_UPDATE?: any;
  RFQ_ORDER_UPDATE?: any,
  BOOK_UPDATE?: any,
  orders?: any;
  order?: any;
  ordersUpdate?: any;
  order_dealing?: any;
}

const initialState: InitialState = {
  theme: "black",
  requestWidget: {
    step: "0",
    view: "CLIENT",
    status: "REQUEST",
    mode: "OUTRIGHT",
  },
  user: {
    role: "user",
    userId: 1,
  },
  instruments: [],
  orders: [],
  order: {},
  ordersUpdate: false,
  order_dealing: false,
  subscription: {},
  RFQ_UPDATE: {},
  RFQ_ORDER_UPDATE: {},
  BOOK_UPDATE:{}
};

const reducer = (state: any, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case "instruments":
      console.log(payload);
      return { ...state, instruments: payload };
    case "subscription":
      return { ...state, subscription: payload };
    case "RFQ_UPDATE":
      return { ...state, RFQ_UPDATE: payload };
    case "RFQ_ORDER_UPDATE":
      return { ...state, RFQ_ORDER_UPDATE: payload };
    case "BOOK_UPDATE":
      return { ...state, BOOK_UPDATE: payload }
    case "FromApi":
      return { ...state, response: payload };
    case "CHANGE_THEME_MODE":
      localStorage.setItem("rfq_theme_default", payload.theme);
      return { ...state, theme: payload.theme };
    case "SET_CARD_VIEW":
      return {
        ...state,
        requestWidget: { ...state.requestWidget, view: payload.view },
      };
    case "CHANGE_MODE":
      return {
        ...state,
        requestWidget: { ...state.requestWidget, mode: payload.mode },
      };
    case "ADVANCE_STEP":
      return {
        ...state,
        requestWidget: { ...state.requestWidget, status: payload.status },
      };
    case "RFQ_TAKER":
      return { ...state, order: payload };
    case "RFQ_MAKER_DEALING":
      return { ...state, order_dealing: payload };
    case "RFQ_MAKER_EXPIRED":
    case "RFQ_MAKER_FILLED":
    case "RFQ_MAKER_REJECTED":
      return { ...state, order_dealing: payload, ordersUpdate: payload };
    case "RFQ_MAKER_NEW_ADMIN":
      const orderupdate = state.orders.findIndex(
        (item: any) => item.client_order_id === payload.client_order_id
      );
      if (orderupdate !== -1) return state;
      return { ...state, orders: [...state.orders, payload] };
    case "RFQ_MAKER_UPDATE_ORDER":
      return { ...state, orders: payload };

    case "RFQ_TAKER_DEALING":
      return { ...state, order_dealing: payload };
    case "RFQ_TAKER_CANCEL_ADMIN":
    case "RFQ_MAKER_CANCEL_ADMIN":
    case "RFQ_MAKER_ORDERS_UPDATE_RESET":
    case "RFQ_MAKER_ORDERS_UPDATE":
      // update order
      // return { ...state, orders: payload };
      // const orderupdate = state.orders.findIndex(
      //   (item: any) => item.client_order_id === payload.client_order_id
      // );
      // if (orderupdate === -1) return state;
      // state.orders[orderupdate] = payload;
      // return state;
      return { ...state, ordersUpdate: payload };

    default:
      return state;
  }
};

export { reducer, initialState };
