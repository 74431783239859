import { Colors } from "../../Constants";
import Styled from "styled-components";

const ContentClientView = Styled.div<any>`
    height: 100%;
    background-color:${props => Colors[props.theme].background.body};
    scrollbar-color: ${props => Colors[props.theme].background.scrollFront} ${props => Colors[props.theme].background.scroll};
    scrollbar-width: thin;

    ::-webkit-scrollbar, * ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    }

    ::-webkit-scrollbar-thumb, * ::-webkit-scrollbar-thumb {
    background:  ${props => Colors[props.theme].background.scrollFront};
    }

::-webkit-scrollbar-track, * ::-webkit-scrollbar-track {
  background: ${props => Colors[props.theme].background.scroll};
}
`;

export {ContentClientView};
