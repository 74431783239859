import React, {useContext, useState, useEffect} from "react";
import { ContextBase } from "../../Hooks";
import Atoms from "../../Atoms";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Text from "../../Atoms/Text"
import Components from "../../Components"
import Grid from '@material-ui/core/Grid';

const FooterCardClient = (props:any) => {
  let com: any = useContext(ContextBase);
  
  return   (
    <Atoms.Card fullHeight={props.fullHeight} FooterCardHeight CardClient={props.clientView} CardMarket={props.marketView}>
     {props.marketView && (
      <div className="marketViewer">
        { props.RFQType && props.RFQType === "OUTRIGHT" && (
          <>
            {props.request.status === "WAIT" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth  onClick={props.onFooterAction('CANCEL')}>CANCEL REQUEST</Atoms.Button>
              </Grid>
            )}
      
            {["REQUEST","REQUEST_UPDATE"].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
              <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('CANCEL')}>CANCEL</Atoms.Button>
              <Atoms.Button color="success" twiceWidth   onClick={props.onFooterAction('SEND')}>SEND</Atoms.Button>
              </Grid>
            )}

            {['CANCEL', 'REJECT', 'CONFIRM', 'EXPIRED'].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth   onClick={props.onFooterAction('BACK')} startIcon={<ArrowBackIosIcon />}> BACK </Atoms.Button>
              </Grid>
            )}
            
            {props.request.status === "ANSWER" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('CANCEL')}>CANCEL</Atoms.Button>
                <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('MODIFY_QUOTES')}>MODIFY <span>QUOTES</span></Atoms.Button>
              </Grid>
            )}

          </>
        )}

        { props.RFQType && props.RFQType === "SPOT" && (
          <>
            {props.request.status === "WAIT" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth  onClick={props.onFooterAction('CANCEL')}>CANCEL REQUEST</Atoms.Button>
              </Grid>
            )}
      
            {["REQUEST","REQUEST_UPDATE"].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
              <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('CANCEL')}>CANCEL</Atoms.Button>
              <Atoms.Button color="success" twiceWidth   onClick={props.onFooterAction('SEND')}>SEND</Atoms.Button>
              </Grid>
            )}

            {['CANCEL', 'REJECT', 'CONFIRM', 'EXPIRED'].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth   onClick={props.onFooterAction('BACK')} startIcon={<ArrowBackIosIcon />}> BACK </Atoms.Button>
              </Grid>
            )}
            
            {props.request.status === "ANSWER" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('CANCEL')}>CANCEL</Atoms.Button>
                <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('MODIFY_QUOTES')}>MODIFY <span>QUOTES</span></Atoms.Button>
              </Grid>
            )}

          </>
        )}

        {props.RFQType && props.RFQType === "SWAP" && (
          <>
            {props.request.status === "WAIT" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth  onClick={props.onFooterAction('CANCEL')}>CANCEL REQUEST</Atoms.Button>
              </Grid>
            )}
      
            {["REQUEST","REQUEST_UPDATE"].includes(props.request.status) && (
            <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
              <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('CANCEL')}>CANCEL</Atoms.Button>
              <Atoms.Button color="success" twiceWidth   onClick={props.onFooterAction('SEND')}>SEND</Atoms.Button>
            </Grid>
            )}

            {['CANCEL', 'REJECT', 'CONFIRM', 'EXPIRED'].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth   onClick={props.onFooterAction('BACK')} startIcon={<ArrowBackIosIcon />}> BACK </Atoms.Button>
              </Grid>
            )}
            
            {props.request.status === "ANSWER" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('CANCEL')}>CANCEL</Atoms.Button>
                <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('MODIFY_QUOTES')}>MODIFY <span>QUOTES</span></Atoms.Button>
              </Grid>
            )}

          </>
        )}
      </div>
    )}

    {props.clientView && (
      <div>
        {props.RFQType && props.RFQType ==="OUTRIGHT" && (
          <>
            {props.request.status === "WAIT" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth  onClick={props.onFooterAction('CANCEL')}>CANCEL REQUEST</Atoms.Button>
              </Grid>
            )}
      
            {props.request.status === "REQUEST" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                {props.role === "USER" && (
                  <>
                    <Atoms.Button twiceWidth  onClick={props.onFooterAction('REQUEST', 'SELL')}>REQUEST <span> SELL PRICE</span></Atoms.Button>
                    <Atoms.Button twiceWidth  onClick={props.onFooterAction('REQUEST', 'BUY')}>REQUEST <span> BUY PRICE</span></Atoms.Button>
                  </>
                )}
                {props.role === "ADMIN" && (
                  <>
                  <Atoms.Button thirdWidth  onClick={props.onFooterAction('REQUEST', 'SELL')}>REQUEST <span> SELL PRICE</span></Atoms.Button>
                  <Atoms.Button thirdWidth active   onClick={props.onFooterAction('REQUEST', 'BOTH')}>BOTH</Atoms.Button>
                  <Atoms.Button thirdWidth  onClick={props.onFooterAction('REQUEST', 'BUY')}>REQUEST <span> BUY PRICE</span></Atoms.Button>
                  </>
                )}
              </Grid>
            )}

            {['CANCEL', 'REJECT', 'CONFIRM', 'EXPIRED'].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth   onClick={props.onFooterAction('BACK')} startIcon={<ArrowBackIosIcon />}> BACK </Atoms.Button>
              </Grid>
            )}
            
            {props.request.status === "ANSWER" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                {props.requestMode !== "BOTH" && (
                <>
                  <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                      <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('REJECT')}>REJECT</Atoms.Button>
                      <Atoms.Button color="success" twiceWidth   onClick={props.onFooterAction('ACCEPT')}>ACCEPT</Atoms.Button>
                  </Grid>
                </>
                )}
                {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                  <>
                  <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                      <Atoms.Button color="error" thirdWidth   onClick={props.onFooterAction('REJECT')}>REJECT</Atoms.Button>
                      <Atoms.Button color="success" thirdWidth   onClick={props.onFooterAction('ACCEPT', 'SELL_BOTH')}>ACCEPT <span>SELL</span></Atoms.Button>
                      <Atoms.Button color="success" thirdWidth   onClick={props.onFooterAction('ACCEPT', 'BUY_BOTH')}>ACCEPT <span>BUY</span></Atoms.Button>
                  </Grid>
                  </>
                )}
              </Grid>
            )}
          </>
        )}

        {props.RFQType && props.RFQType ==="SPOT" && (
          <>
            {props.request.status === "WAIT" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth  onClick={props.onFooterAction('CANCEL')}>CANCEL REQUEST</Atoms.Button>
              </Grid>
            )}
      
            {props.request.status === "REQUEST" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                {props.role === "USER" && (
                  <>
                    <Atoms.Button twiceWidth  onClick={props.onFooterAction('REQUEST', 'SELL')}>REQUEST <span> SELL PRICE</span></Atoms.Button>
                    <Atoms.Button twiceWidth  onClick={props.onFooterAction('REQUEST', 'BUY')}>REQUEST <span> BUY PRICE</span></Atoms.Button>
                  </>
                )}
                {props.role === "ADMIN" && (
                  <>
                  <Atoms.Button thirdWidth  onClick={props.onFooterAction('REQUEST', 'SELL')}>REQUEST <span> SELL PRICE</span></Atoms.Button>
                  <Atoms.Button thirdWidth active   onClick={props.onFooterAction('REQUEST', 'BOTH')}>BOTH</Atoms.Button>
                  <Atoms.Button thirdWidth  onClick={props.onFooterAction('REQUEST', 'BUY')}>REQUEST <span> BUY PRICE</span></Atoms.Button>
                  </>
                )}
              </Grid>
            )}

            {['CANCEL', 'REJECT', 'CONFIRM', 'EXPIRED'].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth   onClick={props.onFooterAction('BACK')} startIcon={<ArrowBackIosIcon />}> BACK </Atoms.Button>
              </Grid>
            )}
            
            {props.request.status === "ANSWER" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                {props.requestMode !== "BOTH" && (
                <>
                  <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                      <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('REJECT')}>REJECT</Atoms.Button>
                      <Atoms.Button color="success" twiceWidth   onClick={props.onFooterAction('ACCEPT')}>ACCEPT</Atoms.Button>
                  </Grid>
                </>
                )}
                {props.requestMode === "BOTH" && props.role === "ADMIN" && (
                  <>
                  <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                      <Atoms.Button color="error" thirdWidth   onClick={props.onFooterAction('REJECT')}>REJECT</Atoms.Button>
                      <Atoms.Button color="success" thirdWidth   onClick={props.onFooterAction('ACCEPT', 'SELL')}>ACCEPT <span>SELL</span></Atoms.Button>
                      <Atoms.Button color="success" thirdWidth   onClick={props.onFooterAction('ACCEPT', 'BUY')}>ACCEPT <span>BUY</span></Atoms.Button>
                  </Grid>
                  </>
                )}
              </Grid>
            )}
          </>
        )}

        { props.RFQType && props.RFQType === "SWAP" && (
          <>
            {props.request.status === "WAIT" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth  onClick={props.onFooterAction('CANCEL')}>CANCEL REQUEST</Atoms.Button>
              </Grid>
            )}
      
            {props.request.status === "REQUEST" && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
              {props.role === "USER" && (
                <>
                  <Atoms.Button twiceWidth  onClick={props.onFooterAction('REQUEST', 'SELL')}>REQUEST <span> SELL PRICE</span></Atoms.Button>
                  <Atoms.Button twiceWidth  onClick={props.onFooterAction('REQUEST', 'BUY')}>REQUEST <span> BUY PRICE</span></Atoms.Button>
                </>
              )}
              {props.role === "ADMIN" && (
                <>
                <Atoms.Button thirdWidth  onClick={props.onFooterAction('REQUEST', 'SELL')}>REQUEST <span> SELL PRICE</span></Atoms.Button>
                <Atoms.Button thirdWidth active   onClick={props.onFooterAction('REQUEST', 'BOTH')}>BOTH</Atoms.Button>
                <Atoms.Button thirdWidth  onClick={props.onFooterAction('REQUEST', 'BUY')}>REQUEST <span> BUY PRICE</span></Atoms.Button>
                </>
              )}
            </Grid>
            )}

            {['CANCEL', 'REJECT', 'CONFIRM', 'EXPIRED'].includes(props.request.status) && (
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button fullWidth   onClick={props.onFooterAction('BACK')} startIcon={<ArrowBackIosIcon />}> BACK </Atoms.Button>
              </Grid>
            )}
            
            {props.requestStatus === "ANSWER" && (
            <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
            {props.requestMode !== "BOTH"  && (
              <>
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                <Atoms.Button color="error" twiceWidth   onClick={props.onFooterAction('REJECT')}>REJECT</Atoms.Button>
                <Atoms.Button color="success" twiceWidth   onClick={props.onFooterAction('ACCEPT')}>ACCEPT</Atoms.Button>
              </Grid>
              </>
            )}
            {props.requestMode === "BOTH" && props.role === "ADMIN" && (
              <>
              <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
              <Atoms.Button color="error" thirdWidth   onClick={props.onFooterAction('REJECT')}>REJECT</Atoms.Button>
              <Atoms.Button color="success" thirdWidth   onClick={props.onFooterAction('ACCEPT', 'SELL')}>ACCEPT <span>SELL</span></Atoms.Button>
              <Atoms.Button color="success" thirdWidth   onClick={props.onFooterAction('ACCEPT', 'BUY')}>ACCEPT <span>BUY</span></Atoms.Button>
              </Grid>
              </>
            )}
          </Grid>
            )}

          </>
        )}
      </div>
    )}
    </Atoms.Card>
  );
};
   
export default FooterCardClient;