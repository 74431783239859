import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {IconCard} from "../../Styles/Atoms/IconCard";
import Atoms from "../../Atoms"
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return ( 
    <IconCard src={props.children}  alt="img" {...props}
    theme={com.state.theme}  props={props.children}/>
  );
};