import { Colors, Fonts } from "../../Constants";
import styled, { css } from "styled-components";

const ContentInner = styled.div<any>`

    @media (min-width: 992px) {
        margin: auto;
        width: 80%;
    }

    .content-text {
        padding-top: 0.5em;

        p {
            &:first-child {
                margin-bottom: 1em !important;
            }
        }
    }
`;

const BlockInner = styled.div<any>`

    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    justify-content: ${props => props.justifyContent ? props.justifyContent : ""};
    margin: 1em auto;
    max-width: 320px;

    @media (min-width: 668px){
        flex-direction: ${props => props.direction ? props.direction : "row"};
        max-width: initial;
    }

    ${props => props.AlignCenter && css`
        align-items: center;
    `}
`;

const Box = styled.div<any>`
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    justify-content: center;
    padding: 0.5em;

    @media (min-width: 992px){
        flex-direction: ${props => props.direction ? props.direction : "row"} ;
    }

    &.bid {
        flex: 1;
    }

    &.date {
        align-self: center;
        flex-direction: column;
    }

    &.offer {
        flex: 1
    }

    .content-button {
        display: flex;
        width: 100%;
        order: 1;

        @media (min-width: 992px){
            flex-direction:column; 
            order: 0;
            width: auto;
        }
            
        >div {
            flex: 1 1 auto;

            &:nth-child(1) {
                @media (min-width: 992px){
                    margin-top: 24px;
                }
            }
            
            &:nth-child(2) {
                align-items: flex-end;
                display: flex;
                justify-content: flex-end;
            }
        }

        button {
            font-size: 0.8em !important;
            text-transform: capitalize;
        }
    }

    .label {
        display: block;
        font-size: 48px;
        font-family:${Fonts.secondary};
        padding: 40px;
        text-align: center;
        width: 100%;
    }

    &.data {
        flex-direction: column;
        grid-gap: 0;
        padding: 0;

        p {
            line-height: 1.2;
        }
    }
`;

const Line = styled.div<any>`
    background: #E96815;
    height: auto;
    margin: 0 0.75em;
    width: 1px;
`;

const Label = styled.button<any>` 
    border: ${props => props.noBorder ? 'none' : '1px solid #E96815'};
    border-radius: 4px;
    background-color: transparent;
    color: #E96815;
    font-weight: bold;
    padding: 7px;
    cursor: ${props => props.cursor ? props.cursor : " "};
    &.hover:hover{
        background-color: #E96815;
        color: #fff;
        .hover{
            color: #fff !important;
        }
    }

    span {
        display: block;
        color: #fff;
        font-size: 12px;
    };

    ${props => props.success && css`
        border-color: #24B227;
        color: #24B227;
    `}
    
    ${props => props.danger && css`
        border-color: #d4372e;
        color: #d4372e;
    `}
    
    ${props => props.disabled && css`
        cursor: default !important;
        pointer-events: none !important;
        opacity: 0.5;
    `}
`;

export {ContentInner, BlockInner, Box, Label, Line}