
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import { DateTimePickerInput } from "../../Styles/Atoms/DateTimePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider } from "@material-ui/core";
import MaterialTheme from "../../Styles/Constants/Materialtheme";

const DateTimePickerComponent = (props: any) => {
  let com: any = useContext(ContextBase);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={createTheme(MaterialTheme[com.state.theme])}>
        <DateTimePickerInput {...props} theme={com.state.theme}
          // customInput={<CustomInput />}
          // TextFieldComponent={CustomInput}
          // disableToolbar
          hideTabs
          format="dd/MM/yyyy hh:mm a"
          id="date-picker-inline"
          InputProps={{
            readOnly: true,
            disableUnderline: true
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};
export default DateTimePickerComponent;