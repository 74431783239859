import React, {useRef, useState, useEffect} from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import Atoms from "../../Atoms"
import {ContentProgress, Progress} from "../../Styles/Atoms/ProgressCard";
import LinearProgress from '@material-ui/core/LinearProgress';


export default function LinearBuffer (props:any) {
  const com: any = useContext(ContextBase);
  const [progress, setProgress] = useState(100);
  const [buffer, setBuffer] = useState( Math.random() * 3);
  const [timer, setTimer] = useState<any>();

  const { expires } = props;
  // const progressRef = useRef(() => {});
  
  useEffect(() => {
    const expireDate = Date.now() + (expires * 1000);
    const interval = setInterval(() => {
      const diff: number = expireDate - Date.now();
      if (diff > 0) {
        const percentage = (diff / (expires * 1000)) * 100;
        const invertedPer = percentage;
      
        const diff2 = Math.random() * 3;
        setProgress(invertedPer);
        setBuffer(invertedPer + diff2);
      } else {
        setProgress(100);
        setBuffer(100);
  
        clearInterval(interval);
        setTimer(null);
      }
    }, 500);

    setTimer(interval);

    return () => {
      setProgress(0);
      setBuffer(0);

      clearInterval(interval);
      setTimer(null);
    };
  }, []);

  const resetInterval = () => {
    
  }

  return ( 
    <ContentProgress theme={com.state.theme} >
      <Progress 
        {...props} 
        // color="primary" 
        theme={com.state.theme} 
        ProgressCard={props.ProgressCard}  
        variant="buffer" 
        value={progress} 
        valueBuffer={buffer} 
      />
    </ContentProgress>

  );
};