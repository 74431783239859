import React, { useEffect, useContext, useState } from "react";
import Components from "../../Components";
import Atoms from "../../Atoms";
import Text from "../../Atoms/Text";
import { Card } from "../../Styles/Atoms/Card";
import Grid from "@material-ui/core/Grid";
import Provider, { ContextBase, SocketMethods } from "../../Hooks";
import {
  CardClientProps,
  Currency,
  Request,
  InputErrors,
} from "../../Models/Components/CardClient";
import { FormatThousands } from "../../Utils/Format";
import { Inner } from "../../Styles/Pages/Content";

const CardClient = (props: CardClientProps) => {
  const com: any = useContext(ContextBase);

  const normalSpans = [
    "TD",
    "TOM",
    "SN",
    "1W",
    "2W",
    "3W",
    "1M",
    "3M",
    "9M",
    "BMF",
  ];
  const spotSpans = ["SPOT"];
  const [presetSpanList, setPresetSpanList] =
    useState<Array<string>>(normalSpans);

  const [requestStatus, setRequestStatus] = useState<string>("");
  const [requestMode, setRequestMode] = useState<string>();
  const [RFQType, setRFQType] = useState<string>();
  const [spotRate, setSpotRate] = useState<string | string>();
  const [frwdPoints, setFrwdPoints] = useState<number | string>();
  const [tradeId, setTradeId] = useState<string>();
  const [amount, setAmount] = useState<number | string>();
  const [allIn, setAllIn] = useState<string>();

  const [valueDate, setValueDate] = useState<number>();
  const [nearLeg, setNearLeg] = useState<number>();
  const [farLeg, setFarLeg] = useState<number>();

  const [valueDateSpan, setValueDateSpan] = useState<string>();
  const [nearLegSpan, setNearLegSpan] = useState<string>();
  const [farLegSpan, setFarLegSpan] = useState<string>();

  const [currencyExchange, setCurrencyExchange] = useState<any>(0);
  const [currenciesAvailable, setCurrenciesAvailable] = useState<Array<string>>(
    []
  );

  const [currencyLabel, setCurrencyLabel] = useState<string>();
  const [currencyFrom, setCurrencyFrom] = useState<any>(0);
  const [instrumentId, setInstrumentId] = useState<any>();
  const [currencyFromLabel, setCurrencyFromLabel] = useState<
    string | undefined
  >("");
  const [currencyTo, setCurrencyTo] = useState<any>(0);
  const [currencyToLabel, setCurrencyToLabel] = useState<string | undefined>(
    ""
  );

  const [client, setClient] = useState<string>();
  const [clientUser, setClientUser] = useState<string>();
  const [clientSpread, setClientSpread] = useState<string>();
  const [swapPoints, setSwapPoints] = useState<string>();
  const [expires, setExpires] = useState<string>();

  // ADMIN
  const [swapPointsBuy, setSwapPointsBuy] = useState<string>();
  const [swapPointsSell, setSwapPointsSell] = useState<string>();

  const [spotMarketBuy, setSpotMarketBuy] = useState<string>();
  const [spotMarketSell, setSpotMarketSell] = useState<string>();

  const [bothBuy, setBothBuy] = useState<any>();
  const [bothSell, setBothSell] = useState<any>();

  const [frwdPointsBuy, setFrwdPointsBuy] = useState<string>();
  const [frwdPointsSell, setFrwdPointsSell] = useState<string>();

  const [clientSpreadBuy, setClientSpreadBuy] = useState<string>();
  const [clientSpreadSell, setClientSpreadSell] = useState<string>();
  const [allInBuy, setAllInBuy] = useState<string>();
  const [allInSell, setAllInSell] = useState<string>();

  const [errors, setErrors] = useState<InputErrors>();
  const [warningDialog, setWarningDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  // state for data input
  const [manualAllin, setManualAllin] = useState<boolean>(false);
  // ONLY FOR TESTING
  const [fixedResponse, setFixedResponse] = useState<string>();

  const {
    currencies,
    marketView,
    clientView,
    role,

    request,
    onWidgetStepChange,
  } = props;
  useEffect(() => {
    if (clientView && request) {
      initClientView(request);
    }
  }, [clientView, request, request?.status, currencies]);

  useEffect(() => {
    if (marketView && request) {
      initMarketView(request);
    }
  }, [marketView, request, request?.status]);

  const initMarketView = (request: any): void => {
    //    setRequestStatus(request.status);
    if (request.requestStatus === undefined) setRequestStatus("REQUEST");
    else setRequestStatus(request.requestStatus);
    if (
      [
        "REQUEST",
        "REQUEST_UPDATE",
        "ANSWER",
        "NEW",
        "MODIFY_QUOTES",
        "CONFIRM",
        "EXPIRED",
      ].includes(request.status)
    ) {
      let requestType: string = "";

      if (!request.type || request.type == null) {
        return;
      }

      let both = request.type == "BOTH";
      switch (
        both ? request.buy.type.toLowerCase() : request.type.toLowerCase()
      ) {
        case "forward":
        case "outright":
          requestType = "OUTRIGHT";
          break;
        case "swap":
          requestType = "SWAP";
          break;

        case "spot":
          requestType = "SPOT";
          break;
      }
      setRFQType(both ? request.buy.type : request.type);

      setClient(
        request.user_id !== undefined ? request.user_id : request.client
      );
      setRequestMode(
        both
          ? request.type
          : request.side !== undefined
          ? request.side
          : request.requestMode
      );
      setClientUser(
        request.username !== undefined ? request.username : request.clientUser
      );
      setAmount(request.amount);

      setClientSpread(both ? request.buy.clientSpread : request.clientSpread);
      setExpires(request.expires ? request.expires : 60);
      // setTradeId(request.tradeId);//no exist
      setTradeId(request.client_order_id);

      // setSwapPoints(request.swapPoints);
      setSwapPoints(both ? request.buy.swapPoints : request.swapPoints);
      setFrwdPoints(both ? request.buy.frwdPoints : request.frwdPoints);

      setCurrencyLabel(request.currency);
      // setFixedResponse(request.fixedResponse);

      if (currencies && currencies.length > 0) {
        const ccy = request.ccyPair || request.currency;
        if (ccy && ccy.includes("/")) {
          const currIdx = currencies.findIndex(
            (item: any) => item.symbol == ccy
          );
          if (currIdx !== -1) {
            const currenciesAvailable = currencies[currIdx].symbol.split("/");
            setCurrencyExchange(currIdx);
            setCurrencyLabel(currencies[currIdx].symbol);

            setCurrenciesAvailable(currenciesAvailable);
            setCurrencyFrom(currenciesAvailable.length - 1);
            setCurrencyFromLabel(
              currenciesAvailable[currenciesAvailable.length - 1]
            );

            // if (request.currency) {
            //   setCurrencyFrom(request.currency);
            //   setCurrencyFromLabel(currenciesAvailable[parseInt(request.currency)]);
            // } else {
            //   setCurrencyFrom(currenciesAvailable.length -1);
            //   setCurrencyFromLabel(currenciesAvailable[currenciesAvailable.length -1 ]);
            // }
          }
        }
      }
      if (requestType === "OUTRIGHT") {
        setPresetSpanList(normalSpans);
      } else if (requestType === "SPOT") {
        setPresetSpanList(spotSpans);
      }

      if (["OUTRIGHT", "SPOT"].includes(requestType)) {
        setValueDate(request.valueDate);
        setSpotRate(
          both
            ? request.buy.price
            : request.price !== undefined
            ? request.price
            : request.spotRate
        );
        setFrwdPoints(
          both
            ? request.buy.frwPoints
            : request.frwPoints !== undefined
            ? request.frwPoints
            : request.frwdPoints
        );

        let allIn =
          Number(
            both
              ? request.buy.clientSpread
              : request.clientSpread.replace(/,/g, "")
          ) +
          Number(
            both
              ? request.buy.price.replace(/,/g, "")
              : request.price !== undefined
              ? request.price.replace(/,/g, "")
              : request.spotRate.replace(/,/g, "")
          ) +
          Number(
            both
              ? request.buy.frwPoints.replace(/,/g, "")
              : request.frwPoints !== undefined
              ? request.frwPoints.replace(/,/g, "")
              : request.frwdPoints.replace(/,/g, "")
          );
        setAllIn(
          FormatThousands(allIn.toString()) === "-"
            ? "0"
            : FormatThousands(allIn.toString())
        );
        setClientSpread(both ? request.buy.clientspread : request.clientSpread);
        setManualAllin(
          request.clientSpread === "0" ||
            request.price === "0" ||
            request.frwdPoints === "0"
        );
        if (request.valueDateSpan) {
          const spanIdx =
            requestType === "SPOT"
              ? spotSpans.indexOf(request.valueDateSpan)
              : presetSpanList.indexOf(request.valueDateSpan);

          setValueDateSpan(spanIdx.toString());
        }
        if (request.type === "BOTH" && role === "ADMIN") {
          setBothBuy(request.buy);
          setBothSell(request.sell);

          setSpotMarketBuy(request.buy.price);
          setSpotMarketSell(request.sell.price);
          setFrwdPointsBuy(request.buy.frwPoints);
          setFrwdPointsSell(request.sell.frwPoints);
          setClientSpreadBuy(request.buy.clientSpread);
          setClientSpreadSell(request.sell.clientSpread);
          let allInBuy =
            Number(request.buy.clientSpread.replace(/,/g, "")) +
            Number(request.buy.price.replace(/,/g, "")) +
            Number(request.buy.frwPoints.replace(/,/g, ""));
          let allInSell =
            Number(request.sell.clientSpread.replace(/,/g, "")) +
            Number(request.sell.price.replace(/,/g, "")) +
            Number(request.sell.frwPoints.replace(/,/g, ""));
          setAllInBuy(FormatThousands(allInBuy.toString()));
          setAllInSell(FormatThousands(allInSell.toString()));
          setManualAllin(
            request.buy.clientSpread === "0" ||
              request.buy.price === "0" ||
              request.buy.frwdPoints === "0" ||
              request.sell.clientSpread === "0" ||
              request.sell.price === "0" ||
              request.sell.frwdPoints === "0"
          );
          setRequestMode(request.type);
        }
      }

      if (requestType === "SWAP") {
        setNearLeg(request.nearLeg);
        setFarLeg(request.farLeg);
        if (request.nearLegSpan) {
          const spanIdx = presetSpanList.indexOf(request.nearLegSpan);
          setNearLegSpan(spanIdx.toString());
        }

        if (request.farLegSpan) {
          const spanIdx = presetSpanList.indexOf(request.farLegSpan);
          setFarLegSpan(spanIdx.toString());
        }

        if (request.type === "BOTH" && role === "ADMIN") {
          setBothBuy(request.buy);
          setBothSell(request.sell);
          setSwapPointsBuy(request.buy.swapPoints);
          setSwapPointsSell(request.sell.swapPoints);

          setClientSpreadBuy(request.buy.clientSpread);
          setClientSpreadSell(request.sell.clientSpread);
          setRequestMode(request.type);
        }
      }
    }
  };

  const initClientView = (request: Request): void => {
    setRequestStatus(request.status);
    if (request.status === "REQUEST") {
      setRFQType(request.RFQType);
      setPresetSpanList(request.RFQType === "SPOT" ? spotSpans : normalSpans);

      setValueDate(undefined);
      setValueDateSpan("");

      setNearLeg(undefined);
      setFarLeg(undefined);

      setNearLegSpan("");
      setFarLegSpan("");
      if (currencies && currencies.length > 0) {
        if (currencies[currencyExchange]) {
          setCurrencyExchange(currencyExchange);
          setCurrencyLabel(currencies[currencyExchange].symbol);
          const currenciesAvailable =
            currencies[currencyExchange].symbol.split("/");
          setInstrumentId(currencies[currencyExchange].instrument_id);
          setCurrenciesAvailable(currenciesAvailable);
          setCurrencyFrom(currenciesAvailable.length - 1);
          setCurrencyFromLabel(
            currenciesAvailable[currenciesAvailable.length - 1]
          );
        }
      }
    }

    if (request.status === "WAIT") {
      setRequestMode(request.requestMode);

      setCurrencyExchange(currencyExchange);
      setCurrencyLabel(request.currency);

      setCurrencyFrom(request.currencyFrom);
      setCurrencyFromLabel(request.currencyFromLabel);

      setAmount(request.amount);
    }
    if (request.status === "ANSWER") {
      setExpires(request.expires ? request.expires : 60);
      setSpotRate(request.spotRate);
      setFrwdPoints(request.frwdPoints);
      setAllIn(request.allIn);
      setSwapPoints(request.swapPoints);

      if (request.RFQType && ["OUTRIGHT", "SPOT"].includes(request.RFQType)) {
        setValueDate(request.valueDate);

        if (request.requestMode === "BOTH" && role === "ADMIN") {
          setSpotMarketBuy(request.spotMarketBuy);
          setSpotMarketSell(request.spotMarketSell);
          setFrwdPointsBuy(request.frwdPointsBuy);
          setFrwdPointsSell(request.frwdPointsSell);
          setClientSpreadBuy(request.clientSpreadBuy);
          setClientSpreadSell(request.clientSpreadSell);
          setAllInBuy(request.allInBuy);
          setAllInSell(request.allInSell);
        }
      }

      if (request.RFQType === "SWAP") {
        setFarLeg(request.farLeg);
        setNearLeg(request.nearLeg);

        if (request.requestMode === "BOTH" && role === "ADMIN") {
          setSwapPointsBuy(request.swapPointsBuy);
          setSwapPointsSell(request.swapPointsSell);
        }
      }
    }

    if (request.status === "CONFIRM") {
      setTradeId(request.tradeId);
      setSpotRate(request.spotRate);
      setFrwdPoints(request.frwdPoints);
      setAllIn(request.allIn);
      setSwapPoints(request.swapPoints);
    }
  };

  const onCurrencyExchangeChange = (currencyIdx: any) => {
    if (
      currencies &&
      currencies.findIndex((item: any) => item.instrument_id == currencyIdx) >
        -1
    ) {


      const indexfound = currencies.findIndex(
        (item: any) => item.instrument_id == currencyIdx
      );
      setCurrencyLabel(currencies[indexfound].symbol);
      setInstrumentId(currencyIdx);

      const currenciesAvailable = currencies[indexfound].symbol.split("/");
      setCurrenciesAvailable(currenciesAvailable);
      setCurrencyFrom(currenciesAvailable.length - 1);
      setCurrencyFromLabel(currenciesAvailable[currenciesAvailable.length - 1]);
    }
  };

  const onCurrencyChange = (currencyIdx: any) => {
    setCurrencyFrom(currencyIdx);
    setCurrencyFromLabel(currenciesAvailable[currencyIdx]);
  };

  const onRFQTypeChange = (rfqType: string) => (event: any) => {
    setRFQType(rfqType);

    if (rfqType === "SPOT") {
      setValueDate(new Date().getTime());

      setPresetSpanList(spotSpans);
      setValueDateSpan("0");
    } else {
      setPresetSpanList(normalSpans);
      setValueDate(undefined);
      setValueDateSpan(undefined);
    }

    setErrors({
      ...errors,
      amount: false,
      valueDate: false,
      nearLeg: false,
      farLeg: false,
    });
  };

  const onInputChange =
    (input: string) =>
    (event: any): void => {
      switch (input) {
        case "amount":
          setAmount(FormatThousands(event.target.value));
          break;
        case "spotRate":
          setSpotRate(
            event.target.value === "-"
              ? "0"
              : FormatThousands(event.target.value)
          );

          setAllIn(
            FormatThousands(
              `${
                Number(
                  event.target.value === "-"
                    ? 0
                    : event.target.value.replace(/,/g, "")
                ) +
                Number(clientSpread?.toString().replace(/,/g, "")) +
                Number(frwdPoints?.toString().replace(/,/g, ""))
              }`
            )
          );

          break;
        case "frwdPoints":
          setFrwdPoints(FormatThousands(event.target.value));
          setAllIn(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(spotRate?.toString().replace(/,/g, "")) +
                Number(clientSpread?.toString().replace(/,/g, ""))
              }`
            )
          );

          break;
        case "clientSpread":
          setClientSpread(FormatThousands(event.target.value));
          setAllIn(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(spotRate?.toString().replace(/,/g, "")) +
                Number(frwdPoints?.toString().replace(/,/g, ""))
              }`
            )
          );

          break;
        case "allIn":
          setClientSpread(
            event.target.value - (Number(frwdPoints) + Number(spotRate)) > 0
              ? FormatThousands(
                  `${
                    event.target.value - (Number(frwdPoints) + Number(spotRate))
                  }`
                )
              : FormatThousands("0")
          );
          setAllIn(event.target.value);
          break;
        case "expires":
          setExpires(event.target.value);
          break;
        case "swapPoints":
          setSwapPoints(FormatThousands(event.target.value));
          setAllIn(
            `${
              Number(event.target.value) + Number(spotRate) + Number(frwdPoints)
            }`
          );
          break;

        case "swapPointsBuy":
          setSwapPointsBuy(FormatThousands(event.target.value));
          break;
        case "swapPointsSell":
          setSwapPointsSell(FormatThousands(event.target.value));
          break;
        case "spotMarketBuy":
          setSpotMarketBuy(FormatThousands(event.target.value));
          setAllInBuy(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(clientSpreadBuy?.toString().replace(/,/g, "")) +
                Number(frwdPointsBuy?.toString().replace(/,/g, ""))
              }`
            )
          );

          break;
        case "spotMarketSell":
          setSpotMarketSell(FormatThousands(event.target.value));
          setAllInSell(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(clientSpreadSell?.toString().replace(/,/g, "")) +
                Number(frwdPointsSell?.toString().replace(/,/g, ""))
              }`
            )
          );

          break;
        case "frwdPointsBuy":
          setFrwdPointsBuy(FormatThousands(event.target.value));
          setAllInBuy(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(clientSpreadBuy?.toString().replace(/,/g, "")) +
                Number(spotMarketBuy?.toString().replace(/,/g, ""))
              }`
            )
          );

          break;
        case "frwdPointsSell":
          setFrwdPointsSell(FormatThousands(event.target.value));
          setAllInSell(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(clientSpreadSell?.toString().replace(/,/g, "")) +
                Number(spotMarketSell?.toString().replace(/,/g, ""))
              }`
            )
          );

          break;
        case "clientSpreadBuy":
          setClientSpreadBuy(FormatThousands(event.target.value));
          setAllInBuy(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(spotMarketBuy?.toString().replace(/,/g, "")) +
                Number(frwdPointsBuy?.toString().replace(/,/g, ""))
              }`
            )
          );
          setSwapPointsBuy(`${Number(event.target.value)}`);
          break;
        case "clientSpreadSell":
          setClientSpreadSell(FormatThousands(event.target.value));
          setAllInSell(
            FormatThousands(
              `${
                Number(event.target.value.replace(/,/g, "")) +
                Number(spotMarketSell?.toString().replace(/,/g, "")) +
                Number(frwdPointsSell?.toString().replace(/,/g, ""))
              }`
            )
          );
          setSwapPointsSell(`${Number(event.target.value)}`);
          break;
        case "allInBuy":
          setClientSpreadBuy(
            event.target.value -
              (Number(frwdPointsBuy) + Number(spotMarketBuy)) >
              0
              ? FormatThousands(
                  `${
                    event.target.value -
                    (Number(frwdPointsBuy) + Number(spotMarketBuy))
                  }`
                )
              : FormatThousands("0")
          );
          setAllInBuy(FormatThousands(event.target.value));
          break;
        case "allInSell":
          setClientSpreadSell(
            event.target.value -
              (Number(frwdPointsSell) + Number(spotMarketSell)) >
              0
              ? FormatThousands(
                  `${
                    event.target.value -
                    (Number(frwdPointsSell) + Number(spotMarketSell))
                  }`
                )
              : FormatThousands("0")
          );
          setAllInSell(FormatThousands(event.target.value));
          break;

        // Set Spans Off, if they are not between "TOM", "SN", "BMF";
        case "valueDate":
          setValueDate(event.getTime());
          break;
        case "nearLeg":
          setNearLeg(event.getTime());
          break;
        case "farLeg":
          setFarLeg(event.getTime());
          break;

        default:
          break;
      }
      setErrors({ ...errors, [input]: false });
    };

  const setDate = (input: string) => (event: any) => {
    if (!presetSpanList[parseInt(event.target.value)]) {
      return;
    }
    const selected: string = presetSpanList[parseInt(event.target.value)];

    const isSetteable: boolean = !["TOM", "SN", "BMF"].includes(selected);

    switch (input) {
      case "valueDate":
        // setValueDateSpan(event.target.value);
        setValueDateSpan(event.target.value);
        if (isSetteable) {
          setValueDate(getPeriodSpan(selected));
        }
        break;
      case "nearLeg":
        setNearLegSpan(event.target.value);
        if (isSetteable) {
          setNearLeg(getPeriodSpan(selected));
        }
        break;
      case "farLeg":
        setFarLegSpan(event.target.value);
        if (isSetteable) {
          setFarLeg(getPeriodSpan(selected));
        }
        break;
      default:
        break;
    }
    setErrors({ ...errors, [input]: false });
  };

  const getPeriodSpan = (spanString: string): number => {
    const now: Date = new Date();
    let span: number;

    switch (spanString) {
      case "TD":
        now.getTime();
        break;
      case "1W":
        now.setDate(now.getDate() - 7);
        break;
      case "2W":
        now.setDate(now.getDate() - 14);
        break;
      case "3W":
        now.setDate(now.getDate() - 21);
        break;
      case "1M":
        now.setMonth(now.getMonth() - 1);
        break;
      case "3M":
        now.setMonth(now.getMonth() - 3);
        break;
      case "9M":
        now.setMonth(now.getMonth() - 9);
        break;
      case "TOM":
        break; // ANY DATE | EDGEWATER'll IMPLEMENT
      case "SN":
        break; // ANY DATE | EDGEWATER'll IMPLEMENT
      case "BMF":
        break; // ANY DATE | EDGEWATER'll IMPLEMENT
      default:
        break;
    }

    span = now.getTime();
    return span;
  };

  const onFooterAction =
    (nextStatus: string, mode?: string) => (event: any) => {
      // actions for sale and buy
      if (clientView) {
        onClientViewAction(nextStatus, mode);
      }
      if (marketView) {
        onMarketViewAction(nextStatus, mode);
      }
    };

  const onClientViewAction = async (nextStatus: string, mode?: string) => {
    if (mode) {
      setRequestMode(mode);
    }
    let newMode =
      requestStatus !== "REQUEST" && requestMode ? requestMode : mode;

    if (role === "ADMIN") {
      newMode = mode ? mode : requestMode;
    }

    let request: any = {
      status: requestStatus,
      currency: currencyLabel,
      currencyFrom,
      currencyTo,
      currencyFromLabel,
      currencyToLabel,
      RFQType,
      instrument_id: Number(instrumentId),
      type: RFQType,
      requestMode: newMode,
      side: newMode,
      expires,

      amount,
      spotRate,
      frwdPoints,
      allIn,
      tradeId,
    };

    if (RFQType && ["OUTRIGHT", "SPOT"].includes(RFQType)) {
      request = {
        ...request,
        valueDate,
        tenor: RFQType === "SPOT" ? "SPOT" : valueDateSpan,
        // TODO
        // valueDateSpan,
      };
      if (mode === "BUY_BOTH") {
        request = {
          ...request,
          side: "BUY",
          nearLeg,
          farLeg,
          nearLegSpan,
          farLegSpan,
        };
      }
      if (mode === "SELL_BOTH") {
        request = {
          ...request,
          side: "SELL",
          nearLeg,
          farLeg,
          nearLegSpan,
          farLegSpan,
        };
      }
      if (mode === "BOTH" && role === "ADMIN") {
        request = {
          ...request,
          buy: { ...request, side: "BUY" },
          sell: { ...request, side: "SELL" },
          spotMarketBuy,
          spotMarketSell,
          frwdPointsBuy,
          frwdPointsSell,
          clientSpreadBuy,
          clientSpreadSell,
          allInBuy,
          allInSell,
        };
      }
    }

    if (RFQType === "SWAP") {
      request = {
        ...request,
        farLeg,
        nearLeg,
        // frwdPoints,//no indicados
        // swapPoints,//no indicados
        // TODO

        tenorNearLeg: nearLegSpan,
        tenorFarLeg: farLegSpan,
      };
      if (mode === "BUY_BOTH") {
        request = {
          ...request,
          side: "BUY",
          nearLeg,
          farLeg,
          nearLegSpan,
          farLegSpan,
        };
      }
      if (mode === "SELL_BOTH") {
        request = {
          ...request,
          side: "SELL",
          nearLeg,
          farLeg,
          nearLegSpan,
          farLegSpan,
        };
      }
      if (mode === "BOTH" && role === "ADMIN") {
        request = {
          ...request,
          buy: { ...request, side: "BUY" },
          sell: { ...request, side: "SELL" },
          swapPointsBuy,
          swapPointsSell,
          clientSpreadBuy,
          clientSpreadSell,
        };
      }
    }

    if (requestStatus === "REQUEST") {
      let validations: any = [];
      validations = onClientValidation();
      // VALIDATION TIME
      if (Object.keys(validations).length < 1) {
        onWidgetStepChange(nextStatus, request);
      } else {
        setErrors(validations);
        setWarningDialog(true);
      }
    } else {
      onWidgetStepChange(nextStatus, request);
    }
  };

  const onMarketViewAction = async (nextStatus: string, mode?: string) => {
    let request: any = {
      status: requestStatus,
      // ccyPair: currencyLabel,
      currency: currencyLabel,
      currencyFrom,
      currencyTo,
      currencyFromLabel,
      currencyToLabel,
      RFQType,
      type: RFQType,
      requestMode: requestMode,
      bankSide: requestMode,

      amount,
      spotRate,
      frwdPoints,
      swapPoints,
      allIn,
      tradeId,

      client,
      clientUser,
      clientSpread,
      expires,
      nearLeg,
      farLeg,

      nearLegSpan,
      farLegSpan,

      fixedResponse,
    };
    if (nextStatus === "CANCEL") {
      // if (requestStatus === "ANSWER") {
      if (com.state.order_dealing.status === "ANSWER") {
        // request.status = request.realstatus;
        SocketMethods.rfqMakerOrderCancel(
          request.clientId
            ? request
            : {
                ...com.state.order_dealing,
                client_order_id: com.state.order_dealing.tradeId,
              }
        );
      }

      props.onClose();
      // return;
    }
    if (nextStatus === "MODIFY_QUOTES" && request.tradeId === undefined) {
      request = {
        ...request,
        client_order_id: com.state.order_dealing.tradeId,
      };
    }
    if (nextStatus === "SEND") {
      if (requestMode === "BOTH") {
        // request={buy:com.state.}
        //added buy and sells request
      }

      // props.onClose();
      // return;
    }
    if (RFQType && ["OUTRIGHT", "SPOT"].includes(RFQType)) {
      request = {
        ...request,
        valueDate,
      };
      if (valueDateSpan) {
        request["valueDateSpan"] = presetSpanList[parseInt(valueDateSpan)];
      }
      if (requestMode === "BOTH" && role === "ADMIN") {
        request = {
          ...request,
          buy:
            nextStatus === "SEND"
              ? {
                  ...bothBuy,
                  clientSpread: clientSpreadBuy,
                  frwPoints: frwdPointsBuy,
                  price: spotMarketBuy,
                  status: "DEALING",
                }
              : bothBuy,
          sell:
            nextStatus === "SEND"
              ? {
                  ...bothSell,
                  clientSpread: clientSpreadSell,
                  frwPoints: frwdPointsSell,
                  status: "DEALING",
                  price: spotMarketSell,
                }
              : bothSell,
          type: "BOTH",
          spotMarketBuy,
          spotMarketSell,
          frwdPointsBuy,
          frwdPointsSell,
          clientSpreadBuy,
          clientSpreadSell,
          allInBuy,
          allInSell,
        };
      }
    }

    if (RFQType === "SWAP") {
      request = {
        ...request,
        farLeg,
        nearLeg,
        frwdPoints,
        swapPoints,
      };

      if (nearLegSpan) {
        request["nearLegSpan"] = presetSpanList[parseInt(nearLegSpan)];
      }

      if (farLegSpan) {
        request["farLegSpan"] = presetSpanList[parseInt(farLegSpan)];
      }
      if (requestMode === "BOTH" && role === "ADMIN") {
        request = {
          ...request,
          buy:
            nextStatus === "SEND"
              ? {
                  ...bothBuy,
                  swapPoints: swapPointsBuy,
                  status: "DEALING",
                  clientSpread: clientSpreadBuy,
                }
              : bothBuy,
          sell:
            nextStatus === "SEND"
              ? {
                  ...bothSell,
                  swapPoints: swapPointsSell,
                  status: "DEALING",
                  clientSpread: clientSpreadSell,
                }
              : bothSell,
          type: "BOTH",
          swapPointsBuy,
          swapPointsSell,
          clientSpreadBuy,
          clientSpreadSell,
        };
      }
    }
    if (nextStatus === "SEND") {
      let validations: any = [];
      validations = onMarketValidation();
      // VALIDATION TIME
      if (Object.keys(validations).length < 1) {
        onWidgetStepChange(nextStatus, request);
      } else {
        setErrors(validations);
        setWarningDialog(true);
      }
    } else {
      onWidgetStepChange(nextStatus, request);
    }
    // onWidgetStepChange(nextStatus, request);
  };

  const onClientValidation = (): any => {
    let validations: any = {};

    if (requestStatus === "REQUEST") {
      let repeatedChars = null;

      if (!!amount) {
        repeatedChars = amount.toString().match(/([.|,]{2})/g);
      }

      if (!amount || amount === "" || repeatedChars !== null) {
        validations["amount"] = true;
      }

      if (RFQType && ["OUTRIGHT", "SPOT"].includes(RFQType)) {
        if (currencyFrom === null || currencyTo === null) {
          validations["currencyFrom"] = true;
          validations["currencyTo"] = true;
        }

        if (!valueDate && valueDate == null) {
          validations["valueDate"] = true;
        }
      }

      if (RFQType === "SWAP") {
        if (!nearLeg && nearLeg == null) {
          validations["nearLeg"] = true;
        }
        if (!farLeg && farLeg == null) {
          validations["farLeg"] = true;
        }
      }
    }

    return validations;
  };

  const onMarketValidation = (): any => {
    let validations: any = {};

    if (requestStatus === "REQUEST") {
      if (!expires || expires === "" || expires === "0") {
        validations["expires"] = true;
      }

      if (RFQType === "OUTRIGHT" || RFQType === "SPOT") {
        if (requestMode !== "BOTH") {
          if (!spotRate || spotRate == null || Number(spotRate) <= 0) {
            validations["spotRate"] = true;
          }
          if (!frwdPoints || frwdPoints == null || Number(frwdPoints) <= 0) {
            validations["frwdPoints"] = true;
          }
          if (
            !clientSpread ||
            clientSpread == null ||
            Number(clientSpread) <= 0
          ) {

            if (Object.keys(validations).length === 0)
              setErrorMessage("Client Spread must be greater than 0.");
            validations["clientSpread"] = true;
          }
          if (!allIn || allIn == null || allIn === "") {
            validations["allIn"] = true;
          }
        } else {
          if (
            !spotMarketBuy ||
            spotMarketBuy == null ||
            Number(spotMarketBuy) <= 0
          ) {
            validations["spotMarketBuy"] = true;
          }
          if (
            !spotMarketSell ||
            spotMarketSell == null ||
            Number(spotMarketSell) <= 0
          ) {
            validations["spotMarketSell"] = true;
          }
          if (
            !frwdPointsBuy ||
            frwdPointsBuy == null ||
            Number(frwdPointsBuy) <= 0
          ) {
            validations["frwdPointsBuy"] = true;
          }
          if (
            !frwdPointsSell ||
            frwdPointsSell == null ||
            Number(frwdPointsSell) <= 0
          ) {
            validations["frwdPointsSell"] = true;
          }
          if (
            !clientSpreadBuy ||
            clientSpreadBuy == null ||
            Number(clientSpreadBuy) <= 0
          ) {
            if (Object.keys(validations).length === 0) {
              setErrorMessage("Client Spread must be greater than 0.");
            }
            validations["clientSpreadBuy"] = true;
          }
          if (
            !clientSpreadSell ||
            clientSpreadSell == null ||
            Number(clientSpreadSell) <= 0
          ) {
            if (
              Object.keys(validations).length === 0 ||
              validations["clientSpreadBuy"] === undefined
            ) {
              setErrorMessage("Client Spread must be greater than 0.");
            }
            validations["clientSpreadSell"] = true;
          }
          if (!allInBuy || allInBuy == null || allInBuy === "") {
            validations["allInBuy"] = true;
          }
          if (!allInSell || allInSell == null || allInSell === "") {
            validations["allInSell"] = true;
          }
        }
      }

      if (RFQType === "SWAP") {
        if (!clientSpread || clientSpread == null) {
          validations["clientSpread"] = true;
        }
        if (!swapPoints || swapPoints == null) {
          validations["swapPoints"] = true;
        }
      }
    }

    return validations;
  };

  const resetValues = (type: string) => {
    if (RFQType === "OUTRIGHT") {
    }
  };

  const onExpiration = (event: any) => {
    if (clientView) {
      onClientViewAction("EXPIRED");
    }
    if (marketView) {
      onMarketViewAction("EXPIRED");
    }
  };

  const requestAsProps = {
    request,
    requestStatus,
    currencyLabel,
    currencyFrom,
    currencyTo,
    currencyFromLabel,
    currencyToLabel,
    currencies,
    currenciesAvailable,
    RFQType,
    spotRate,
    frwdPoints,
    valueDate,
    amount,
    nearLeg,
    farLeg,
    tradeId,
    client,
    clientUser,
    requestMode,
    allIn,
    swapPoints,
    clientSpread,
    expires,

    presetSpanList,
    valueDateSpan,
    nearLegSpan,
    farLegSpan,

    swapPointsBuy,
    swapPointsSell,
    spotMarketBuy,
    spotMarketSell,
    frwdPointsBuy,
    frwdPointsSell,
    clientSpreadBuy,
    clientSpreadSell,
    allInBuy,
    allInSell,
    manualAllin,
  };

  const onDialogClose = (event: any) => {
    setWarningDialog(false);
  };

  return (
    <>
      <Grid container xs={12} style={{ height: "100%" }}>
        <Inner>
          <Components.TopCardClient
            TopCardHeight
            display
            bold
            marketView={marketView}
            clientView={clientView}
            role={role}
            {...requestAsProps}
            onRFQTypeChange={onRFQTypeChange}
            onCurrencyExchangeChange={onCurrencyExchangeChange}
            onCurrencyChange={onCurrencyChange}
          />

          <Components.HeaderCardClient
            alignItems
            display
            labelCard
            bold
            marketView={marketView}
            clientView={clientView}
            role={role}
            {...requestAsProps}
            errors={errors}
            onInputChange={onInputChange}
            onSetToday={setDate}
            onCurrencyChange={onCurrencyChange}
          />

          <Components.BodyCardClient
            fullHeight
            ProgressCard
            bodyCard
            marketView={marketView}
            clientView={clientView}
            role={role}
            {...requestAsProps}
            errors={errors}
            onInputChange={onInputChange}
            onExpiration={onExpiration}
          />

          <Components.FooterCardClient
            thirdWidth
            active
            movilFullWidth
            twiceWidth
            request={request}
            RFQType={RFQType}
            requestStatus={requestStatus}
            requestMode={requestMode}
            onFooterAction={onFooterAction}
            role={role}
            marketView={marketView}
            clientView={clientView}
          />
        </Inner>
      </Grid>
      <div>
        <Atoms.Alert
          open={warningDialog}
          severity={"error"}
          onClose={onDialogClose}
        >
          <Atoms.Text size="22" textLeft labelCard>
            {!errorMessage
              ? "Error: One or more fields is incorrect or invalid."
              : errorMessage}
          </Atoms.Text>
        </Atoms.Alert>
      </div>
    </>
  );
};

export default CardClient;
