import React from "react";
import { BrowserRouter, Route, Link, Switch } from "react-router-dom";
import Home from "../Pages/Home";
import MarketView from "../Pages/MarketView";
import ClientView from "../Pages/ClientView";
import UserView from "../Pages/UserView";
import EDF from "../Pages/EDF";
import CP from "../Pages/CP";
import BlockTrades from "../Pages/BlockTrades";
import IMM from "../Pages/IMM";
import Matrix from "../Pages/Matrix";
import Template from "../Pages/Template"

export default function Router(props: any) {

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/market-view" component={MarketView}/>
        <Route exact path="/client-view" component={ClientView}/>
        <Route exact path="/user-view" component={UserView}/>
        <Route exact path="/edf-view" component={EDF}/>
        <Route exact path="/cp-view" component={CP}/>
        <Route exact path="/blocktrades-view" component={BlockTrades}/>
        <Route exact path="/imm-view" component={IMM}/>
        <Route exact path="/matrix-view" component={Matrix}/>
        <Route exact path="/plantilla-view" component={Template}/>
      </Switch>
    </BrowserRouter>
  )
}

