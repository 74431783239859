import { Colors, FontSize, Fonts } from "../../Constants";

import Styled, {css} from "styled-components";

const ContentSelect = Styled.div<any>`
  align-items: ${props => props.alignItems ? "flex-start" : "flex-end"};
  display: ${props => props.display ? "inline-block" : "flex"};
  flex-direction: ${props => props.column ? "column" : "row"};
  color: #000000;
  height: 36px;
  min-width: ${props => props.minWidth ? props.minWidth : " "};
  margin-right:${props => props.headerTop ? "10px" : "0px"};
  margin-bottom: ${props => props.headerTop ? "0px" : "10px"};
  position: relative;
  vertical-align: top;
  
  &div:first-child{
    margin-bottom: 0px !important;
  }

  &:after {
    content: '';
    font-family: agGridAlpine;
    position: absolute;
    right: 3px;
    display: flex;
    vertical-align: middle;
    color: ${props => props.headerTop ? "black" : "white"};
    font-size: 19px;
    top: 8px;
    width: 20px;
  }
  ${props => props.noMargin && css`
    margin: 0 !important;
  `}
`;

const Select = Styled.select<any>`
  appearance: none;
  background-color: ${props => props.headerTop ? "white" : "transparent"};
  border-color: ${props => props.headerTop ? "transparent" : "white"};
  border-radius: ${props => props.headerTop ? "4px" : props.simple ? "4px" : "4px 0px 0px 4px"};
  border: 1px solid;
  box-sizing: border-box;
  color: ${props => props.headerTop ? "black" : "white"};
  cursor: pointer;
  font-family: ${Fonts.primaryBook};
  ${FontSize.sz18};
  font-weight: ${props => props.headerTop ? "normal" : "bold"};
  height: 36px;
  padding-left: 7px;
  padding-right: 20px;
  text-overflow: ellipsis;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  width: 110px;
  
  &:focus{
    outline: none !important;
  }

  ${props => props.lg && css`
    ${FontSize.sz20};
    text-align: left;
    
    @media (min-width : 768px) {
      width: 160px;
    }
  `}

  ${props => props.select && css`
    background: ${props => Colors[props.theme].calendar.body};
    border: 0;
    border-radius: 4px;
    text-align: left;
    width: 100%;
  `}

  ${props => props.input && css`
    text-align: left;
    border: 1px solid;
    cursor: pointer;
    border-color: ${Colors[props.theme].background.light};
    background-color:  ${Colors[props.theme].background.input};
    color: ${props => Colors[props.theme].primary.textInput };
    border-radius: 4px;
    text-align: left;
    width: 100%;
  `}
  
  ${props => props.fullWidth && css `
    width: 100%;
  `}

  
  ${props => props.borderer && css `
    background-color: transparent !important;
    border-color: #7a7a7a !important;
    color: ${props => Colors[props.theme].primary.invert};
  `}
  
`;

const Option = Styled.option<any>`
  background-color:${props => Colors[props.theme].gray.main};
  color: ${props => Colors[props.theme].primary.invert};
  text-transform: uppercase;
  &:hover{
    background-color:${props => Colors[props.theme].primary.default};
    color: #ffffff;
  }
`;

export {ContentSelect, Select, Option};