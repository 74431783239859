import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ContentInput, ContentInputSearch, InputSearch} from "../../Styles/Atoms/InputSearch";
import StarIcon from '@material-ui/icons/Search';
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return <ContentInput  theme={com.state.theme}>
          <ContentInputSearch  theme={com.state.theme}>
            <InputSearch {...props} theme={com.state.theme}/>
            <StarIcon/>
          </ContentInputSearch>
        </ContentInput>
      ;
};
