import React, { useContext, useEffect } from "react"
import Components from "../../Components";
import { ContextBase, SocketMethods } from "../../Hooks";
import { Content } from "../../Styles/Pages/Content"

const BlockTrades = () => {

    const ctx: any = useContext(ContextBase);

    useEffect(() => {
        SocketMethods.rfqUpdate(ctx.state, ctx.dispatch);
        if (localStorage.getItem("rfq_theme_default")) {
            ctx.dispatch({
                type: "CHANGE_THEME_MODE",
                payload: { theme: localStorage.getItem("rfq_theme_default") },
            });
        }
    }, []);

    return (
        <Content theme={ctx.state.theme}>
            <Components.CardBlockTrades />
        </Content>
    )
}

export default BlockTrades