import React from "react";
import { Colors } from "../../Constants";

import Styled, {css} from "styled-components";


const Card = Styled.div<any>`
  border-top: 1px solid;
  padding: 15px 15px;
  border-color: ${props => Colors[props.theme].border.card};
  box-sizing: border-box;
  margin:0 auto;
  width: 100%; 

  ${props => props.bodyCard && css`
    background-color:${props => Colors[props.theme].primary.main};
  `}

  ${props => props.TopCardHeight && css`
    min-height: 70px;
    background-color: ${props => Colors[props.theme].background.body } !important;
  `}

  ${props => props.HeaderCardHeight && css`
    z-index: 9;
    background-color: ${props => Colors[props.theme].background.body } !important;
  `}

  ${props => props.BodyCardHeight && css`
    display: grid;
    align-items: center;
    height: 100%;
    margin: auto;
    overflow-x: hidden;
    overflow-y: auto;
  `}

  ${props => props.BodyDialog && css`
    min-height: 200px;
  `}

  ${props => props.BodyCardConfirm && css`
    height: calc(100vh - (158px + 46px));
    bottom: 89px;
    margin: auto;
  `}

  ${props => props.BodyCardAnswer && css`
    height: calc(100vh - (158px + 94px));
    margin: auto;
  `}

  ${props => props.FooterCardHeight && css`
    height: 88px;
    background-color: ${props => Colors[props.theme].background.body } !important;
  `}

  ${props => props.FooterCardAuto && css`
    height: auto;
    background-color: ${props => Colors[props.theme].background.body } !important;
  `}



  ${props => props.CardClient && css`
  `}

  ${props => props.CardMarket && css`
  `}

  .alignRight{
    text-align: right;
  }
  
`;

const IconCard = Styled(Card)<any>`
  color: #ff0000 !important;
`;

const CardBody = Styled.div<any>`
  padding: 8px;
`;

const BodyCardClient = Styled.div<any>`
  padding: 15px !important;
`;


const HeaderCard = Styled.div<any>`
  border-bottom: 1px solid;
  border-color: ${props => Colors[props.theme].primary.light};
  background-color: ${props => Colors[props.theme].background.main};
  border-radius: 4px 4px 0 0;
  min-height: 41px;
  padding: 4px 8px;
  /* margin: -8px -8px 10px; */
  box-sizing: border-box;
  display: flex;
  align-items:  ${props => props.alignItems ? props.alignItems : "center"};
  justify-content: ${props => props.justify ? props.justify : "flex-start"};
  flex-wrap: wrap;
`;


export {Card, HeaderCard, CardBody, IconCard, BodyCardClient};
  

// const WrapperCard = (props:any) => {
//   const {children, ...rest} = props;
//   console.log('prop:', props)
//   return <Card {...rest} {...props} >{children}</Card>;
// };

