import React, { useState, useEffect, useContext, useCallback } from "react";

import SettingsIcon from "@material-ui/icons/Settings";
import StarIcon from "@material-ui/icons/Star";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Brightness1Icon from "@material-ui/icons/Brightness1";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

import { ComponetDummy } from "../Component1";
import { ContextBase } from "../../Hooks";
import {
  TableWrapper,
  CellIcon,
  AgGridReactStyle,
} from "../../Styles/Components/Table";
import { ToggleButtonGorupStyle } from '../../Styles/Atoms/Button';
import TableHeaderCell from "./TableHeaderCell";
import Atoms from "../../Atoms";
import DatePickerCell from "../DatePickerCellEditor";
import { DoneOutlined, EditOutlined } from "@material-ui/icons";

interface TableColumn {
  label: string;
  field: string;

  customCell?: any;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  sortable?: boolean;

  headerCheckboxSelection?: boolean;
  headerCheckboxSelectionFilteredOnly?: boolean;
  editable?: boolean,
  cellEditor?: any
}
interface TableProps {
  rows: Array<any>;
  columns: Array<any>;
  rowSelection?: boolean | string;
  rowClassRules?: any;
  pagination?: boolean | string;
  onRowSelection?: (event: Array<any>) => void;
  onRowClick?: (event: Array<any>) => void;
  reference?: any;
  headerHeight?: any,
  height?: any,
  editType?: any,
  paginationPageSize?: number,
  onCellEdited?: (event: Array<any>) => void;
  suppressClickEdit?: boolean | string;
  onGridLoaded?: () => void;
}

const TableCellIconRenderer = (props: any) => {
  const { data, value } = props;
  console.log(data, value);
  if (data.status === "REQUEST") {
    data.status = "NEW";
    data.requestStatus = "REQUEST";
  }
  let Icon = Brightness1Icon;

  switch (data.status) {
    case "Dealing":
    case "DEALING":
      Icon = SettingsIcon;
      break;
    case "New":
    case "NEW":
      Icon = StarIcon;
      break;
    case "Rejected":
    case "User Cancel":
    case "CANCELED":
    case "Trader Cancel":
      Icon = NotInterestedIcon;
      break;
    case "Done":
    case "FILLED":
      Icon = CheckCircleOutlineIcon;
      break;
  }

  return (
    <CellIcon>
      {Icon && (
        <div>
          <Icon className="cell-icon" />
        </div>
      )}
      <div>
        <span>{props.value === "REQUEST" ? "NEW" : props.value}</span>
      </div>
    </CellIcon>
  );
};

const TableCellButtonRender = (props: any) => {
  const [editing, setEditing] = useState(false);

  const edit = () => {
    setEditing(true)
    props.api.startEditingCell({
      rowIndex: props.rowIndex,
      colKey: 'value'
    });
  }
  const save = () => {
    setEditing(false)
    props.api.stopEditing();
    props.editClicked(props.rowIndex)
  }
  return (
 
    <>
      {(props.editButton && props.data.status !== "done")  &&
        <Atoms.Button mr Icon variant="outlined"
          btnTable
          color={editing ? "success" : ""}
          onClick={editing ? save : edit}
        >
          {editing ?
            <>Update</>
            :
            <EditOutlined style={{ fontSize: '1rem' }} />
          }
        </Atoms.Button>
      }

      {!editing &&
        <Atoms.Button
          color={props.data.status === "send" ? "success" : props.data.status === "done" ? "secondary" : "error"}
          btnTable
          onClick={() => props.clicked(props.data)}
        >
          {props.data.status || "Cancel"}
        </Atoms.Button>
      }

    </>
  )
}
const ToggleButton = (props: any) => {
  const [value, setValue] = React.useState('coc');

  const onToggle = (e: any, value: any) => {
    setValue(value);
    props.clicked(value)
  }
  return (
    <ToggleButtonGorupStyle
      size="small"
      height="100%"
      value={value}
      onChange={onToggle}
      exclusive
    >
      <Atoms.ToggleButton header value="coc">
        COC
      </Atoms.ToggleButton>
      <Atoms.ToggleButton header value="all">
        ALL
      </Atoms.ToggleButton>
    </ToggleButtonGorupStyle>
  )
}

const TableCellAddRender = (props: any) => {
  const click = () => {
    props.clicked(props.data)
  }
  return (
    <Atoms.Button color="secondary" btnTable onClick={click}>
      Add
    </Atoms.Button>
  )
}

const TableCellEditRender = (props: any) => {
  return (
    <Atoms.Button btnTable Icon variant="outlined"
      color={props.value ? "success" : ""}
    >
      {props.value ?
        <DoneOutlined style={{ fontSize: '1rem' }} />
        :
        <EditOutlined style={{ fontSize: '1rem' }} />
      }
    </Atoms.Button>
  )
}

const Table = (props: TableProps) => {
  let com: any = useContext(ContextBase);
  const context: any = useContext(ContextBase);

  const {
    rows,
    columns,
    rowClassRules,
    onRowSelection,
    onRowClick,
    pagination,
    reference,
    headerHeight,
    height,
    editType,
    paginationPageSize,
    onCellEdited,
    suppressClickEdit,
    onGridLoaded
  } = props;

  const [gridApi, setGridApi] = useState<any>(null);
  const [gridColumnApi, setGridColumnApi] = useState<any>(null);
  const [enableRangeSelection, setEnableRangeSelection] =
    useState<boolean>(false);

  const [frameworkComponents, setFrameworkComponents] = useState({
    btnCellRenderer: ComponetDummy,
    cellIconRenderer: TableCellIconRenderer,
    cellButtonRenderer: TableCellButtonRender,
    datePickerRenderer: DatePickerCell,
    cellButtonAddRender: TableCellAddRender,
    cellButtonEditRender: TableCellEditRender,
    innerToggleButton: ToggleButton,
  });

  const [columnDefs, setColumnDefs] = useState<Array<TableColumn>>([]);
  const [rowData, setRowData] = useState<Array<any>>([]);
  const [rowClassRulesDef, setRowClassRulesDef] = useState<any>({});

  useEffect(() => {
    if (columns.length > 0) {
      setColumnDefs(columns);
    }
    if (rows.length > 0) {
      setRowData(rows);
    }
    if (rowClassRules) {
      setRowClassRulesDef(rowClassRules);
    }
  }, ["columns", "rows", "rowClassRules"]);
  const getRowId = (data: any) => data.client_order_id;
  useEffect(() => {
    setRowData(rows);
  }, [rows]);

  const onGridReady = (params: any) => {
    if(onGridLoaded)
      onGridLoaded()
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = (event: any) => {
    if (gridApi !== null) {
      const selectedRows = gridApi.getSelectedRows();

      if (onRowSelection !== undefined) {
        onRowSelection(selectedRows);
      }
    }
  };

  const onCellClicked = (event: any) => {
    if (onRowClick !== undefined) {
      console.log(event);
      onRowClick(event.data);
    }
  };

  const onCellEditedd = useCallback(
    (event) => {
      const oldData = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const newData = { ...oldData };
      newData[field] = event.newValue;
      console.log('onCellEditRequest, updating ' + field + ' to ' + newValue);
      const tx = {
        update: [newData],
      };
      event.api.applyTransaction(tx);
    },
    []
  );

  const onThemeChange = (event: any): void => {
    context.dispatch({ type: "CHANGE_THEME_MODE" });
  };
  const defaultColDef = {

    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        // '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        "  </div>" +
        "</div>",
    },
  };

  // const gridRef = useRef();
  return (
    <TableWrapper
      theme={com.state.theme}
      className={"ag-theme-alpine-dark"}
      height={height}
    // className={`${ context.state.theme === 'light' ? "ag-theme-alpine" : 'ag-theme-alpine-dark'}`}
    // style={{
    //   backgroundColor: context.state.theme === 'light' ? '#f9f9f9' : '#0a080b',
    // }}
    >
      {/* <div>
        <button type="button" onClick={onThemeChange}>Change to {context.state.theme}</button>
      </div> */}

      {columnDefs && columnDefs.length < 1 && (
        <div className="noinfo">No Column Configuration to display</div>
      )}

      {columnDefs && columnDefs.length > 0 && (
        <AgGridReactStyle
          getRowId={getRowId}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowClassRules={rowClassRulesDef}
          frameworkComponents={frameworkComponents}
          enableRangeSelection={enableRangeSelection}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onCellClicked={onCellClicked}
          theme={com.state.theme}
          pagination={pagination}
          paginationPageSize={paginationPageSize != null ? paginationPageSize : 15}
          ref={reference}
          headerHeight={headerHeight}
          editType={editType}
          onCellEditRequest={onCellEdited}
          readOnlyEdit={true}
          suppressClickEdit={suppressClickEdit}
        >
          {/* {columnDefs.length > 0 && columnDefs.map((col, colIdx) => (
            <AgGridColumn key={colIdx} field={col.field}></AgGridColumn>
          ))} */}
          {/* 
          <AgGridColumn field="model"></AgGridColumn>
          <AgGridColumn field="price"></AgGridColumn> */}
        </AgGridReactStyle>
      )}
    </TableWrapper>
  );
};

export default Table;
