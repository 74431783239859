import { Grid } from "@material-ui/core"
import Atoms from "../../Atoms"

const FooterCardEDF = () => {
    return (
        <Atoms.Card
            FooterCardHeight
            fullHeight
        >
            <Grid container xs={12}  alignItems="center" justify="space-between" direction="row" >
                    <Atoms.Button color="error" twiceWidth disabled>
                        CANCEL
                    </Atoms.Button>
                    <Atoms.Button color="success" twiceWidth disabled>
                        SELL
                    </Atoms.Button>
            </Grid>
        </Atoms.Card>
    )
}

export default FooterCardEDF