import { Button, Grid } from "@material-ui/core"
import React, { useState } from "react"
import Atoms from "../../Atoms"
import { BlockInner, Box, ContentInner, Label } from "../../Styles/Pages/Content/BodyCard"
import ContentRequest from "./BodyContent/ContentRequest"

const BodyCardTrades = (props: any) => {

    return (
        <Atoms.Card
            bodyCard
            BodyCardHeight
        >
            {props.view && props.currency &&
                <ContentInner>
                    <BlockInner justifyContent="center">
                        <Box direction="column" className="">
                            <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white> Ref BID</Atoms.Text>
                            <Label success cursor="pointer" className="label" onClick={() => props.onStatusChange("bid")}>
                                <small>{props.bid}</small>
                                <span>Request Sell Price</span>
                            </Label>
                        </Box>
                        <Box direction="column" className="">
                            <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white>{props.timestamp}</Atoms.Text>
                            <Label className="label" cursor="pointer" onClick={() => props.onStatusChange("rfq")}>
                                <Atoms.Text size="24" textCenter>RFQ</Atoms.Text>
                                <span>&nbsp;</span>
                            </Label>
                        </Box>
                        <Box direction="column" className="">
                            <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white> Ref OFFER</Atoms.Text>
                            <Label danger className="label" cursor="pointer" onClick={() => props.onStatusChange("offer")}>
                                <small>{props.offer}</small>
                                <span>Request Buy Price</span>
                            </Label>
                        </Box>
                    </BlockInner>

                    <Grid container item justifyContent="center" spacing={2}>
                        <Grid container item xs sm={6} md={4} justifyContent="center" spacing={2}>
                            <Atoms.Text block textCenter size="26" uppercase fontFamily="bold" white>Notional:</Atoms.Text>
                            <Grid container item justifyContent="center">
                                <Atoms.Input
                                    headerTop
                                    textCenter
                                    type="text"
                                    onChange={props.onInputChange('notional')}
                                    value={props.notional}
                                />
                            </Grid>

                            <Grid container item justifyContent="space-around">
                                <Label onClick={props.onInputChange('20m')}>20 Mio</Label>
                                <Label onClick={props.onInputChange('50m')}>50 Mio</Label>
                                <Label onClick={props.onInputChange('100m')}>100 Mio</Label>
                            </Grid>

                            <Grid className="content-text">
                                <Atoms.Text block fontFamily="book" size="22" textCenter uppercase white> LP´s</Atoms.Text>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent="center">
                            <Atoms.Select name="select" alignItems display headerTop simple>
                                {props.lp_list && props.lp_list.map((item: any, key: number) => {
                                    return <Atoms.Option value={item.id} key={key}>{item.id}</Atoms.Option>
                                })}
                            </Atoms.Select>

                            <Atoms.Select name="select" alignItems display headerTop simple>
                                {props.lp_list && props.lp_list.map((item: any, key: number) => {
                                    return <Atoms.Option value={item.name} key={key}>{item.name}</Atoms.Option>
                                })}
                            </Atoms.Select>
                        </Grid>
                    </Grid>
                </ContentInner>
            }
            {props.requestStatus && <ContentRequest {...props} />}
        </Atoms.Card>
    )
}

export default (BodyCardTrades)