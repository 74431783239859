import React from 'react';
import Atoms from '../../../Atoms';
import { ToggleButtonGorupStyle } from '../../../Styles/Atoms/Button';

export default (props: any) => {

  const [value, setValue] = React.useState('oca');

  const onToggle = (e: any, value: any) => {
    setValue(value);
    props.clicked(value)
  }

  return (
    <ToggleButtonGorupStyle
      size="small"
      height="100%"
      value={value}
      onChange={onToggle}
      exclusive
    >
      <Atoms.ToggleButton header value="oca">
        OCA
      </Atoms.ToggleButton>
      <Atoms.ToggleButton header value="all">
        ALL
      </Atoms.ToggleButton>
    </ToggleButtonGorupStyle>
  );
};