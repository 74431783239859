import Styled, { css } from "styled-components";
import { Colors, FontSize, Fonts } from "../../Constants";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

const Btn = Styled(Button) <any>`
  /* padding: 10px !important; */
  border-radius: 4px !important;
  box-shadow: none !important;
  color: ${props => Colors[props.theme].primary.invert} !important;
  border: 1px solid !important;
  border-color: #7a7a7a !important;
  box-sizing: border-box;
  display:flex;
  align-content: space-between;
  min-height: ${props => props.simple ? "36px" : "55px"};
  ${(props) =>
    props.btnTable &&
    css`
      min-height: 30px !important;
      margin-bottom: 7px !important;
    `}
  ${(props) =>
    props.minheight &&
    css`
      min-height: ${props.minheight} !important;
    `}
  
  span{
    display: block;
  }

  span[class*="startIcon"]{
    display:inline-block;
    vertical-align: text-top;
  }

  ${FontSize.sz12};
  font-family:${Fonts.primaryMedium};
  line-height: 1.6 !important;

  ${(props) =>
    props.BtnSimple &&
    css`
      span {
        display: inline-flex;
        font-size: 0.825rem;
        line-height: 1;
        font-family: ${Fonts.primaryBook};
      }
  `}

  ${(props) =>
    props.reduceIcon &&
    css`
      padding: 0 !important;
      min-width: 0 !important;
      border: 0 !important;
    `}
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100% !important;
    `}

  ${(props) =>
    props.thirdWidth &&
    css`
      width: 32% !important;
    `}

  ${(props) =>
    props.twiceWidth &&
    css`
      width: 49% !important;
    `}

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].active.default} !important;
      border: 1px solid !important;
      border-color: ${(props) => Colors[props.theme].active.default} !important;
    `}

  ${(props) =>
    props.movilFullWidth &&
    css`
      @media screen and (max-width: 360px) {
        width: 100% !important;
        margin-bottom: 10px;
      }
    `}
  
  ${(props) =>
    props.noBorder &&
    css`
      border-color: transparent !important;
      color: ${(props) => Colors[props.theme].primary.main} !important;
    `}

  ${(props) =>
    props.color === "secondary" &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].secondary.light} !important;
      border-color: ${(props) =>
        Colors[props.theme].secondary.light} !important;
      color: #ffffff !important;
    `}

  ${(props) =>
    props.color === "success" &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].success.light} !important;
      border-color: ${(props) => Colors[props.theme].success.light} !important;
      color: #ffffff !important;
    `}

  ${(props) =>
    props.color === "error" &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].error.light} !important;
      border-color: ${(props) => Colors[props.theme].error.light} !important;
      color: #ffffff !important;
    `}

  ${(props) =>
    props.color === "warning" &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].warning.light} !important;
      border-color: ${(props) => Colors[props.theme].warning.light} !important;
      color: #ffffff !important;
    `}

  ${(props) =>
    props.color === "success" && props.variant === "outlined" &&
    css`
      background: transparent !important;
      color: ${(props) => Colors[props.theme].success.light} !important;
    `}
  
  ${(props) =>
    props.color === "error" && props.variant === "outlined" &&
    css`
      background: transparent !important;
      color: ${(props) => Colors[props.theme].error.light} !important;
    `}
  
  ${(props) =>
    props.color === "secondary" && props.variant === "outlined" &&
    css`
      background: transparent !important;
      color: ${(props) => Colors[props.theme].secondary.light} !important;
    `}
  
  ${(props) =>
    props.Icon &&
    css`
      line-height: 1 !important;
      min-width: auto !important;
      span{
        display: inherit;
      }
    `}

  
  ${(props) =>
    props.largeIcon &&
    css`
      line-height: 1 !important;
      min-width: 3.5rem !important;
      span{
        display: inherit;
        svg{
          font-size: 2.1875rem
        }
      }
  `}

  ${(props) =>
    props.mr &&
    css`
      margin-right: 10px !important;
    `}
  ${(props) =>
    props.ml &&
    css`
      margin-left: 10px !important;
    `}

    ${(props) => props.header && css`
      ${FontSize.header}
      min-height: 24px;
  `}
`;

const ToggleButtonGorupStyle = Styled(ToggleButtonGroup) <any>`
  align-items: center;
  display: flex !important; 
  height: ${props => props.height ? props.height : ""};

  & button {
    border-width: 1px 0px 1px 0px;
    border-radius: 0px;
  }

  & button:first-child{
    border-radius: 4px 0px 0px 4px;
     /* &.Mui-selected{
       background-color: #C85C13;
     } */
   }
   & button:nth-child(2) {
     border-radius: 0;
   }

  & button:last-child{
    border-radius: 0px 4px 4px 0px;
  }

`;

const ToggleButtonStyle = Styled(ToggleButton) <any>`
  box-shadow: none !important;
  color: white !important;
  border: 1px solid !important;
  border-color: #7a7a7a !important;
  font-family:${Fonts.primaryMedium};
  ${FontSize.header}
  ${FontSize.sz12};
  min-width: 85px;
  ${(props) =>
    props.light &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].warning.main} !important;
    `}
  &.MuiToggleButton-sizeSmall{
    padding: 4px 7px!important;
  }
  &.Mui-selected{
      background-color:${(props) =>
    props.light
      ? Colors[props.theme].warning.light
      : Colors[props.theme].warning.toogle} !important;
  }

  ${(props) => props.header && css`
      ${FontSize.header}
      height: 28px;
      min-width: inherit;
      width: auto;
  `}

`;
const ButtonIcon = Styled(IconButton) <any>`
  &.btnTable {
    height: 24px !important;
    width: 24px !important;
    color: ${(props) => Colors[props.theme].background.default} !important;
    padding: 0 !important;
  }
  ${(props) =>
    props.color === "error" &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].error.light} !important;
      border-color: ${(props) => Colors[props.theme].error.light} !important;
    `}
  ${(props) =>
    props.color === "success" &&
    css`
      background-color: ${(props) =>
        Colors[props.theme].success.light} !important;
      border-color: ${(props) => Colors[props.theme].success.light} !important;
    `}
`;

export { Btn, ToggleButtonGorupStyle, ToggleButtonStyle, ButtonIcon };
