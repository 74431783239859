import { Colors, FontSize, Fonts } from "../../Constants";

import Styled, {css} from "styled-components";

const ContentInput = Styled.div<any>`
  display: ${props => props.display ? "inline-block" : "contents"};
  flex-direction: ${props => props.column ? "column" : "row"};
  align-items: ${props => props.column ? "flex-start" : "flex-end"};
  color: ${props => Colors[props.theme].primary.light};
  ${FontSize.sz14};
  vertical-align: top;
  ${props => props.fullWidth && css `
    width: 100%;
  `}
`;
const Input = Styled.input<any>`
  border: 1px solid;
  cursor: pointer;
  border-color:  ${props => {
    if (props.error) {
      return "#ff695d";
    }
    return Colors[props.theme].background.light;
  }};
  border-radius: ${props => props.headerTop ? "4px" : "0px 4px 4px 0px"};
  background-color:  ${props => {

    if (props.error) {
      return "#ffd8d5";
    } 
    return Colors[props.theme].background.input;
  }};
  color: ${props => Colors[props.theme].primary.textInput };
  height: 36px;
  padding: 0 5px;
  vertical-align: middle;
  box-sizing: border-box;
  width: ${props => props.w ? props.w : "calc(100% - 110px)"} ;
  ${FontSize.sz20};
  text-transform: uppercase;
  font-family: ${Fonts.secondary};
  text-align: right;
  &:focus{
    outline: none !important;
  }
  
  ${props => props.headerCard && css`
    max-width: 140px !important;
    /* @media screen and (min-width:562px){
      max-width: 240px !important;
    } */
  `}
  ${props => props.textCenter && css`
    text-align: center !important;
  `}
  
  ${props => props.fullWidth && css `
    width: 100%;
  `}

  ${props => props.borderer && css `
    background-color: transparent !important;
    border-color: #7a7a7a !important;
    color: ${props => Colors[props.theme].primary.invert};
  `}
`;


export {ContentInput, Input};