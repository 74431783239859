import { normalize } from "./Config";
export default {
  header: {
    fontSize: `clamp(10px, ${normalize(2)}vw, 11px) !important`
  },
  sz9: {
    fontSize: `clamp(9px, ${normalize(2)}vw, 9px) !important`
  },
  sz10: {
    fontSize: `clamp(9px, ${normalize(2)}vw, 12px) !important`
  },
  sz11: {
    fontSize: `clamp(9px, ${normalize(2)}vw, 13px) !important`
  },
  sz12: {
    fontSize: `clamp(10px, ${normalize(2)}vw, 14px) !important`
  },
  sz14: {
    fontSize: `clamp(10px, ${normalize(2)}vw, 14px) !important`
  },
  sz16: {
    fontSize: `clamp(14px, ${normalize(2)}vw, 16px) !important`
  },
  sz18: {
    fontSize: `clamp(12px, ${normalize(2)}vw, 18px) !important`
  },
  sz20: {
    fontSize: `clamp(18px, ${normalize(2)}vw, 20px) !important`
  },
  sz22: {
    fontSize: `clamp(16px, ${normalize(2)}vw, 22px) !important`
  },
  sz24: {
    fontSize: `clamp(16px, ${normalize(2)}vw, 24px) !important`
  },
  sz26: {
    fontSize: `clamp(24px, ${normalize(2)}vw, 26px) !important`
  },
  sz28: {
    fontSize: `clamp(26px, ${normalize(2)}vw, 28px) !important`
  },
  sz60: {
    fontSize: `clamp(55px, ${normalize(2)}vw, 60px) !important`
  },
  sz75: {
    fontSize: `clamp(60px, ${normalize(2)}vw, 75px) !important`
  },
};