import React, {useContext} from "react";
import Atoms from "../../Atoms";
import Text from "../../Atoms/Text"
import Components from "../../Components"
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ToggleButtonGorupStyle } from '../../Styles/Atoms/Button'
import Grid from '@material-ui/core/Grid';
import { ContextBase } from "../../Hooks";
import { SortBy, SortByKey } from "../../Utils/Sort";
import { Currency } from "../../Models/Components/CardClient";


const TopCardClient = (props:any) => {
  
  const com: any = useContext(ContextBase);


  const onCurChange = (event: any) => {
    const inputValue = event.target.value;
    if (props.onCurrencyExchangeChange) {
      props.onCurrencyExchangeChange(inputValue)
    }
  }

  return  (
    <Atoms.Card TopCardHeight CardClient={props.clientView} CardMarket={props.marketView}>

      {props.marketView && (
        <>
          <Grid container xs={12}  alignItems="center" justifyContent="space-between" direction="row">
            <Grid item>
              <Text  size="20" fontFamily="bold" titleCard bold textLeft>RFQ</Text>
            </Grid>
            <Grid item>
            <ToggleButtonGorupStyle  size="small"  exclusive>
                <Atoms.ToggleButton  textColorHeader label="outright" value={props.RFQType} highlightValue={"OUTRIGHT"} disabled >
                  OUTRIGHT
                </Atoms.ToggleButton>
                <Atoms.ToggleButton  textColorHeader label="swap" value={props.RFQType} highlightValue={'SWAP'} disabled>
                  SWAP
                </Atoms.ToggleButton>
                <Atoms.ToggleButton  textColorHeader label="spot" value={props.RFQType} highlightValue={"SPOT"} disabled >
                SPOT
                </Atoms.ToggleButton>
              </ToggleButtonGorupStyle>
            </Grid>
          </Grid>
        </>
      )}

      {props.clientView && (
        <>
          {props.request && props.request.status === "REQUEST" && (
            <Grid container xs={12}  alignItems="center" justify="center" direction="row">
              <Grid container item xs={8} md={8} alignItems="center">
                <Grid>
                  <Atoms.Select name="select" alignItems display headerTop value={props.currencyExchange} onChange={onCurChange}>
                    {(props.currencies && props.currencies.length > 0) && 
                    props.currencies.sort(SortBy)
//  props.currencies//no sort for specific id
                    .map((currency: any, curIdx: any): any => (
                      <Atoms.Option value={currency.instrument_id}>{currency.symbol}</Atoms.Option >
                    ))}
                  </Atoms.Select>
                </Grid>
                <Grid>
                  <Text size="20" fontFamily="bold" bold hiddenMovile titleCard textLeft>RFQ</Text>
                </Grid>
              </Grid>
              <Grid container item xs={4} md={4} justifyContent="flex-end">
                <ToggleButtonGorupStyle  size="small"  exclusive>
                    <Atoms.ToggleButton  textColorHeader label="outright" value={props.RFQType} highlightValue={'OUTRIGHT'} onClick={props.onRFQTypeChange('OUTRIGHT')}>
                      OUTRIGHT
                    </Atoms.ToggleButton>
                    <Atoms.ToggleButton  textColorHeader label="swap" value={props.RFQType} highlightValue={'SWAP'} onClick={props.onRFQTypeChange('SWAP')}>
                      SWAP
                    </Atoms.ToggleButton>
                    <Atoms.ToggleButton  textColorHeader label="spot" value={props.RFQType} highlightValue={'SPOT'} onClick={props.onRFQTypeChange('SPOT')}>
                      SPOT
                    </Atoms.ToggleButton>
                </ToggleButtonGorupStyle>
              </Grid>
            </Grid>
          )}

          {props.request && ['WAIT', 'CANCEL', 'ANSWER', 'REJECT', 'CONFIRM', 'EXPIRED'].includes(props.request.status) && (
            <Grid container xs={12}  alignItems="center" justifyContent="space-between" direction="row">
              <Grid item>
                <Text  size="20" fontFamily="bold" bold  titleCard textLeft>RFQ</Text>
              </Grid>
              <Grid item>
              <ToggleButtonGorupStyle  size="small"  exclusive>
                  <Atoms.ToggleButton  textColorHeader label="normal" value={props.RFQType} highlightValue={"OUTRIGHT"} disabled >
                  OUTRIGHT
                  </Atoms.ToggleButton>
                  <Atoms.ToggleButton  textColorHeader label="50" value={props.RFQType} highlightValue={'SWAP'} disabled>
                    SWAP
                  </Atoms.ToggleButton>
                  <Atoms.ToggleButton  textColorHeader label="50" value={props.RFQType} highlightValue={'SPOT'} disabled>
                    SPOT
                  </Atoms.ToggleButton>
                </ToggleButtonGorupStyle>
              </Grid>
            </Grid>
          )}

        </>
      )}

    </Atoms.Card>
  );
};
    
export default TopCardClient;