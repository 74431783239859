import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ToggleButtonStyle} from "../../Styles/Atoms/Button";

const ToggleButton = (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <ToggleButtonStyle
      {...props}
      className={`${props.highlightValue === props.value ? 'Mui-selected' : ''}`}
      value={props.value}
      aria-label={props.label}
      theme={com.state.theme}
    >
      {props.children}
    </ToggleButtonStyle>
  );
};


export default ToggleButton;