import { Box } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useContext, useState, useCallback, useEffect, useRef } from "react"
import Atoms from "../../Atoms";
import Components from "../../Components";
import { ContextBase, SocketMethods } from "../../Hooks";
import { Content } from "../../Styles/Pages/Content";
import button from "./customHeader/button";
import toggleButton from "./customHeader/toggleButton";
import AddNew from "./Dialog";
const { v4: uuidv4 } = require('uuid');

let parentId = uuidv4();

const Template = () => {
  const ctx: any = useContext(ContextBase);

  useEffect(() => {
    SocketMethods.rfqUpdate(ctx.state, ctx.dispatch);
    if (localStorage.getItem("rfq_theme_default")) {
      ctx.dispatch({
        type: "CHANGE_THEME_MODE",
        payload: { theme: localStorage.getItem("rfq_theme_default") },
      });
    }
  }, []);

  const gridRef = useRef<any>(null);

  const [rows, setRows] = useState<Array<any>>(new Array());
  const [deleteRow, setDeleteRow] = useState<any>()
  const [filter, setFilter] = useState<string>();
  const [selectedRow, setSelectedRow] = useState<any>()
  const [isEditing, setIsEditing] = useState<number>();
  const [instruments, setInstruments] = useState<Array<string>>([]);

  const onBtnRender = (e: any) => {
    if (e.status === "cancel")
      setDeleteRow(e)
  }

  const onBtnEdit = (index: any, data: any) => {
    setTimeout(() => setIsEditing(index), 500)
  }

  const onBtnClear = () => {
    setRows([]);
    SocketMethods.rfsCancelOrder({
      msg_type: 'CANCEL_ALL_RFQ',
      filter: 'FWD',
      parentId
    });
  };

  const onFilter = (e: any) => {
    setFilter(e)
  };

  const [columns, setColumns] = useState<Array<any>>([
    {
      headerName: "Value",
      field: "value",
      minWidth: 120,
      resizable: true,
      flex: 1,
      editable: true,
      cellEditor: "datePickerRenderer",
      cellEditorParams: {
        disablePast: true
      }
    },
    {
      headerName: "Fix",
      field: "fix",
      minWidth: 120,
      resizable: true,
      flex: 1
    },
    {
      headerName: "my Bid",
      field: "myBid",
      editable: true,
      minWidth: 100,
      resizable: true,
      flex: 1
    },
    {
      headerName: "Bid",
      field: "bid",
      minWidth: 100,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "offer",
      field: "offer",
      minWidth: 100,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "My offer",
      field: "myOffer",
      minWidth: 100,
      resizable: true,
      flex: 1,
      editable: true
    },
    {
      headerName: "Notional",
      field: "notional",
      minWidth: 140,
      resizable: true,
      flex: 1,
      editable: true,
      valueFormatter: (params: any) => `$${params.value}`,
    },
    {
      field: "type",
      minWidth: 120,
      resizable: true,
      flex: 1,
      headerComponentFramework: toggleButton,
      headerComponentParams: {
        clicked: onFilter
      },
      editable: (params: any) => params.data.isRequested ? false : true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['OCA', 'ALL']
      },
      filter: 'agTextColumnFilter'
    },
    {
      headerName: "Open",
      field: "open",
      minWidth: 140,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "filled",
      field: "filled",
      minWidth: 140,
      resizable: true,
      flex: 1,
    },
    {
      headerName: "",
      field: "action",
      minWidth: 145,
      resizable: true,
      flex: 1,
      cellRenderer: "cellButtonRenderer",
      cellRendererParams: {
        clicked: onBtnRender,
        editButton: true,
        editClicked: onBtnEdit
      },
      headerComponent: button,
      headerComponentParams: {
        clicked: onBtnClear
      }
    }
  ]);
  const normalSpans = ["TD", "TOM", "SN", "1W", "2W", "3W", "1M", "3M", "9M", "BMF"]
  const [presetSpanList, setPresetSpanList] = useState<Array<string>>(normalSpans);
  const [valueDate, setValueDate] = useState<any>();
  const [valueDateSpan, setValueDateSpan] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [multiple, setMultiple] = useState(false);
  const [instrument_id, setInstrument] = useState<number>()

  const onInputChange = (event: any) => {
    setInstrument(event.target.value);
  }

  const setDate = (input: string) => (event: any) => {
    switch (input) {
      case "selectDate":
        if (!presetSpanList.includes(event.target.value)) {
          return;
        }
        // const selected: string = presetSpanList[parseInt(event.target.value)];

        const isSetteable: boolean = !["TOM", "SN", "BMF"].includes(event.target.value);
        setValueDateSpan(event.target.value);
        // setValueDateSpan(selected);
        if (isSetteable) {
          setValueDate(getPeriodSpan(event.target.value));
        }
        break;
      case "valueDate":
        setValueDate(event);
        break;
      default:
        break;
    }
    //setErrors({ ...errors, [input]: false });
  };

  const getPeriodSpan = (spanString: string): Date => {
    const now: Date = new Date();
    let span: Date;

    switch (spanString) {
      // case "TD":
      //   now;
      //   break;
      case "1W":
        now.setDate(now.getDate() - 7);
        break;
      case "2W":
        now.setDate(now.getDate() - 14);
        break;
      case "3W":
        now.setDate(now.getDate() - 21);
        break;
      case "1M":
        now.setMonth(now.getMonth() - 1);
        break;
      case "3M":
        now.setMonth(now.getMonth() - 3);
        break;
      case "9M":
        now.setMonth(now.getMonth() - 9);
        break;
      case "TOM":
        break; // ANY DATE | EDGEWATER'll IMPLEMENT
      case "SN":
        break; // ANY DATE | EDGEWATER'll IMPLEMENT
      case "BMF":
        break; // ANY DATE | EDGEWATER'll IMPLEMENT
      default:
        break;
    }

    span = now;
    return span;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = useCallback(() => {
    if (valueDate) {
      setOpen(false);
      // const newStore = rows.slice();
      // newStore.push({
      //   client_order_id: Math.random(),
      //   value: "2021/08/15",
      //   fix: "2021/08/15",
      //   myBid: "26.00",
      //   bid: "26.00",
      //   offer: "26.20",
      //   notional: "000000",
      //   type: filter ? filter.toUpperCase() : "OCA",
      //   open: "2021/08/15",
      //   filled: "2021/08/15",
      //   status: "cancel"
      // });

      const instr: any = instruments.find((e: any) => e.instrument_id == instrument_id);
      const formatDate = valueDate.toISOString().split("T")[0].replaceAll("-", "");

      SocketMethods.rfsSubscribe(
        {
          "streamType": "outright",
          "instrumentId": instr.instrument_id,
          "instrumentPair": instr.symbol,
          "clientRequestId": null,
          "datePublish": new Date(),
          "date": valueDate.toISOString(),
          "tenor": valueDateSpan,
          "tenorFar":""
        }
      );

      // setRows(newStore);
      // setValueDate(undefined);
      // setValueDateSpan(undefined);

    } else {
      alert("Select a date")
    }
  }, [rows, valueDate, filter]);

  const handleReject = () => {
    setRows([]);
    setMultiple(false);
  }

  const handleAccept = useCallback(() => {
    const newStore = rows.slice();
    newStore.map((i) => {
      i.status = "done";
      return i
    });
    setRows(newStore);
    gridRef.current!.api.setRowData(newStore);
    setMultiple(false)

  }, [rows]);

  const onCellEdited = useCallback((event) => {
    const newStore = rows.slice()
    const oldData = event.data;
    const field = event.colDef.field;
    const newValue = event.newValue;
    const newData = { ...oldData, ...selectedRow };
    newData[field] = event.newValue;
    console.log('onCellEditRequest, updating ' + field + ' to ' + newValue);
    setSelectedRow(newData)
    //setRows(newStore)
    // const tx = {
    //   update: [newData],
    // };
    // event.api.applyTransaction(tx);
  }, [selectedRow]);

  const onGridReady = useCallback(() => {
    setFilter("oca")
  }, []);

  useEffect(() => {
    if (isEditing !== undefined) {
      if (selectedRow) {
        const newStore = rows.slice();
        selectedRow.isRequested = true;
        newStore[isEditing] = selectedRow;
        setRows(newStore);

        const instr: any = instruments.find((e: any) => e.instrument_id == selectedRow.instrument_id);

        SocketMethods.rfsNewOrder({
          referencePrice: selectedRow.bid,
          instrument_id: selectedRow.instrument_id,
          side: selectedRow.myBid !== "" ? "BUY" : "SELL",
          qty: selectedRow.notional,
          price: selectedRow.myBid !== "" ? selectedRow.myBid : selectedRow.myOffer,
          time_in_force: 'GTC',
          order_type: 'LIMIT',
          expire_time_seconds: 5000,
          slippage_pips: 3,
          currency: `${instr.symbol}`.split("/")[0],
          reference_widget: "plantilla",
          timestamp_ui: new Date().toISOString(),
          reference_book: selectedRow.book_update,
          link_id: parentId
        })
      }
      setSelectedRow(undefined)
      setIsEditing(undefined)

    }
  }, [isEditing])

  useEffect(() => {
    if (deleteRow) {
      const newStore = rows.slice();
      setRows(newStore.filter(e => e.client_order_id !== deleteRow.client_order_id));
      SocketMethods.rfsCancelOrder({
        msg_type: 'ORDER_REPLACE',
        is_cancel: true,
        client_order_id: deleteRow.client_order_id
      });

    }
  }, [deleteRow])


  useEffect(() => {
    if (filter && gridRef.current) {
      const columnDefs: Array<any> = columns;
      columnDefs.forEach(function (colDef) {
        if (colDef.field === 'myBid' || colDef.field === 'myOffer') {
          colDef.hide = (filter === "oca" ? false : true);
        }
      });
      if (filter === "all") {
        onBtnClear();
      }
      gridRef.current!.api.setFilterModel({ type: { type: 'startsWith', filter } });
      // } else {
      //   gridRef.current!.api.setFilterModel({ type: { type: 'startsWith', values: ["oca", "all"] } });
      // }
      gridRef.current!.api.setColumnDefs(columnDefs);
    }
  }, [filter])


  useEffect(() => {
    setInstruments(ctx.state.instruments);
  }, [ctx.state.instruments]);

  useEffect(() => {
    if (ctx.state.RFQ_UPDATE && ctx.state.RFQ_UPDATE.client_request_id) {
      const newStore = rows.slice();
      const index = newStore.findIndex((e: any) => e.client_order_id == ctx.state.RFQ_UPDATE.client_request_id);
      if (index > -1) {
        var newItem = newStore[index];
        newItem.bid = ctx.state.RFQ_UPDATE.bids[0] ? ctx.state.RFQ_UPDATE.bids[0].price : "";
        newItem.offer = ctx.state.RFQ_UPDATE.asks[0] ? ctx.state.RFQ_UPDATE.asks[0].price : "";
        gridRef.current!.api.applyTransaction({ update: [newItem] });
        setRows(newStore);
      } else {
        newStore.push({
          client_order_id: ctx.state.RFQ_UPDATE.client_request_id,
          value: ctx.state.RFQ_UPDATE.timestamp.split("T")[0],
          fix: ctx.state.RFQ_UPDATE.fixing_date,
          myBid: "",
          bid: ctx.state.RFQ_UPDATE.bids[0] ? ctx.state.RFQ_UPDATE.bids[0].price : "",
          offer: ctx.state.RFQ_UPDATE.asks[0] ? ctx.state.RFQ_UPDATE.asks[0].price : "",
          myOffer: "",
          notional: "",
          type: filter ? filter.toUpperCase() : "OCA",
          open: "",
          filled: "",
          status: "cancel",
          instrument_id: 31,
          book_update: ctx.state.RFQ_UPDATE
        });
        setRows(newStore);

        setValueDate(undefined);
        setValueDateSpan(undefined);
        setInstrument(undefined)
      }
    }
  }, [ctx.state.RFQ_UPDATE]);

  return (
    <Content
      theme={ctx.state.theme}
      display="flex"
      flexDirection="column"
    >
      <Box padding={1}>
        <Atoms.Select name="select" alignItems display simple noMargin value={instrument_id} onChange={onInputChange}>
          <Atoms.Option value={0} key={0}>Instrument</Atoms.Option >
          {(instruments && instruments.length > 0) &&
            instruments.map((item: any, index: any): any => (
              <Atoms.Option key={index} value={item.instrument_id}>{item.symbol}</Atoms.Option >
            ))}

        </Atoms.Select>
        <Atoms.Button
          ml
          color="secondary"
          variant="outlined"
          onClick={handleClickOpen}
          startIcon={<Add />}
          simple
          BtnSimple
        >
          Add New
        </Atoms.Button>
        <AddNew
          open={open}
          onClose={handleClose}
          onSetToday={setDate}
          handleClick={handleAdd}
          {...{ presetSpanList, valueDate, valueDateSpan }}
        />
      </Box>

      <Components.Table
        rows={rows}
        columns={columns}
        height="100%"
        reference={gridRef}
        onCellEdited={onCellEdited}
        editType={'fullRow'}
        suppressClickEdit={true}
        onGridLoaded={onGridReady}
      />
      {multiple ?
        <Box padding={1} display="flex" justifyContent="flex-end">
          <Atoms.Button color="error" simple onClick={handleReject}>REJECT</Atoms.Button>
          <Atoms.Button color="success" simple onClick={handleAccept}>ACCEPT</Atoms.Button>
        </Box>
        : filter === "all" ? <Box padding={1} display="flex" justifyContent="flex-end">
          <Atoms.Button color="secondary" simple onClick={() => setMultiple(true)}>PX ALL</Atoms.Button>
        </Box> : ""}
    </Content>
  )
}

export default Template