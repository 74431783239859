import { Grid } from "@material-ui/core";
import React from "react";
import Atoms from "../../../Atoms";
import LoaderCard from "../../../Atoms/LoaderCard";
import { ContentText } from "../../../Styles/Atoms/Text";
import { BlockInner, ContentInner } from "../../../Styles/Pages/Content/BodyCard";

const ContentRequest = (props: any) => {

    return (
        <React.Fragment>
            <React.Fragment>
                {["send", "waiting"].includes(props.requestStatus) &&
                    <ContentInner>
                        <Grid alignItems="center" container item direction="column" justifyContent="center" spacing={2}>
                            <Grid container item xs md={5} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" uppercase white>{props.requestType === "bid" ? 'Sell' : props.requestType === "offer" ? 'Buy' : "Sell/Buy"}:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.currency}</Atoms.Text>
                            </Grid>
                            <Grid container item xs md={5} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" uppercase white>AMOUNT:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.currency} {props.notional}</Atoms.Text>
                            </Grid>
                            {props.value === "OUTRIGHT" && <Grid container item xs md={5} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" uppercase white>DATE:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.presetSpanList[props.valueDateSpan]} {new Date(props.valueDate).toLocaleDateString()}</Atoms.Text>
                            </Grid>
                            }
                            {props.value === "SWAP" && <>
                                <Grid container item xs md={5} justifyContent="space-between">
                                    <Atoms.Text fontFamily="book" size="24" uppercase white>NEAR LEG:</Atoms.Text>
                                    <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.presetSpanList[props.nearLegSpan]} {new Date(props.nearLeg).toLocaleDateString()}</Atoms.Text>
                                </Grid>
                                <Grid container item xs md={5} justifyContent="space-between">
                                    <Atoms.Text fontFamily="book" size="24" uppercase white>FAR LEG:</Atoms.Text>
                                    <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.presetSpanList[props.farLegSpan]} {new Date(props.farLeg).toLocaleDateString()}</Atoms.Text>
                                </Grid>
                            </>
                            }
                        </Grid>

                        <Grid alignItems="center" container item direction="column" justifyContent="center" spacing={2}>
                            {props.requestStatus === "send" && <Grid container direction="column" item xs md={5}>
                                <Atoms.Text block size="28" textCenter>
                                    SELL PRICE REQUESTED
                                </Atoms.Text>
                                <LoaderCard onClick={() => props.onStatusChange("waiting")}></LoaderCard>
                                <Atoms.Text
                                    fontFamily="book"
                                    size="16"
                                    block
                                    textCenter
                                    white
                                >
                                    Waiting for reply
                                </Atoms.Text>
                            </Grid>}

                            {props.requestStatus === "waiting" && <Grid container item xs md={5}>
                                <Grid item xs={12}>
                                    <Atoms.TimeCountdown
                                        onExpiration={() => props.onStatusChange("expired")}
                                        expires={10}
                                    />
                                    <Atoms.ProgressCard expires={props.expires} />
                                </Grid>
                                <Grid item xs={12}>
                                    <ContentText {...props} important>
                                        <Atoms.Text size="26" fontFamily="bold" textLeft>
                                            {props.value==="SWAP"?"SWAP":"FOWARD"} POINTS:
                                        </Atoms.Text>
                                        <Atoms.Text size="26" fontFamily="bold" white >
                                            -.020
                                        </Atoms.Text>
                                    </ContentText>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </ContentInner>
                }


                {props.requestStatus === "done" &&
                    <ContentInner>
                        <Grid alignItems="center" container item direction="column" justifyContent="center" spacing={2}>
                            <Grid item xs md={5}>
                                <Atoms.AnimationCard
                                    animation={"confirm"}
                                    height={100}
                                    width={100}
                                ></Atoms.AnimationCard>
                                <Atoms.Text block size="26" fontFamily="bold" textCenter>
                                    Deal done
                                </Atoms.Text>
                                <Atoms.Text block size="26" fontFamily="bold" textCenter white>
                                    {props.currency}
                                </Atoms.Text>
                            </Grid>
                            <Grid container item xs md={5} justifyContent="space-between">
                                <Atoms.Text fontFamily="bold" size="26" uppercase white>{props.requestType === "bid" ? 'Sell' : props.requestType === "offer" ? 'Buy' : "Sell/Buy"}</Atoms.Text>
                                <Atoms.Text fontFamily="bold" size="26" uppercase white>{props.currency} {props.notional}</Atoms.Text>
                            </Grid>
                            {props.value === "OUTRIGHT" && <Grid container item xs md={5} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" uppercase white>DATE:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.presetSpanList[props.valueDateSpan]} {new Date(props.valueDate).toLocaleDateString()}</Atoms.Text>
                            </Grid>
                            }
                            {props.value === "SWAP" && <>
                                <Grid container item xs md={5} justifyContent="space-between">
                                    <Atoms.Text fontFamily="book" size="24" uppercase white>NEAR LEG:</Atoms.Text>
                                    <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.presetSpanList[props.nearLegSpan]} {new Date(props.nearLeg).toLocaleDateString()}</Atoms.Text>
                                </Grid>
                                <Grid container item xs md={5} justifyContent="space-between">
                                    <Atoms.Text fontFamily="book" size="24" uppercase white>FAR LEG:</Atoms.Text>
                                    <Atoms.Text fontFamily="secondary" size="22" uppercase gray>{props.presetSpanList[props.farLegSpan]} {new Date(props.farLeg).toLocaleDateString()}</Atoms.Text>
                                </Grid>
                            </>
                            }
                            <Grid container item xs md={5} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" uppercase white>Spot Rate:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>726.15</Atoms.Text>
                            </Grid>
                            <Grid container item xs md={5} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" uppercase white>{props.value==="SWAP"?"SWAP":"FOWARD"} Points:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>-.08</Atoms.Text>
                            </Grid>
                            <Grid container item xs md={5} justifyContent="space-between">
                                <ContentText {...props} important>
                                    <Atoms.Text size="26" fontFamily="bold" textLeft>
                                        ALL-IN:
                                    </Atoms.Text>
                                    <Atoms.Text size="26" fontFamily="bold" white >
                                        726.15
                                    </Atoms.Text>
                                </ContentText>
                            </Grid>
                        </Grid>
                    </ContentInner>
                }

                {["canceled", "rejected", "expired"].includes(props.requestStatus) &&
                    <ContentInner>
                        <Atoms.AnimationCard
                            animation={"cancel"}
                            height={60}
                            width={60}
                        ></Atoms.AnimationCard>
                        <Atoms.Text size="24" textCenter block>
                            The transaction has been {props.requestStatus}
                        </Atoms.Text>
                    </ContentInner>
                }
            </React.Fragment>
        </React.Fragment>
    )
}

export default ContentRequest