import React, { useEffect } from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import Grid from '@material-ui/core/Grid';
import Atoms from "../../Atoms";
import Components from "../../Components"
//import {AppBarStyle, TabsStyle, TabStyle} from "../../Styles/Atoms/Tabs";
import PropTypes from 'prop-types';

import AddCircleOutline from '@material-ui/icons/AddCircleOutline';

function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{height: "100%"}}
    > 
      {value === index && (
          <div style={{height: "100%"}}>{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index:any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const InputPanel = (props: any) => {
  let com: any = useContext(ContextBase);

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
  },[com])
  
  return (
    
    <Grid item xs={12} md={4}>
    <Atoms.Card fullHeight whitHeader>

      {/* <Atoms.HeaderCard justify="space-between"> */}

        {/* <AppBarStyle position="static" theme={com.state.theme}>
          <TabsStyle value={value} onChange={handleChange} aria-label="simple tabs example" theme={com.state.theme}>
            <TabStyle label="Inbound rates" {...a11yProps(0)} theme={com.state.theme} />
            <TabStyle label="Forward curve" {...a11yProps(1)} theme={com.state.theme} />
          </TabsStyle>
        </AppBarStyle> */}

        {value===0 && <Atoms.Button size="small" noBorder startIcon={<AddCircleOutline/>} onClick={handleClickOpen}>input</Atoms.Button>}
        
        <Atoms.Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          title="Add Inbound"
          newItem
        >
          
          <Grid container xs={12}  spacing={1}>
            <Grid item xs={12} md={4}>
              <Atoms.Select name="select" label="CCY.PAIR:" column>
                <Atoms.Option value="value1">EUR/USD</Atoms.Option >
                <Atoms.Option  value="value2" defaultValue="value2">EUR/PLZ</Atoms.Option >
                <Atoms.Option  value="value3">EUR/MXN</Atoms.Option > 
              </Atoms.Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <Atoms.Select name="select" label="STREAM:" column>
                <Atoms.Option value="value1">L.POOL1</Atoms.Option >
                <Atoms.Option  value="value2" defaultValue="value2">L.POOL2</Atoms.Option >
              </Atoms.Select>
            </Grid>
            <Grid item xs={12} md={4}>
              <Atoms.Input type="text" name="tier" label="TIER:" column />
            </Grid>
          </Grid>
        </Atoms.Dialog>

      {/* </Atoms.HeaderCard> */}
      <Atoms.CardBody >
        {/* <TabPanel value={value} index={0}>
           <Components.TableInbound/> 
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Components.ForwardCurve/>
        </TabPanel> */}
      </Atoms.CardBody>

    </Atoms.Card>
  </Grid>
  );
};

export default InputPanel;
