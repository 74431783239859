const SimpleDateToString = (date:number | Date): string => {

    let dateString: string = '';

    try {
      const date_ = new Date(date);
      let dd: string | number = date_.getDate();

      let mm: string | number = date_.getMonth()+1; 
      let yyyy = date_.getFullYear();
      if(dd<10){
          dd='0'+dd;
      } 

      if(mm<10){
          mm='0'+mm;
      } 

      dateString = `${dd}/${mm}/${yyyy}`;
      
    } catch(error) {
      dateString = 'InvalidDate';
    }

    return dateString;
};

export {SimpleDateToString};