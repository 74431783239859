import { Colors, FontSize, Fonts } from "../../Constants";

import Styled from "styled-components";


const IconCard = Styled.img<any>`
margin:0px auto;
display:block;

`;


export {IconCard};