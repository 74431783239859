import { Grid } from "@material-ui/core"
import { Key, useState } from "react"
import Components from ".."
import Atoms from "../../Atoms"
import { Box, ContentInner, Label } from "../../Styles/Pages/Content/BodyCard"
import Table from "../TableAg"
import { Add } from "@material-ui/icons"
import { ToggleButtonGorupStyle } from "../../Styles/Atoms/Button"
import { ContentText } from "../../Styles/Atoms/Text";


const data =[
    {
        id: "0",
        acount: "000",
        amount: '000',
        action: ""
    },
    {
        id: "1",
        acount: "001",
        amount: '1,000,000',
        action: ""
    },
    // {
    //     id: "2",
    //     acount: "002",
    //     amount: '9,000,000',
    //     action: ""
    // },
    // {
    //     id: "3",
    //     acount: "003",
    //     amount: '10,000,000',
    //     action: ""
    // },
]

const BodyCardEDF = (props) => {

    // const onBtnRender = (e) => {
    //       props.onRemove(e)
    //   }

    const [columns, setColumns] = useState([
        {
            headerName: "",
            field: "id",
            minWidth: "70",
            flex: 1
        },
        {
            headerName: "Account",
            field: "acount",
            minWidth: "70",
            flex: 1
        },
        {
            headerName: "Amount",
            field: "amount",
            minWidth: "120",
            flex: 1
        },
        {
            headerName: "",
            field: "action",
            minWidth: "100",
            flex: 1,
            cellRenderer: "cellButtonRenderer",
            // cellRendererParams: {
            //     clicked: onBtnRender,
            // }
        },
    ]);
    console.log(data.length)

    return (
        <Atoms.Card
            bodyCard
            BodyCardHeight
            fullHeight
        >
            <ContentInner>
                <Grid alignItems="center" container item justifyContent="center" spacing={2}>
                    <Grid container item xs={12} sm={8} justifyContent="center">
                        <Grid item>
                            <ToggleButtonGorupStyle size="large" exclusive
                                value={props.value} onChange={props.onToggle}
                            >
                                <Atoms.ToggleButton value="sell">
                                    SELL
                                </Atoms.ToggleButton>
                                <Atoms.ToggleButton value="buy">
                                    BUY
                                </Atoms.ToggleButton>
                            </ToggleButtonGorupStyle>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid alignItems="center" container item justifyContent="center" spacing={2}>
                    <Grid container item xs="12" sm={8} justifyContent="center">
                        <Grid item xs>
                            <Box className="bid">
                                <Label className="label hover" noBorder cursor="pointer" disabled={props.value === "buy"}>
                                    <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white>SELL</Atoms.Text>
                                    <Atoms.Text block fontFamily="secondary" size="26" textCenter uppercase gray>19.90</Atoms.Text>
                                    <Atoms.Text block fontFamily="secondary" size="75" textCenter uppercase className="hover">42</Atoms.Text>
                                </Label>
                            </Box>
                        </Grid>
                        <Grid item xs>
                            <Box className="offer">
                                <Label className="label hover" noBorder cursor="pointer" disabled={props.value === "sell"}>
                                    <Atoms.Text block fontFamily="book" size="24" textCenter uppercase white>BUY</Atoms.Text>
                                    <Atoms.Text block fontFamily="secondary" size="26" textCenter uppercase gray>19.90</Atoms.Text>
                                    <Atoms.Text block fontFamily="secondary" size="75" textCenter uppercase className="hover">50</Atoms.Text>
                                </Label>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid alignItems="center" container item justifyContent="center" spacing={2}>
                    <Grid container item xs={12} sm={8} justifyContent="center" spacing={2}>
                        <Grid item xs={6}>
                            <Atoms.Text block size="20" fontFamily="bold" white>Account:</Atoms.Text>
                            <Atoms.Select 
                                name="select" 
                                alignItems 
                                fullWidth
                                noMargin 
                                input
                                borderer
                                colorArrowInvert
                                onChange={props.onInputChange("client")}
                            >
                                    <Atoms.Option value="001">Client 001</Atoms.Option>
                                    <Atoms.Option value="002">Client 002</Atoms.Option>
                                    <Atoms.Option value="003">Client 003</Atoms.Option>
                            </Atoms.Select>
                        </Grid>
                        <Grid container item xs={6} alignItems="flex-end">
                            <div style={{flex: '1'}}>
                            <Atoms.Text block size="20" fontFamily="bold" white>Amount:</Atoms.Text>
                            <Atoms.Input
                                headerTop
                                borderer
                                fullWidth
                                type="text"
                                onChange={props.onInputChange('national')}
                                value={props.national}
                            />
                            </div>
                            
                            <Atoms.Button  Icon BtnSimple simple ml>
                                <Add />
                            </Atoms.Button>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={8} spacing={2}>
                        <Grid item xs={12}>
                            <Table
                                // rows={props.data}
                                rows={data}
                                columns={columns}
                                // pagination={true}
                                // paginationPageSize={5}
                                height={data.length === 0 ? '0px' : (data.length * 42 + 74) + 'px' }
                                // header 57px 
                                // row 42px
                                // scroll 17px + 57px = 74
                                // header fix space 99px
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={8} spacing={2}>
                        {/* <Grid item xs={12}> */}
                            
                            <Grid container item xs={12} justifyContent="space-between">
                                <Atoms.Text fontFamily="bold" size="20" uppercase white>Total:</Atoms.Text>
                                <Atoms.Text fontFamily="bold" size="20" uppercase white>0.00</Atoms.Text>
                            </Grid>
                                
                            <Grid container item xs={12} justifyContent="space-between">
                                <ContentText {...props} important>
                                    <Atoms.Text size="26" fontFamily="bold" textLeft>
                                        Final Client Rate
                                    </Atoms.Text>
                                    <Atoms.Input
                                        headerTop
                                        borderer
                                        type="text"
                                        onChange={props.onInputChange('fcr')}
                                        value={props.fcr}
                                    />
                                </ContentText>
                            </Grid>

                             <Grid container item xs={12} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" white>Term amount:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>0.00</Atoms.Text>
                            </Grid>
                             <Grid container item xs={12} justifyContent="space-between">
                                <Atoms.Text fontFamily="book" size="24" uppercase white>Profit:</Atoms.Text>
                                <Atoms.Text fontFamily="secondary" size="22" uppercase gray>0.00</Atoms.Text>
                            </Grid>
                        {/* </Grid> */}
                    </Grid>

                </Grid>
            </ContentInner>
        </Atoms.Card>
    )
}

export default BodyCardEDF