export default {};
const width = window.innerWidth,
  height = window.innerHeight;

const pixelRatio = window.devicePixelRatio;
const normalize = (size: number) => {
  if(pixelRatio >= 2 ) return Math.round(size * pixelRatio) 
  return Math.round(size * pixelRatio) - 2; 
};

export { normalize };
