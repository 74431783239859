import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ContentText,Text} from "../../Styles/Atoms/Text";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    
    <Text
      {...props}
      theme={com.state.theme}
      important={props.important}
      blink={props.blink}
      error={props.error}
      success={props.success}
      threeColumn={props.threeColumn}
      hiddenMovile ={props.hiddenMovile}
      seconds={props.seconds}
      allInInput={props.allInInput}
      importantMargin={props.importantMargin}
      gutterBottom labelCard={props.labelCard} white={props.white} textLeft={props.textLeft} block={props.block} textRight={props.textRight} bold={props.bold}
    >
        {props.children}
    </Text>
  
  );
};
