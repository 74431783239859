import { Colors, FontSize, Fonts } from "../../Constants";

import Styled, {css} from "styled-components";

const ContentInput = Styled.div<any>`
  width: calc(100% - 2px);
  margin: 0 1px;
  box-sizing: border-box;
  color: ${props => Colors[props.theme].primary.white };
  background-color: ${props => Colors[props.theme].background.headerTable} !important;
  padding: 5px;
  border: 1px solid;
  border-color: ${props => Colors[props.theme].border.main} !important;
  border-bottom: none;
`;

const ContentInputSearch = Styled.div<any>`
margin: 1px;
  display: flex;
  align-items: center;
  background-color:  ${props => {
    if (props.error) {
      return "#ffd8d5";
    } 
    return Colors[props.theme].background.headerTable;
    }};
  
  border: 1px solid;
  cursor: pointer;
  border-color:  ${props => {
    if (props.error) {
      return "#ff695d";
    }
    return Colors[props.theme].background.headerTable;
  }};
  border-radius: 4px;
  padding-right: 5px;
  
  /* max-width: 240px !important; */
    /* @media screen and (min-width:562px){
      max-width: 240px !important;
    } */
`;

const InputSearch = Styled.input<any>`
  width: 100%;
  background-color: transparent;
    outline: none !important;
    border: none;
  height: 30px;
  padding: 0 5px;
  vertical-align: middle;
  box-sizing: border-box;
  ${FontSize.sz18};
  font-family: ${Fonts.primaryBook};
  text-align: left;
  color: ${props => Colors[props.theme].primary.white };
  &:focus{
    outline: none !important;
  }
`;


export {ContentInput, ContentInputSearch, InputSearch};