import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ContentButton, Button} from "../../Styles/Atoms/ButtonInline";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
    <ContentButton theme={com.state.theme} alignItems={props.alignItems} display>
      <Button {...props}>
        {props.children}
      </Button>  
    </ContentButton>
  );
};
