import React, { useEffect, useContext, useState } from "react";
import Components from "../../Components";
import Atoms from "../../Atoms";
import { ContentUserView } from "../../Styles/Pages/UserView";
import { ContextBase } from "../../Hooks";

// TEST
import Grid from "@material-ui/core/Grid";
import { ToggleButtonGorupStyle } from "../../Styles/Atoms/Button";


const MakerToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NTYzNTM2MDUsImlhdCI6MTY1NTkyMTYwNTMxNiwiZW1haWwiOiJGaW5hbmNpYWxNYWtlckAxMjMuY29tIiwiaWRfdXNlciI6Mjk2LCJ1c2VybmFtZSI6IkZpbmFuY2lhbE1ha2VyIiwiYWRtaW5fdG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKZmFXUWlPakk1Tml3aWRYTmxjbTVoYldVaU9pSkdhVzVoYm1OcFlXeE5ZV3RsY2lJc0ltVjRjQ0k2TVRZMU1ETXdNekl4TUM0ek1UTXNJbWxoZENJNk1UWTBOVEV5TWpneE1IMC55UmpvcGZwMGlybFFTbzVwRzBBTmpYcFFfNzRxbV9BZ0ctaVJOVmFSQUdZIn0.j_hfbTi_zBuFjjwjNhxj2a0e75ujsYRjXZe2ZyeGXsw";
const TakerToken =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NTYzNTMzODAsImlhdCI6MTY1NTkyMTM4MDQzMSwiZW1haWwiOiJGaW5hbmNpYWxUYWtlckAxMjMuY29tIiwiaWRfdXNlciI6Mjk3LCJ1c2VybmFtZSI6IkZpbmFuY2lhbFRha2VyIiwiYWRtaW5fdG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKZmFXUWlPakk1Tnl3aWRYTmxjbTVoYldVaU9pSkdhVzVoYm1OcFlXeFVZV3RsY2lJc0ltVjRjQ0k2TVRZMU1ETXdNemc0TVM0M016a3NJbWxoZENJNk1UWTBOVEV5TXpRNE1YMC5GcWx2T2hJYWlyVF9HcGhkVjNyMFJ5b3pzVklrdExySlNyeGZoMkhDTmdvIn0.guuIcXGPCosBvPusralT9rMkR3-WAcPadxROeQvbPwQ";


const UserView = () => {
  let ctx: any = useContext(ContextBase);

  useEffect(() => {
    if (localStorage.getItem("rfq_theme_default")) {
      ctx.dispatch({
        type: "CHANGE_THEME_MODE",
        payload: { theme: localStorage.getItem("rfq_theme_default") },
      });
    }
  }, []);

  const RFQ_USER_ROLE = "rfq_user_role";
  const RFQ_USER_TOKEN = "rfq_user_token";
  const RFQ_USER_PERMISSIONS = "rfq_user_permissions";

  const [role, setRole] = useState<string>(
    localStorage.getItem("rfq_user_role") || "USER"
  );

  const onUserRoleChange = (role: string) => (event: MouseEvent) => {
    setRole(role);
    localStorage.setItem(RFQ_USER_ROLE, role);
    localStorage.setItem(
      RFQ_USER_TOKEN,
      role === "ADMIN" ? MakerToken : TakerToken
    );
    if (role === "USERB") localStorage.setItem(RFQ_USER_PERMISSIONS, "ADMIN");
    else if (localStorage.getItem(RFQ_USER_PERMISSIONS))
      localStorage.removeItem(RFQ_USER_PERMISSIONS);
  };

  const onThemeChange = (event: any) => {
    console.log(event);
    ctx.dispatch({
      type: "CHANGE_THEME_MODE",
      payload: { theme: event.target.value },
    });
  };

  return (
    <ContentUserView theme={ctx.state.theme}>
      <Grid container spacing={2} alignItems="center" direction="row">
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item>
            <Atoms.Text size="20" fontFamily="bold" titleCard bold textLeft>
              Rol Usuario
            </Atoms.Text>
          </Grid>
          <Grid item>
            <ToggleButtonGorupStyle size="small" exclusive>
              <Atoms.ToggleButton
                light={ctx.state.theme === "lightBlue"}
                textColorHeader
                label="outright"
                value={role}
                highlightValue={"USER"}
                onClick={onUserRoleChange("USER")}
              >
                USER
              </Atoms.ToggleButton>
              <Atoms.ToggleButton
                light={ctx.state.theme === "lightBlue"}
                textColorHeader
                label="outright"
                value={role}
                highlightValue={"USERB"}
                onClick={onUserRoleChange("USERB")}
              >
                USER BOTH
              </Atoms.ToggleButton>

              <Atoms.ToggleButton
                light={ctx.state.theme === "lightBlue"}
                textColorHeader
                label="swap"
                value={role}
                highlightValue={"ADMIN"}
                onClick={onUserRoleChange("ADMIN")}
              >
                ADMIN
              </Atoms.ToggleButton>
            </ToggleButtonGorupStyle>
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          <Atoms.Text size="20" fontFamily="bold" bold textLeft>
            Tema
          </Atoms.Text>
        </Grid>
        <Grid item xs={6} md={6} style={{ textAlign: "right" }}>
          <Atoms.Select
            name="select"
            display
            headerTop
            noMargin
            bvalue={ctx.state.theme}
            onChange={onThemeChange}
          >
            <Atoms.Option value={"lightBlue"}>Light Blue</Atoms.Option>
            <Atoms.Option value={"blue"}>Blue</Atoms.Option>
            <Atoms.Option value={"black"}>Black</Atoms.Option>
            <Atoms.Option value={"green"}>Green</Atoms.Option>
          </Atoms.Select>
        </Grid>
      </Grid>
      
    </ContentUserView>
  );
};

export default UserView;
