import { useEffect, useReducer, createContext, useContext } from "react";
import { io } from "socket.io-client/build/index";
import { reducer, initialState } from "../Hooks/Hooks.reducers";
import {
  rfqsubscribe,
  rfqcreatemaker,
  rfqordernew,
  rfqordercancel,
  rfqmakerordercancel,
  socketMethods,
  rfqsubscribeBoth,
  rfqcreatemakerBoth,
} from "./Interfaces/requests";

const ENDPOINT = "wss://beta.latamfx.pro";
let ContextBase: any = createContext(initialState);

const socket = io(ENDPOINT, { path: "/ws" });

const SocketMethods: socketMethods = {
  instruments: () => {
    console.log("load-initial-instruments")
    socket.emit("load-initial-instruments", {
      token: localStorage.getItem("rfq_user_token"),
      data: {},
    });
  },

  //client view
  rfqSubscribe: (data: rfqsubscribe | rfqsubscribeBoth) => {
    console.log("rfqSubscribe", data);
    socket.emit("rfq-subscribe", data);
  },
  rfqClientOrderNew: (data: rfqordernew) => {
    // socket.emit("rfq-order-new", data);
    socket.emit("rfq-client-order", {
      ...data,
      clientId: data.client_order_id,
    });
  },
  rfqClientOrderConfirm: (data: rfqordernew) => {
    socket.emit("rfq-client-order", data);
  },
  rfqClientOrderCancel: (data: rfqordercancel) => {
    console.log("rfqClientOrderCancel", data);
    // socket.emit("rfq-order-cancel", data);
    socket.emit("rfq-client-order", { ...data, accept: false });
  },

  //market view
  rfqMakerOrderUpdate: (data: any) => {
    console.log("rfqMakerOrdeUpdate", data);
    socket.emit("rfq-maker-update", data);
  },
  rfqMakerOrdeCreate: (data: rfqcreatemaker | rfqcreatemakerBoth) => {
    console.log("rfqMakerOrdeCreate", data);
    socket.emit("rfq-create-maker", {
      ...data,
      status: "DEALING",
    });
  },
  rfqmaker: (state, dispatch) => {
    socket.on("RFQ_MAKER", (arg: any) => {
      console.log("RFQ_MAKER", arg);
      if (
        (arg.type === "BOTH" && arg.buy.status === "FILLED") ||
        (arg.type === "BOTH" && arg.sell.status === "FILLED")
      ) {
        arg.status = "FILLED";
      }

      if (localStorage.getItem("rfq_user_role") !== "ADMIN") {
        // temp if for response both
        if (arg.type === "BOTH" && arg.buy.status === "UPDATING") {
          arg.status = "UPDATING";
        }
        if (arg.clientUser === undefined) {
          arg.clientUser = arg.username;
          arg.client = arg.client_id;
        }
        switch (arg.status) {//Client
          case "NEW":
            console.log("CLIENT : NEW ", arg);
            dispatch({ type: "RFQ_TAKER", payload: arg });
            break;

          case "DEALING":
            console.log("CLIENT DEALING", arg, state.orders);
            dispatch({ type: "RFQ_TAKER_DEALING", payload: arg });
            break;
          case "UPDATING":
            console.log("CLIENT DEALING_UPDATE", arg, state.orders);
            dispatch({ type: "RFQ_TAKER_DEALING", payload: arg });
            break;
          case "CANCELED":
            console.log("CLIENT: CANCELED<-client", arg, state.orders);
            dispatch({ type: "RFQ_TAKER_CANCEL_ADMIN", payload: arg });
            break;
        }
      } else {
        if (
          arg.type === "BOTH" &&
          ![
            "NEW",
            "DEALING",
            "EXPIRED",
            "CANCELED",
            "FILLED",
            "REJECTED",
            "UPDATING",
          ].includes(arg.status)
        ) {
          arg.status = "NEW";
          arg.amount = arg.buy.amount;
        }
        console.log("RFQ_MAKER", arg);
        switch (
        arg.status // Blotter
        ) {
          case "NEW":
            console.log("BLOTTER : NEW ", arg);
            // Tests
            // if (arg.type === "BOTH") {
            //   arg.buy.clientSpread = "30";
            //   arg.buy.price = "20";
            //   arg.buy.frwPoints = "20";
            //   arg.sell.clientSpread = "30";
            //   arg.sell.price = "20";
            //   arg.sell.frwPoints = "20";
            // } else {
            //   arg.clientSpread = "30";
            //   arg.price = "20";
            //   arg.frwPoints = "20";
            // }
            // end Test
            if (
              arg.makerOrganization === null ||
              arg.makerOrganization === undefined
            ) {
              arg.makerOrganization = "-";
            }
            if (arg.type === "BOTH") {
              arg.allIn = "";
            } else if (arg.type === "SWAP") {
              arg.allIn = Number(arg.swapPoints) + Number(arg.clientSpread);
            } else {
              arg.allIn =
                Number(arg.clientSpread) +
                Number(arg.price) +
                Number(arg.frwPoints);
            }
            if (arg.allIn === NaN) arg.allIn = "";
            dispatch({
              type: "RFQ_MAKER_NEW_ADMIN",
              payload: {
                ...arg,
                client: arg.user_id,
                clientUser: arg.username,
              },
            });
            break;

          case "DEALING":
            console.log("Blotter: DEALING", arg);
            dispatch({ type: "RFQ_MAKER_ORDERS_UPDATE", payload: arg });
            break;
          case "EXPIRED":
            console.log("Blotter: EXPIRED", arg);
            dispatch({ type: "RFQ_MAKER_EXPIRED", payload: arg });
            break;
          case "FILLED":
            console.log("Blotter: FILLED", arg);
            if (arg.type === "BOTH") {
              arg.allIn =
                arg.buy.status === "FILLED" ? arg.allInBuy : arg.allInSell;
              arg.side = arg.buy.status === "FILLED" ? "BUY" : "SELL";
            }
            dispatch({ type: "RFQ_MAKER_FILLED", payload: arg });
            break;
          case "REJECTED":
            console.log("Blotter: CANCELED", arg);
            dispatch({ type: "RFQ_MAKER_REJECTED", payload: arg });
            break;
          case "CANCELED":
            console.log("Blotter: CANCELED", arg);
            dispatch({ type: "RFQ_MAKER_CANCEL_ADMIN", payload: arg });
            break;
        }
      }
    });
  },
  rfqMakerOrderCancel: (data: rfqmakerordercancel) => {
    console.log("rfqMakerOrderCancel", data);
    // socket.emit("rfq-order-cancel", data);
    socket.emit("rfq-maker-order-cancel", {
      ...data,
      accept: false,
    });
  },

  // PLANTILLA
  rfsSubscribe: (data: any) => {
    console.log("start-instrument-rfq-rfs-subscription", data);
    socket.emit("start-instrument-rfq-rfs-subscription", data);
  },
  rfsUpdateSubscription: (state, dispatch) => {
    socket.on("update-subscription", (arg: any) => {
      console.log("update-subscription", arg);
      dispatch({ type: "subscription", payload: arg });
    })
  },
  rfsNewOrder: (data: any) => {
    console.log("rfs-order", data);
    socket.emit("order-rfs", data);
  },
  rfsCancelOrder: (data: any) => {
    console.log("cancel-order");
    socket.emit("order-replace", data);
  },
  rfqUpdate: (state, dispatch) => {
    console.log("rfqUpdate")
    socket.on("rfq-update", (arg: any) => {
      dispatch({ type: "RFQ_UPDATE", payload: arg });
    })
  },
  rfsOrderUpdate: (state, dispatch) => {
    socket.on("RFQ-ORDER-UPDATE", (arg: any) => {
      console.log("RFS_order UPDATE....", arg);
      dispatch({ type: "RFQ_ORDER_UPDATE", payload: arg });
    })
  }
};

const Provider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {

    socket.on("connect", () => {

      console.log("con", socket.id); // x8WIv7-mJelg7on_ALbx

      const role = localStorage.getItem("rfq_user_role");
      console.log("role", role);

      socket.emit("socket-authentication", {
        token:localStorage.getItem("rfq_user_token"), // same as the session storage
        data: {
          platform: "latamfx",
        },
      },
        (err: any, data: any) => {
          if (err) {
            console.error(err);
            return;
          }

          console.log(
            'authentication has been completed, moving to load instruments'
          );

          // this is optional, is only to stop current rfq/rfs instruments
          socket.emit('stop-instrument-rfq-rfs-subscription-all');

          console.log("load-initial-instruments")
          socket.emit("load-initial-instruments", {
            token:localStorage.getItem("rfq_user_token")
          });

        });

    });


    // if (localStorage.getItem("rfq_user_role") !== "ADMIN") {
    socket.on("load-subscription", (arg: any) => {
      dispatch({ type: "instruments", payload: arg });
    });
    // }

    //client and maker
    socket.on("ORDER_UPDATE", (arg: any) => {
      console.log("order_update", arg);
    });

    // }
    // });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <ContextBase.Provider value={{ state, dispatch }}>
      {props.children}
    </ContextBase.Provider>
  );
};

const Context = (WrappedComponent: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(ContextBase);
  return <WrappedComponent {...props as {}} {...context as {}} />;
};

export default Provider;

export { ContextBase, Context, SocketMethods };
