import Colors from "./Colors";
import Fonts from "./Fonts";


const MaterialTheme : any | (() => any) = {
    lightBlue:{
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: Colors.lightBlue.calendar.header,
                }
            },
            MuiPickerDTTabs: {
                tabs: {
                    backgroundColor: Colors.lightBlue.calendar.header,
                }
            },
            PrivateTabIndicator: {
                colorSecondary: {
                    backgroundColor: Colors.lightBlue.calendar.selected,
                }
            },
            MuiPickersClock: {
                pin: {backgroundColor: Colors.lightBlue.calendar.selected,}
            },
            MuiPickersClockPointer: {
                pointer: {
                    backgroundColor: Colors.lightBlue.calendar.selected,
                },
                noPoint: {
                    backgroundColor: Colors.lightBlue.calendar.selected,
                },
                thumb: {
                    borderColor: Colors.lightBlue.calendar.selected,
                }
            },
            MuiPickersClockNumber:{
                clockNumber: {
                    color: "currentColor",
                }
            },
            MuiPaper: {
                root: {
                    backgroundColor: Colors.lightBlue.calendar.body,
                    color: "#fff"
                }
            },
            MuiTypography: {
                body1: {
                    fontFamily: Fonts.primaryMedium,
                },
                body2: {
                    fontFamily: Fonts.primaryBook
                },
                caption: {
                    fontFamily: Fonts.primaryBook
                }
            },
            MuiPickersStaticWrapper: {
                staticWrapperRoot: {
                    backgroundColor: Colors.lightBlue.calendar.body,
                    minWidth: "280px"
                }
            },
            MuiPickersBasePicker: {
                pickerView: {
                    minWidth: "280px"
                }
            },
            MuiPickersCalendarHeader: {
                iconButton: {
                    backgroundColor: Colors.lightBlue.calendar.body,
                    color: "currentColor"
                },
                transitionContainer: {
                    color: "currentColor"
                },
                dayLabel: {
                    color: "currentColor"
                }
            },
            MuiPickersDay: {
                day: {
                    color: "currentColor"
                },
                daySelected: {
                    backgroundColor: Colors.lightBlue.calendar.selected,
                    borderRadius: 0,
                    "&:hover": {
                    backgroundColor: Colors.lightBlue.calendar.selected
                    }
                },
                current: {
                    color: Colors.lightBlue.calendar.selected
                },
                dayDisabled: {
                    color: Colors.lightBlue.disabled.main,
                }
            },
            MuiButton: {
                textPrimary: {
                    color: Colors.lightBlue.secondary.light,
                }
            },
            MuiInput: {
                root: {
                    border: "1px solid",
                    color: Colors.lightBlue.secondary.light,
                },
            }
        }
    },

    blue:{
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: Colors.blue.calendar.header,
                }
            },
            MuiPickerDTTabs: {
                tabs: {
                    backgroundColor: Colors.blue.calendar.header,
                }
            },
            PrivateTabIndicator: {
                colorSecondary: {
                    backgroundColor: Colors.blue.calendar.selected,
                }
            },
            MuiPickersClock: {
                pin: {backgroundColor: Colors.blue.calendar.selected,}
            },
            MuiPickersClockPointer: {
                pointer: {
                    backgroundColor: Colors.blue.calendar.selected,
                },
                noPoint: {
                    backgroundColor: Colors.blue.calendar.selected,
                },
                thumb: {
                    borderColor: Colors.blue.calendar.selected,
                }
            },
            MuiPickersClockNumber:{
                clockNumber: {
                    color: "currentColor",
                }
            },
            MuiPaper: {
                root: {
                    backgroundColor: Colors.blue.calendar.body,
                    color: "#fff"
                }
            },
            MuiTypography: {
                body1: {
                    fontFamily: Fonts.primaryMedium,
                },
                body2: {
                    fontFamily: Fonts.primaryBook
                },
                caption: {
                    fontFamily: Fonts.primaryBook
                }
            },
            MuiPickersStaticWrapper: {
                staticWrapperRoot: {
                    backgroundColor: Colors.blue.calendar.body,
                    minWidth: "280px"
                }
            },
            MuiPickersBasePicker: {
                pickerView: {
                    minWidth: "280px"
                }
            },
            MuiPickersCalendarHeader: {
                iconButton: {
                    backgroundColor: Colors.blue.calendar.body,
                    color: "currentColor"
                },
                transitionContainer: {
                    color: "currentColor"
                },
                dayLabel: {
                    color: "currentColor"
                }
            },
            MuiPickersDay: {
                day: {
                    color: "currentColor"
                },
                daySelected: {
                    backgroundColor: Colors.blue.calendar.selected,
                    borderRadius: 0,
                    "&:hover": {
                    backgroundColor: Colors.blue.calendar.selected
                    }
                },
                current: {
                    color: Colors.blue.calendar.selected
                },
                dayDisabled: {
                    color: Colors.blue.disabled.main,
                }
            },
            MuiButton: {
                textPrimary: {
                    color: Colors.blue.secondary.light,
                }
            },
            MuiInput: {
                root: {
                    border: "1px solid",
                    color: Colors.blue.secondary.light,
                },
            }
        }
    },

    black: {
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: Colors.black.calendar.header,
                }
            },
            MuiPickerDTTabs: {
                tabs: {
                    backgroundColor: Colors.black.calendar.header,
                }
            },
            PrivateTabIndicator: {
                colorSecondary: {
                    backgroundColor: Colors.black.calendar.selected,
                }
            },
            MuiPickersClock: {
                pin: {backgroundColor: Colors.black.calendar.selected,}
            },
            MuiPickersClockPointer: {
                pointer: {
                    backgroundColor: Colors.black.calendar.selected,
                },
                noPoint: {
                    backgroundColor: Colors.black.calendar.selected,
                },
                thumb: {
                    borderColor: Colors.black.calendar.selected,
                }
            },
            MuiPickersClockNumber:{
                clockNumber: {
                    color: "currentColor",
                }
            },
            MuiPaper: {
                root: {
                    backgroundColor: Colors.black.calendar.body,
                    color: "#fff",
                },
            },
            MuiTypography: {
                body1: {
                fontFamily: Fonts.primaryMedium,
                },
                body2: {
                fontFamily: Fonts.primaryBook,
                },
                caption: {
                fontFamily: Fonts.primaryBook,
                },
            },
            MuiPickersStaticWrapper: {
                staticWrapperRoot: {
                    backgroundColor: Colors.black.calendar.body,
                    minWidth: "280px"
                }
            },
            MuiPickersBasePicker: {
                pickerView: {
                    minWidth: "280px"
                }
            },
            MuiPickersCalendarHeader: {
                iconButton: {
                backgroundColor: Colors.black.calendar.body,
                color: "currentColor",
                },
                transitionContainer: {
                color: "currentColor",
                },
                dayLabel: {
                color: "currentColor",
                },
            },
            MuiPickersDay: {
                day: {
                color: "currentColor",
                },
                daySelected: {
                backgroundColor: Colors.black.calendar.selected,
                borderRadius: 0,
                "&:hover": {
                    backgroundColor: Colors.black.calendar.selected,
                },
                },
                current: {
                    color: Colors.black.calendar.selected,
                },
                dayDisabled: {
                    color: Colors.black.disabled.main,
                }
            },
            MuiButton: {
                textPrimary: {
                    color: Colors.black.secondary.light,
                }
            },
            MuiInput: {
                root: {
                    border: "1px solid",
                    color: Colors.black.secondary.light,
                },
            },
            MuiSelect: {
                select: {
                    backgroundColor: Colors.black.calendar.body
                }
            }
        },
    },

    green:{
        overrides: {
            MuiPickersToolbar: {
                toolbar: {
                    backgroundColor: Colors.green.calendar.header,
                }
            },
            MuiPickerDTTabs: {
                tabs: {
                    backgroundColor: Colors.green.calendar.header,
                }
            },
            PrivateTabIndicator: {
                colorSecondary: {
                    backgroundColor: Colors.green.calendar.selected,
                }
            },
            MuiPickersClock: {
                pin: {backgroundColor: Colors.green.calendar.selected,}
            },
            MuiPickersClockPointer: {
                pointer: {
                    backgroundColor: Colors.green.calendar.selected,
                },
                noPoint: {
                    backgroundColor: Colors.green.calendar.selected,
                },
                thumb: {
                    borderColor: Colors.green.calendar.selected,
                }
            },
            MuiPickersClockNumber:{
                clockNumber: {
                    color: "currentColor",
                }
            },
            MuiPaper: {
                root: {
                    backgroundColor: Colors.green.calendar.body,
                    color: "#fff"
                }
            },
            MuiTypography: {
                body1: {
                    fontFamily: Fonts.primaryMedium,
                },
                body2: {
                    fontFamily: Fonts.primaryBook
                },
                caption: {
                    fontFamily: Fonts.primaryBook
                }
            },
            MuiPickersStaticWrapper: {
                staticWrapperRoot: {
                    backgroundColor: Colors.green.calendar.body,
                    minWidth: "280px"
                }
            },
            MuiPickersBasePicker: {
                pickerView: {
                    minWidth: "280px"
                }
            },
            MuiPickersCalendarHeader: {
                iconButton: {
                    backgroundColor: Colors.green.calendar.body,
                    color: "currentColor"
                },
                transitionContainer: {
                    color: "currentColor"
                },
                dayLabel: {
                    color: "currentColor"
                }
            },
            MuiPickersDay: {
                day: {
                    color: "currentColor"
                },
                daySelected: {
                    backgroundColor: Colors.green.calendar.selected,
                    borderRadius: 0,
                    "&:hover": {
                    backgroundColor: Colors.green.calendar.selected
                    }
                },
                current: {
                    color: Colors.green.calendar.selected
                },
                dayDisabled: {
                    color: Colors.green.disabled.main,
                }
            },
            MuiButton: {
                textPrimary: {
                    color: Colors.green.secondary.light,
                }
            },
            MuiInput: {
                root: {
                    border: "1px solid",
                    color: Colors.green.secondary.light,
                },
            }
        }
    }
};

export default MaterialTheme;
