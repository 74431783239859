import React, {useEffect, useContext, useState} from "react";
import Components from "../../Components"
import Atoms from "../../Atoms"
import Text from "../../Atoms/Text"
import {Card} from "../../Styles/Atoms/Card";
import Grid from '@material-ui/core/Grid';
import Provider, { ContextBase } from "../../Hooks";

export default (props:any) => {
  const com = useContext(ContextBase);

  const { marketView, clientView } = props;
  return   (
    <Grid container xs={12} >
      <Components.TopCardClient marketView={marketView} clientView={clientView} currencies={props.currencies} TopCardHeight display bold/>
      <Components.HeaderCardClient marketView={marketView} clientView={clientView} currencies={props.currencies} alignItems display labelCard bold /> 
      <Components.BodyCardClient marketView={marketView} clientView={clientView} fullHeight ProgressCard bodyCard />
      <Components.FooterCardClient marketView={marketView} clientView={clientView} thirdWidth active movilFullWidth twiceWidth/>
    </Grid>
    );
};
