import { Grid } from "@material-ui/core"
import { useState } from "react"
import Atoms from "../../../Atoms"
import Text from "../../../Atoms/Text";
import { ContentText } from "../../../Styles/Atoms/Text"
import { Box, Line } from "../../../Styles/Pages/Content/BodyCard"
import { FormatThousands } from "../../../Utils/Format";


const OrderDialog = (props: any) => {

    const [show, setShow] = useState(true);
    const [rate, setRate] = useState<string | number>();
    const [national, setNational] = useState<string | number>();
    const [showing, setShowing] = useState<string | number>();
    const [date, setDate] = useState<Date | null>(null);
    const [rangeA, setRangeA] = useState<number | string>();
    const [rangeB, setRangeB] = useState<number | string>();
    const [time, setTime] = useState<string | number>();

    const onInputChange = (input: string) => (event: any): void => {
        switch (input) {
            case "rate":
                setRate(FormatThousands(event.target.value));
                break;
            case "national":
                if (`${event.target.value}`.toLowerCase().includes("m")) {
                    setNational(
                        FormatThousands(
                            (parseInt(`${event.target.value}`.toLowerCase().replace("m", "").replace(",", "")) * 1000000).toString()
                        )
                    );
                } else {
                    setNational(FormatThousands(event.target.value));
                }
                break;
            case "showing":
                if (`${event.target.value}`.toLowerCase().includes("m")) {
                    setNational(
                        FormatThousands(
                            (parseInt(`${event.target.value}`.toLowerCase().replace("m", "").replace(",", "")) * 1000000).toString()
                        )
                    );
                } else {
                    setShowing(FormatThousands(event.target.value));
                }
                break;
            case "rangeA":
                if (event.target.value.replace("-", "").length <= 2)
                    setRangeA(event.target.value);

                break;
            case "rangeB":
                if (event.target.value.replace("-", "").length <= 2) {
                    setRangeB(event.target.value);
                }
                break;
            default:
        }
    }

    const send = () => {
        if (props.MidBid && (rangeA && rangeB) && rangeA > rangeB) {
            alert("Incorrect number range")
        } else {
            //props.handleClick({rate,national,showing,date,rangeA,rangeB,time});

            const obj = {
                client_order_id: Math.random(),
                total: national,
                showing: showing,
                field: 0,
                time: props.MidBid ? time : (date ? date.toLocaleString() : null),
                side: props.side
            }
            props.onSend(obj)
            props.onClose();
            clear();
        }
    }

    const clear = () => {
        setRate('')
        setNational('')
        setShowing('')
        setDate(null)
        setRangeA('')
        setRangeB('')
        setTime('')
        props.onClose();
    }

    return (
        <Atoms.Dialog
            open={props.open}
            showButton={show}
            buttonClose={clear}
        >
            <Atoms.Card
                HeaderCardHeight
            >
                <Grid container item xs={12} justifyContent="space-between">
                    <Grid item xs>
                        {props.MidBid ?
                            <Atoms.Text size="20" fontFamily="bold" titleCard textLeft>MidBid</Atoms.Text>
                            :
                            <Atoms.Text size="20" fontFamily="bold" titleCard textLeft>My Bid</Atoms.Text>
                        }

                    </Grid>
                    <Grid container item xs direction="row" justifyContent="flex-end">
                        <Box className="data">
                            <Atoms.Text block uppercase white>Bid</Atoms.Text>
                            <Atoms.Text size="20" fontFamily="secondary" textLeft>726.00</Atoms.Text>
                        </Box>
                        <Line />
                        <Box className="data">
                            <Atoms.Text block uppercase white>Offer</Atoms.Text>
                            <Atoms.Text size="20" fontFamily="secondary" textLeft>726.20</Atoms.Text>
                        </Box>
                    </Grid>
                </Grid>
            </Atoms.Card>

            <Atoms.Card
                bodyCard
                BodyCardHeight
            >
                <Grid container item>

                    <Grid item xs={12}>
                        <ContentText
                            {...props}
                            maxWidth="100%"
                        >
                            <Text size="22" fontFamily="book" textLeft white>
                                Rate:
                            </Text>
                            <Grid container item xs={7} spacing={1} justifyContent="flex-end">
                                <Grid item xs>
                                    <Atoms.Input
                                        type="text"
                                        w="100%"
                                        onChange={onInputChange('rate')}
                                        value={rate}
                                    />
                                </Grid>

                            </Grid>
                        </ContentText>
                        <ContentText
                            {...props}
                            maxWidth="100%"
                        >
                            <Text size="22" fontFamily="book" textLeft white>
                                Notional:
                            </Text>
                            <Grid container item xs={7} spacing={1} justifyContent="flex-end">
                                <Grid item xs>
                                    <Atoms.Input
                                        type="text"
                                        w="100%"
                                        onChange={onInputChange('national')}
                                        value={national}
                                    />
                                </Grid>
                            </Grid>
                        </ContentText>
                        <ContentText
                            {...props}
                            maxWidth="100%"
                        >
                            <Text size="22" fontFamily="book" textLeft white>
                                Show:
                            </Text>
                            <Grid container item xs={7} spacing={1} justifyContent="flex-end">
                                <Grid item xs>
                                    <Atoms.Input
                                        type="text"
                                        w="100%"
                                        onChange={onInputChange('showing')}
                                        value={showing}
                                    />
                                </Grid>
                            </Grid>
                        </ContentText>

                        {props.MidBid ?
                            <>
                                <ContentText
                                    {...props}
                                    maxWidth="100%"
                                >
                                    <Text size="22" fontFamily="book" textLeft white>
                                        Range:
                                    </Text>
                                    <Grid container item xs={7} spacing={1} direction="row" wrap="nowrap" justifyContent="flex-end">
                                        <Grid item>
                                            <Atoms.Input
                                                type="text"
                                                w="100%"
                                                onChange={onInputChange('rangeA')}
                                                value={rangeA}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Atoms.Input
                                                type="text"
                                                w="100%"
                                                onChange={onInputChange('rangeB')}
                                                value={rangeB}
                                                disabled={rangeA && parseInt(rangeA + "") ? false : true}
                                            />
                                        </Grid>
                                    </Grid>
                                </ContentText>
                                <ContentText
                                    {...props}
                                    maxWidth="100%"
                                >
                                    <Text size="22" fontFamily="book" textLeft white>
                                        Time:
                                    </Text>
                                    <Grid container item xs={7} spacing={1} justifyContent="flex-end">
                                        <Grid item xs>
                                            <Atoms.Input
                                                type="text"
                                                w="100%"
                                                onChange={(e: any) => setTime(e.target.value)}
                                                value={time}
                                            />
                                        </Grid>
                                    </Grid>
                                </ContentText>
                            </>
                            :
                            <ContentText
                                {...props}
                                maxWidth="100%"
                            >
                                <Text size="22" fontFamily="book" textLeft white>
                                    GTT:
                                </Text>
                                <Grid container item xs={7} spacing={1} justifyContent="flex-end">
                                    <Grid item xs>
                                        <Atoms.DateTimePicker
                                            value={date}
                                            onChange={setDate}
                                            disablePast={true}
                                        />
                                    </Grid>
                                </Grid>
                            </ContentText>
                        }
                    </Grid>
                </Grid>
            </Atoms.Card>

            <Atoms.Card
                FooterCardHeight
            >
                <Grid container item xs={12} alignItems="center" justify="space-between" direction="row" >
                    <Atoms.Button color="error" twiceWidth onClick={clear}>CANCEL</Atoms.Button>
                    <Atoms.Button color="success" twiceWidth onClick={send}>SEND</Atoms.Button>
                </Grid>
            </Atoms.Card>
        </Atoms.Dialog>
    )
}

export default OrderDialog