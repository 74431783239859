import { useState, useContext } from "react";
import { ContextBase } from "../../Hooks";
import { createTheme, makeStyles, ThemeProvider } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import MaterialTheme from "../../Styles/Constants/Materialtheme";

const useStyles = makeStyles({
    root: {
        '& input': {
            textAlign: "center",
        }
    },
});


export default (props: any) => {
    let com: any = useContext(ContextBase);
    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={createTheme(MaterialTheme[com.state.theme])}>
                <DatePicker
                    {...props}
                    disableToolbar
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true,
                        className: classes.root,
                    }}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
}
