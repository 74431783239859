import { Grid, Box } from "@material-ui/core";
import Atoms from "../../../Atoms"
import Components from "../../../Components";
import { Currency } from "../../../Models/Components/CardClient";


const AddNew = (props: any) => {

    return (
        <Atoms.Dialog
            open={props.open}
            showButton={true}
            buttonClose={props.onClose}
        >
            <Atoms.Card
                BodyCardHeight
            >
                <Grid alignItems="center" container spacing={2} direction="column">
                    <Grid item xs={12}>
                        <Atoms.Select
                            name="select"
                            select
                            display
                            minWidth="280px"
                            value={props.valueDateSpan}
                            onChange={props.onSetToday("selectDate")}
                        >
                            {props.presetSpanList &&
                                props.presetSpanList.length > 0 &&
                                props.presetSpanList.map(
                                    (span: string) => (
                                        <Atoms.Option value={span}>{span}</Atoms.Option>
                                    )
                                )}
                        </Atoms.Select>
                    </Grid>
                    <Grid item>
                        <Components.DatePicker
                            variant="static"
                            headerCard
                            dateFormat="dd/MM/yyyy"
                            value={props.valueDate}
                            onChange={props.onSetToday("valueDate")}
                            error={props.errors && props.errors.valueDate}
                            disablePast
                        />
                    </Grid>
                </Grid>
            </Atoms.Card>
            <Atoms.Card
                FooterCardAuto
            >
                <Grid container item xs={12} alignItems="center" justify="flex-end" direction="row" >
                    <Atoms.Button color="secondary" simple onClick={props.handleClick}>Add New</Atoms.Button>
                </Grid>
            </Atoms.Card>
        </Atoms.Dialog>
    )
}

export default AddNew