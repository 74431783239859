import { Grid } from "@material-ui/core";
import BodyCardEDF from "./Body";
import FooterCardEDF from "./footer";
import { Inner } from "../../Styles/Pages/Content";
import HeaderCard from "../HeaderCard";
import { useCallback, useContext, useState, useEffect } from "react";
import { ContextBase, SocketMethods } from "../../Hooks";
import { FormatThousands } from "../../Utils/Format";

//workaround, combo select is triggered twice onchange event
let isselect = 0;

const CardEDF = () => {
    const com: any = useContext(ContextBase);

    const normalSpans = ["TODAY", "TOM", "SN", "1W", "2W", "3W", "1M", "3M", "9M", "BMF"];
    const [currencies, setCurrencies] = useState<Array<string>>([]);
    const [data, setData] = useState(new Array())
    const [value, setValue] = useState('OUTRIGHT');
    const [currency, setCurrency] = useState<any>(0)
    const [presetSpanList, setPresetSpanList] = useState<Array<string>>(normalSpans);
    const [valueDate, setValueDate] = useState<number>();
    const [nearLeg, setNearLeg] = useState<number>();
    const [farLeg, setFarLeg] = useState<number>();
    const [valueDateSpan, setValueDateSpan] = useState<string>('');
    const [nearLegSpan, setNearLegSpan] = useState<string>('');
    const [farLegSpan, setFarLegSpan] = useState<string>('');
    const [notional, setNotional] = useState<string | number>();
    const [deleteRow, setDeleteRow] = useState<any>()
    const [requestType, setRequestType] = useState<string>();
    const [bid, setBid] = useState("-");
    const [offer, setOffer] = useState("-");
    const [timestamp, settimestamp] = useState("-");
    const [client_order_id, setclient_order_id] = useState(0);
    const [book, setBook] = useState<any>()

    const onToggle = (e: any, val: any) => {
        setValue(val)
    }

    const onInputChange = (input: string) => (event: any): void => {
        switch (input) {
            case "currency":
                setCurrency(event.target.value);
                break;
            case "notional":
                if (`${event.target.value}`.toLowerCase().includes("m")) {
                    setNotional(
                        FormatThousands(
                            (parseInt(`${event.target.value}`.toLowerCase().replace("m", "").replace(",", "")) * 1000000).toString()
                        )
                    );
                } else {
                    setNotional(FormatThousands(event.target.value));
                }
                break;
            case "1m":
                setNotional(FormatThousands("1000000"));
                break;
            case "5m":
                setNotional(FormatThousands("5000000"));
                break;
            case "10m":
                setNotional(FormatThousands("10000000"));
                break;
            case "valueDate":
                setValueDate(event.getTime());
                initSuscription(event.getTime());
                break;
            case "nearLeg":
                setNearLeg(event.getTime());
                initSuscription(event.getTime());
                break;
            case "farLeg":
                setFarLeg(event.getTime());
                break;
            default:
        }
    }

    const setDate = (input: string) => (event: any) => {
        if (!presetSpanList[parseInt(event.target.value)]) {
            return;
        }
        const selected: string = presetSpanList[parseInt(event.target.value)];

        const isSetteable: boolean = !["TOM", "SN", "BMF"].includes(selected);

        switch (input) {
            case "valueDate":
                if (isselect == 2 && valueDateSpan !== event.target.value)
                    isselect = 0
                isselect = isselect + 1;
                setValueDateSpan(event.target.value);
                if (isSetteable) {
                    setValueDate(getPeriodSpan(selected));
                    initSuscription(getPeriodSpan(selected), selected);
                }

                break;
            case "nearLeg":
                if (isselect == 2 && valueDateSpan !== event.target.value)
                    isselect = 0
                isselect = isselect + 1;
                setNearLegSpan(event.target.value);
                if (isSetteable) {
                    setNearLeg(getPeriodSpan(selected));
                    initSuscription(getPeriodSpan(selected), selected);
                }
                break;
            case "farLeg":
                setFarLegSpan(event.target.value);
                if (isSetteable) {
                    setFarLeg(getPeriodSpan(selected));
                }
                break;
            default:
                break;
        }
        //setErrors({ ...errors, [input]: false });
    };

    const getPeriodSpan = (spanString: string): number => {
        const now: Date = new Date();
        let span: number;

        switch (spanString) {
            case "TODAY":
                now.getTime();
                break;
            case "1W":
                now.setDate(now.getDate() - 7);
                break;
            case "2W":
                now.setDate(now.getDate() - 14);
                break;
            case "3W":
                now.setDate(now.getDate() - 21);
                break;
            case "1M":
                now.setMonth(now.getMonth() - 1);
                break;
            case "3M":
                now.setMonth(now.getMonth() - 3);
                break;
            case "9M":
                now.setMonth(now.getMonth() - 9);
                break;
            case "TOM":
                break; // ANY DATE | EDGEWATER'll IMPLEMENT
            case "SN":
                break; // ANY DATE | EDGEWATER'll IMPLEMENT
            case "BMF":
                break; // ANY DATE | EDGEWATER'll IMPLEMENT
            default:
                break;
        }

        span = now.getTime();
        return span;
    };

    const initSuscription = (date: number, datespan?: string) => {
        if (isselect === 1) {
            const pair: any = currencies.find((e: any) => e.instrument_id === parseInt(currency))
            let request: any = {
                "streamType": value,
                "instrumentId": parseInt(currency),
                "instrumentPair": pair ? pair.symbol : "",

                // "instrument_id": parseInt(currency),
                // "amount": notional,
                // "currency": pair ? pair.symbol : "",
                // "type": value,
                // "side": requestType,
                // "status": "WAIT",
                // "currencyFrom": 1,
                // "currencyTo": 0,
                // "currencyFromLabel": currency,
                // "currencyToLabel": "",
                // "RFQType": value,
                // "requestMode": requestType,
            }
            if (value === "OUTRIGHT") {
                request = {
                    ...request,
                    date,
                    tenor: datespan
                }
                SocketMethods.rfsSubscribe(request);
            } else if (value === "SWAP") {
                const tenorFarLeg: string = presetSpanList[parseInt(farLegSpan)];
                request = {
                    ...request,
                    dateFar: farLeg,
                    date,
                    tenor: datespan,
                    tenorFarLeg,
                    "clientRequestId": null
                }
            }
        }
    }

    const send = useCallback((obj) => {
        const newStore = data.slice();
        newStore.push(obj)
        setData(newStore);
        console.log(obj)
        const pair: any = currencies.find((e: any) => e.instrument_id === parseInt(currency))

        const reference_book = {
            ...com.state.BOOK_UPDATE,
            timestamp: new Date().getTime(),
            client_request_id: com.state.RFQ_UPDATE.client_request_id,
            is_swap: value === "SWAP" ? "1" : "0"
        }
        SocketMethods.rfsNewOrder({
            referencePrice: notional,
            instrument_id: currency,
            amount: obj.total,
            side: obj.side,
            msg_type: "ORDER_NEW",
            type: value,
            currency: pair ? pair.symbol : "",
            reference_book
            //TODO --------------
            // rate,
            // show,
            // GTT,
            // isMidBid,
            // range,
            // time
        })
    }, [data, currency, notional])

    useEffect(() => {
        if (deleteRow) {
            const newStore = data.slice();
            setData(newStore.filter(e => e.client_order_id !== deleteRow.client_order_id));
            SocketMethods.rfsCancelOrder({
                msg_type: 'ORDER_REPLACE',
                is_cancel: 1,
                client_order_id: deleteRow.client_order_id
            })
        }
    }, [deleteRow])

    useEffect(() => {
        setCurrencies(com.state.instruments);
    }, [com.state.instruments]);

    useEffect(() => {
        if (com.state.RFQ_UPDATE.asks) {
            const ask = com.state.RFQ_UPDATE.asks;
            const bid = com.state.RFQ_UPDATE.bids
            if (ask[0] && bid[0]) {
                setBid(ask[0].price);
                setOffer(bid[0].price);
                settimestamp(com.state.RFQ_UPDATE.timestamp)
            }
            //setclient_order_id(com.state.RFQ_UPDATE.client_request_id)
        }
    }, [com.state.RFQ_UPDATE]);

    useEffect(() => {
        if (com.state.RFQ_ORDER_UPDATE) {

        }
    }, [com.state.RFQ_ORDER_UPDATE]);



    const requestAsProps = {
        value,
        currencies,
        valueDate,
        valueDateSpan,
        notional,
        nearLeg,
        farLeg,
        nearLegSpan,
        farLegSpan,
        currency,
        data,
        requestType,
        presetSpanList,
        bid,
        offer,
        timestamp
    }

    return (
        <Grid container style={{ height: "100%", overflowX: "auto" }}>
            <Grid item xs={12} style={{ height: "100%" }}>
                <Inner>
                    <HeaderCard
                        onInputChange={onInputChange}
                        onSetToday={setDate}
                        onToggle={onToggle}
                        {...requestAsProps}
                    />
                    <BodyCardEDF
                        onInputChange={onInputChange}
                        {...requestAsProps}
                        onSend={send}
                        onRemove={setDeleteRow}
                    />
                    <FooterCardEDF />
                </Inner>
            </Grid>
        </Grid>
    )
}


export default CardEDF