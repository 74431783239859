import React, { useEffect, useContext, useState } from "react";
import "../src/assets/fonts/stylesheet.css";
import "./App.css";
import Fonts from "./Styles/Constants/FontSize";
import Provider from "./Hooks";
import Router from "./Router";

const App = () => {
  // sessionStorage.setItem('key','no')

  useEffect(() => {
    const userRole = localStorage.getItem("rfq_user_role");

    if (!userRole) {
      localStorage.setItem("rfq_user_role", "USER");
      const TakerToken =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NTYzNTMzODAsImlhdCI6MTY1NTkyMTM4MDQzMSwiZW1haWwiOiJGaW5hbmNpYWxUYWtlckAxMjMuY29tIiwiaWRfdXNlciI6Mjk3LCJ1c2VybmFtZSI6IkZpbmFuY2lhbFRha2VyIiwiYWRtaW5fdG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKZmFXUWlPakk1Tnl3aWRYTmxjbTVoYldVaU9pSkdhVzVoYm1OcFlXeFVZV3RsY2lJc0ltVjRjQ0k2TVRZMU1ETXdNemc0TVM0M016a3NJbWxoZENJNk1UWTBOVEV5TXpRNE1YMC5GcWx2T2hJYWlyVF9HcGhkVjNyMFJ5b3pzVklrdExySlNyeGZoMkhDTmdvIn0.guuIcXGPCosBvPusralT9rMkR3-WAcPadxROeQvbPwQ";


      localStorage.setItem("rfq_user_token", TakerToken);
    }

    const themeDefault = localStorage.getItem("rfq_theme_default");
    if (!themeDefault) {
      localStorage.setItem("rfq_theme_default", "black");
    }
  }, []);
  // instruction to use the widget
  // Allways implement the provider before inject the component for use 
  return (
    <Provider>
      <Router />
    </Provider>
  );
};

export default App;
