import { Colors, FontSize, Fonts } from "../../Constants";
import Styled, {css} from "styled-components";
import { DatePicker } from "@material-ui/pickers";


const ContentInput = Styled.div<any>`
  display: ${props => props.display ? "inline-block" : "contents"};
  flex-direction: ${props => props.column ? "column" : "row"};
  align-items: ${props => props.column ? "flex-start" : "flex-end"};
  color: ${props => Colors[props.theme].primary.light};
  ${FontSize.sz14};

  .react-datepicker-wrapper {
    width: ${props => props.width ? props.width : "calc(100% - 110px)"};
  }

  .react-datepicker-popper{
    position: fixed !important;
    width: 244px;
    height: 237px;
    display: block;

    @media screen and (max-width:561px){
      transform:translate3d(30%,70%,0px)!important;
    }
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
    border-bottom-color:  ${props => Colors[props.theme].calendar.header};
    display: none;
  }

  .react-datepicker__header{
    background-color:  ${props => Colors[props.theme].calendar.header}!important;
    .react-datepicker__current-month{
      color: ${props => Colors[props.theme].calendar.text };
    }
    .react-datepicker__day-name{
      color: ${props => Colors[props.theme].calendar.text };
    }
  }

  .react-datepicker{
    background-color:  ${props => Colors[props.theme].calendar.body}!important;
  }

  .react-datepicker__day{
    color: ${props => Colors[props.theme].calendar.text };
    &:hover{
      background-color:  ${props => Colors[props.theme].calendar.hover}!important;
    }
  }

  .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected{
    background-color: ${props => Colors[props.theme].calendar.selected } !important;
  }

  .react-datepicker-wrapper{
    ${props => props.headerCard && css`
      max-width: 140px !important;
    `}
  }
`;

const DatePickerInput = Styled.div<any>`
  border: 1px solid;
  cursor: pointer;
  border-color:  ${props => {
    if (props.error) {
      return "#ff695d";
    }
    return Colors[props.theme].background.light;
  }};
  border-radius: ${props => props.rounded ? "4px" : "0px 4px 4px 0px"};
  background-color:  ${props => {
    if (props.error) {
      return "#ffd8d5";
    }

    if (props.disabled) {
      return Colors[props.theme].disabled.light;
    }

    return Colors[props.theme].background.input;
  }};
  color: ${props => Colors[props.theme].primary.textInput };
  height: 36px;
  padding: 0 5px;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  ${FontSize.sz20};
  text-transform: uppercase;
  font-family: ${Fonts.secondary};

  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: flex-end;

  &:focus{
    outline: none !important;
  }
`;

const DatePickerIcon = Styled.div<any>`
  display:flex;
  margin-left: 5px;
  vertical-align: middle !important;
  @media screen and (max-width:390px){
    display:none;
  }
`;

const DatePikcerInput = Styled(DatePicker)`
  height: 100%;

  .MuiInput-root {
    border: 1px solid ${(props) => Colors[props.theme].gray.main};
    background: transparent;
    color: currentColor;
    font-family: ${Fonts.primaryBook};
    font-size: inherit;
    height: 100%;
    padding-left: 6px;
    
    input {
      cursor: pointer;
    }
  }
  
  [class$="root"] {
    background: transparent;
    color: currentColor;
    font-size: 1.25rem;
  }
`

export {ContentInput, DatePickerInput, DatePickerIcon, DatePikcerInput};
