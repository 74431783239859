import React, { useContext, useEffect } from "react"
import Components from "../../Components";
import { ContextBase,SocketMethods } from "../../Hooks";
import { Content } from "../../Styles/Pages/Content"

const IMM = () => {
    
    const com: any = useContext(ContextBase);

    useEffect(() => {
      SocketMethods.rfsUpdateSubscription(com.state, com.dispatch);
      if (localStorage.getItem("rfq_theme_default")) {
        com.dispatch({
          type: "CHANGE_THEME_MODE",
          payload: { theme: localStorage.getItem("rfq_theme_default") },
        });
      }
    }, []);

    return (
        <Content theme={com.state.theme}>
            <Components.CardImm />
        </Content>
    )
}

export default IMM