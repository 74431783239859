import React from "react";
import { normalize } from "../../Constants/Config";
import { Colors, FontSize, Fonts } from "../../Constants";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import Styled from "styled-components";

const Box = Styled.div`
`;

const AgGridReactStyle = Styled(AgGridReact) <any>`
  background-color: transparent !important;
  width: calc(100% - 0px) !important; 
  overflow-x:auto;
  font-family:${Fonts.primaryMedium};
  .ag-root-wrapper{
    background-color: transparent !important;
    border: 0 !important;
  }
  .ag-header{
    background-color: ${props => Colors[props.theme].background.headerTable} !important;
    margin-bottom: 8px;
    border: 1px solid;
    border-radius: 0px;
    border-color: ${props => Colors[props.theme].border.main} !important;
    // height: 50px !important;
    // min-height: 50px !important;
    box-sizing: border-box;
    text-transform: uppercase !important;
    
  }
  .ag-header-cell{
    /*padding: 0 !important;*/
    // height: 43px !important;
    // min-height: 43px !important;
    .ag-header-cell-label {
      display:flex;
      justify-content: space-between;
    }
  }
  .ag-header-cell-label{
    justify-content: flex-start;
  }
  .ag-react-container{
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
  .ag-header-row{
    ${FontSize.sz14};
    ${FontSize.header};
    font-family:${Fonts.primaryBook};
    height: 50px !important;
  }
  .ag-row{
    font-family:${Fonts.primaryBook};
    background-color: transparent;
    border: 1px solid;
    border-radius: 0px;
    border-color: transparent !important;
    box-sizing: border-box;
    & .ag-cell-wrapper:first-child{
      justify-content: flex-start !important;
    }

    &-odd {
      background-color: ${props => Colors[props.theme].background.body1};
    }
  }
  .ag-cell-focus{
    border-color: transparent !important;
    box-shadow: none;
    outline: none !important;
  }
  .ag-cell-wrapper{
    justify-content: center !important;
    align-items: center !important;
    .ag-row-drag{
      margin-right: 6px !important;
    }
  }
  
  .ag-cell-edit-wrapper,
  .ag-cell-inline-editing {
    background: transparent;
    border-color: transparent !important;
  }

  .ag-select .ag-picker-field-wrapper {
    background: transparent;
    border-color: ${(props) => Colors[props.theme].gray.main};
    border-radius: 0;
  }

  input[class^='ag-']:not([type]), 
  input[class^='ag-'][type='text'], 
  input[class^='ag-'][type='number'], 
  input[class^='ag-'][type='tel'], 
  input[class^='ag-'][type='date'], 
  input[class^='ag-'][type='datetime-local'], 
  textarea[class^='ag-'] {
    background: transparent;
    border-color: ${(props) => Colors[props.theme].gray.main};
    border-radius: 0;
    font-family: inherit;

    &:focus {
      border-color: ${(props) => Colors[props.theme].gray.main} !important;
      box-shadow: none
    }
  }
  .ag-center-cols-container{
    width: 100% !important; 
  }

`;
const AgGridColumnStyle = Styled(AgGridColumn)`
  background-color: transparent !important;
  color: red;
`;

const TableWrapper = Styled.div<any>`
background-color:${props => Colors[props.theme].background.bodyTable};
height: ${props => props.height ? props.height : "calc(100vh - 44px)"};
width: calc(100% - 0px) !important; 
margin: 0 auto;
border: none;
border-radius: 0px;
border-color: #ACACAC !important;
scrollbar-color: ${props => Colors[props.theme].background.scrollFront} ${props => Colors[props.theme].background.scroll};
scrollbar-width: thin;

::-webkit-scrollbar, * ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb, * ::-webkit-scrollbar-thumb {
  background:  ${props => Colors[props.theme].background.scrollFront};
}

::-webkit-scrollbar-track, * ::-webkit-scrollbar-track {
  background: ${props => Colors[props.theme].background.scroll};
}
  &.ag-theme-alpine-dark {
    >div.noinfo{
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      vertical-align: middle;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      height: 100%;
      width: 240px;
      margin: 0 auto;
      text-align: center;
      font-family:${Fonts.primaryMedium};
    }
    .ag-icon-asc:before {
      content: "\f12f" !important;
    }
    .ag-icon-desc:before {
      transform: rotate(270deg)!important;
      display:block;
      content: "" !important;
    }
    .ag-checkbox-input-wrapper{
      ${FontSize.sz12};
      background-color: transparent !important;
    }
    .ag-checkbox-input-wrapper.ag-checked::after {
      color: #E96815 !important;
      ${FontSize.sz12};
      outline:none;
  }
  .ag-checkbox-input-wrapper:focus-within{
    box-shadow:none !important;
  }
    .row-highlight-new {
      color:${props => Colors[props.theme].primary.invert};
      div.ag-cell{
        -webkit-animation: heartbeat 1.3s linear infinite alternate both;
        -moz-animation: heartbeat 1.3s linear infinite alternate both;
        -o-animation: heartbeat 1.3s linear infinite alternate both;
        animation: heartbeat 1.3s linear infinite alternate both;
        @-webkit-keyframes heartbeat {
          0% {
            /* -webkit-transform: scale(1);
                    transform: scale(1); */
                    opacity: 1;
          }
          50% {
            /* -webkit-transform: scale(0.9);
                    transform: scale(0.9); */
                    opacity: 0.5;
          }
          100% {
            /* -webkit-transform: scale(1);
                    transform: scale(1); */
                    opacity: 1;
          }
        }
        @keyframes heartbeat {
          0% {
            /* -webkit-transform: scale(1);
                    transform: scale(1); */
                    opacity: 0.5;
          }
          50% {
            /* -webkit-transform: scale(0.9);
                    transform: scale(0.9); */
                    opacity: 1;
          }
          100% {
            /* -webkit-transform: scale(1);
                    transform: scale(1); */
                    opacity: 0.5;
          }
        }
        input{
            -webkit-animation: vibrate-1 1s linear 0 both;
                    animation: vibrate-1 1s linear 0 both;
                    @-webkit-keyframes vibrate-1 {
                      0% {
                        -webkit-transform: translate(0);
                                transform: translate(0);
                      }
                      20% {
                        -webkit-transform: translate(-2px, 2px);
                                transform: translate(-2px, 2px);
                      }
                      40% {
                        -webkit-transform: translate(-2px, -2px);
                                transform: translate(-2px, -2px);
                      }
                      60% {
                        -webkit-transform: translate(2px, 2px);
                                transform: translate(2px, 2px);
                      }
                      80% {
                        -webkit-transform: translate(2px, -2px);
                                transform: translate(2px, -2px);
                      }
                      100% {
                        -webkit-transform: translate(0);
                                transform: translate(0);
                      }
                    }
                    @keyframes vibrate-1 {
                      0% {
                        -webkit-transform: translate(0);
                                transform: translate(0);
                      }
                      20% {
                        -webkit-transform: translate(-2px, 2px);
                                transform: translate(-2px, 2px);
                      }
                      40% {
                        -webkit-transform: translate(-2px, -2px);
                                transform: translate(-2px, -2px);
                      }
                      60% {
                        -webkit-transform: translate(2px, 2px);
                                transform: translate(2px, 2px);
                      }
                      80% {
                        -webkit-transform: translate(2px, -2px);
                                transform: translate(2px, -2px);
                      }
                      100% {
                        -webkit-transform: translate(0);
                                transform: translate(0);
                      }
                    }
                    
        }
        
        svg{
          -webkit-animation: scale-down-center 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
	        animation: scale-down-center 0.9s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
          @-webkit-keyframes scale-down-center {
            0% {
              -webkit-transform: scale(1);
                      transform: scale(1);
            }
            100% {
              -webkit-transform: scale(0.5);
                      transform: scale(0.5);
            }
          }
          @keyframes scale-down-center {
            0% {
              -webkit-transform: scale(1);
                      transform: scale(1);
            }
            100% {
              -webkit-transform: scale(0.5);
                      transform: scale(0.5);
            }
          }
          
          }
        }
      }
    }
    
  &.ag-theme-alpine {
    .row-highlight-new {
      color: black;
    }
  }
  .row-highlight-dealing {
    color: #bb602b;
  }
  .row-highlight-rejected,
  .row-highlight-user-cancel,
  .row-highlight-trader-cancel {
    color: #676668;
    .cell-icon {
      color: red;
    }
  }
  .row-highlight-done {
    color: #4d9c39;
  }
`;

const CellIcon = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 0;

  .cell-icon {
    font-size:1.3rem;
    margin-right:0.5rem;
  }
`
const Component = (props: any) => {
  return <Box >Box</Box>;
};

export default Component;
export { TableWrapper, CellIcon, AgGridReactStyle, AgGridColumnStyle };
