import React from "react";
import { useState, useContext } from "react";
import Atoms from "../../Atoms";
import { Components } from "../../Styles";
import { Context } from "../../Hooks";
const ComponetDummy: React.FC = () => (
  <span>
    <button>up</button>
    <button>right</button>
    <button>left</button>
    <button>down</button>
  </span>
);

const Componet1 = (props: any) => {
  const [con, setCon] = useState();
  return (
    <>
      {/* <Atoms.Atom1 theme={props.state.theme} /> */}
    </>
  );
};

export default Context(Componet1);
export { ComponetDummy };
