import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


export default function Alert(props: any) {
  const onClose = (event: any) =>{
    props.onClose();
  }
  return (
    <Snackbar 
      style={{zIndex: 9999}}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      key={'topcenter'} 
      open={props.open} 
      autoHideDuration={5000} 
      onClose={onClose}
    >
      <MuiAlert elevation={6} variant="filled" severity={props.severity}>
        {props.children}
      </MuiAlert>
    </Snackbar>
  );
}