import React, { useContext,forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ContextBase } from "../../Hooks";
import {ContentInput, DatePickerInput, DatePickerIcon} from "../../Styles/Atoms/DatePicker";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

const DatePickerComponent = (props:any) => {
  let com: any = useContext(ContextBase);
  const { error } = props;
  const CustomInput = forwardRef((props: any, ref: any) => {
    return (
      <DatePickerInput {...props}  theme={com.state.theme} error={error} rounded={props.rounded}>
        <label onClick={props.onClick} ref={ref}>
          {props.value || props.placeholder}
        </label>
        <DatePickerIcon onClick={props.onClick} ref={ref}>
          <CalendarTodayIcon />
        </DatePickerIcon>
      </DatePickerInput>
    )
  })
  return (
    <ContentInput {...props} theme={com.state.theme}  error={error} rounded={props.rounded}>
      <DatePicker {...props} theme={com.state.theme}customInput={<CustomInput rounded={props.rounded}/>} 
      popperPlacement="top-end"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: "0px, 0px"
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: "viewport"
        }
      }}/>
    </ContentInput>
  );
};
export default DatePickerComponent;