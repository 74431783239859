import { Box, Grid } from "@material-ui/core"
import { Settings } from "@material-ui/icons"
import React from "react"
import Atoms from "../../Atoms"
import { ToggleButtonGorupStyle } from "../../Styles/Atoms/Button"
import { ContentButton, HeaderContent, TopBar } from "../../Styles/Components/HeaderCard"
import { Currency } from "../../Models/Components/CardClient";
import { SortBy } from "../../Utils/Sort";

const HeaderCard = (props: any) => {

    return (
        <Atoms.Card
            HeaderCardHeight
        >
            <HeaderContent>
                <TopBar>
                    <ToggleButtonGorupStyle size="small" exclusive
                        value={props.value} onChange={props.onToggle}
                    >
                        <Atoms.ToggleButton value="OUTRIGHT">
                            FWD
                        </Atoms.ToggleButton>
                        <Atoms.ToggleButton value="SWAP">
                            SWP
                        </Atoms.ToggleButton>
                    </ToggleButtonGorupStyle>

                    <Atoms.Select name="select" alignItems display simple lg value={props.currency} onChange={props.onInputChange("currency")}>
                        <Atoms.Option value={0} key={0}>Instrument</Atoms.Option >
                        {(props.currencies && props.currencies.length > 0) &&
                            props.currencies.sort(SortBy)
                                //  props.currencies//no sort for specific id
                                .map((currency: any, curIdx: any): any => (
                                    <Atoms.Option key={curIdx} value={currency.instrument_id}>{currency.symbol}</Atoms.Option >
                                ))}
                    </Atoms.Select>
                    <ContentButton width>
                        <Atoms.Text size="24" fontFamily="bold">FIX</Atoms.Text>
                        <Atoms.Button Icon simple>

                            <Settings />
                        </Atoms.Button>
                        
                    </ContentButton>
                </TopBar>


                {props.currency && props.value === "OUTRIGHT" &&
                    <ContentButton justifyContent="flex-end">

                        <Box className="content-select">
                            <Atoms.Text block size="24" white>Date:</Atoms.Text>
                            <Box>
                                <Atoms.Select
                                    name="select"
                                    column
                                    display
                                    value={props.valueDateSpan}
                                    onChange={props.onSetToday("valueDate")}
                                    disable={props.currency===0?true:false}
                                >
                                    <Atoms.Option value={null}></Atoms.Option>
                                    {props.presetSpanList &&
                                        props.presetSpanList.length > 0 &&
                                        props.presetSpanList.map(
                                            (span: Currency, spanIdx: number) => (
                                                <Atoms.Option value={spanIdx} key={spanIdx}>{span}</Atoms.Option>
                                            )
                                        )}
                                </Atoms.Select>
                                <Atoms.DatePicker
                                    headerCard
                                    dateFormat="dd/MM/yyyy"
                                    selected={props.valueDate}
                                    onChange={props.onInputChange("valueDate")}
                                    error={props.errors && props.errors.valueDate}
                                    minDate={new Date()}
                                    disable={props.currency===0?true:false}
                                />
                            </Box>
                        </Box>
                    </ContentButton>
                }

                {props.currency && props.value === "SWAP" &&
                    <ContentButton>

                        <Box className="content-select">
                            <Atoms.Text block size="24" white>NEAR LEG:</Atoms.Text>
                            <Box>
                                <Atoms.Select
                                    name="select"
                                    column
                                    display
                                    value={props.nearLegSpan}
                                    onChange={props.onSetToday("nearLeg")}
                                >
                                    <Atoms.Option value={null} key={0}></Atoms.Option>
                                    {props.presetSpanList &&
                                        props.presetSpanList.length > 0 &&
                                        props.presetSpanList.map(
                                            (span: Currency, spanIdx: number) => (
                                                <Atoms.Option value={spanIdx} key={spanIdx}>{span}</Atoms.Option>
                                            )
                                        )}
                                </Atoms.Select>
                                <Atoms.DatePicker
                                    headerCard
                                    dateFormat="dd/MM/yyyy"
                                    selected={props.nearLeg}
                                    onChange={props.onInputChange("nearLeg")}
                                    minDate={new Date()}
                                />
                            </Box>
                        </Box>
                        <Atoms.Text size="24" fontFamily="bold">FIX</Atoms.Text>
                        <Box className="content-select">
                            <Atoms.Text block size="24" white>FAR LEG:</Atoms.Text>
                            <Box>
                                <Atoms.Select
                                    name="select"
                                    column
                                    display

                                    value={props.nearLegSpan}
                                    onChange={props.onSetToday("nearLeg")}
                                    disable={props.currency===0?true:false}

                                >
                                    <Atoms.Option value={null}></Atoms.Option>
                                    {props.presetSpanList &&
                                        props.presetSpanList.length > 0 &&
                                        props.presetSpanList.map(
                                            (span: Currency, spanIdx: number) => (
                                                <Atoms.Option value={spanIdx} key={spanIdx}>{span}</Atoms.Option>
                                            )
                                        )}
                                </Atoms.Select>
                                <Atoms.DatePicker
                                    headerCard
                                    dateFormat="dd/MM/yyyy"
                                    selected={props.farLeg}
                                    onChange={props.onInputChange("farLeg")}
                                    minDate={new Date()}
                                    disable={props.currency===0?true:false}
                                />
                            </Box>
                        </Box>
                    </ContentButton>
                }
            </HeaderContent>
        </Atoms.Card>
    )
}

export default HeaderCard