import styled from "styled-components";

const HeaderContent = styled.div<any>``;

const TopBar = styled.div<any>` 
    align-items: flex-start;
    display: flex;
    grid-gap: 10px;
    justify-content: space-between;
    flex-flow: row wrap;
    /* margin-bottom: 1em; */
    & > div{
        flex: 1;
    }
`;

const ContentButton = styled.div<any>`
    display: flex;
    justify-content: ${props => props.justifyContent ? props.justifyContent : "space-between"};
    align-items: ${props => props.align};
    flex-flow: row wrap;
    width: 100%;

    @media (min-width: 678px) {
        min-width: 250px;
        width: inherit;
    }

    .content-select {
        min-width: 250px;
    }
`;

const ContentSelect = styled.div<any>`
`;

export {HeaderContent, TopBar, ContentButton}