import React, { useContext, useState, useEffect, useCallback } from "react"
import Components from "../../Components";
import { ContextBase,SocketMethods } from "../../Hooks";
import { Content } from "../../Styles/Pages/Content";
import { FormatThousands } from '../../Utils/Format'
import Atoms from '../../Atoms'
import button from "./customHeader/button";

const aRowEdit = [
  {
    client_order_id: 0,
    typeA: "Next OBS",
    typeB: "Next OBS",
    dateA: "2021/08/15",
    dateB: "2021/08/15",
    fixingA: "2021/08/15",
    fixingB: "2021/08/15",
    notional: "00,000,000",
    filled: "00,000,000",
    myBid: 26.00,
    bid: 26.00,
    offer: 26.20,
    myOffer: 26.20,
    filledB: "00,000,000",
    notionalB: "00,000,000",
  }
]

const Matrix = () => {

  const ctx: any = useContext(ContextBase);

  useEffect(() => {
    if (localStorage.getItem("rfq_theme_default")) {
      ctx.dispatch({
        type: "CHANGE_THEME_MODE",
        payload: { theme: localStorage.getItem("rfq_theme_default") },
      });
    }
  }, []);

  const [rows, setRows] = useState<Array<any>>(new Array());
  const [rowEdit, setRowEdit] = useState<Array<any>>(aRowEdit)
  const [newRow, setNewRow] = useState<any>()
  const [deleteRow, setDeleteRow] = useState<any>()

  const [warningDialog, setwarningDialog] = useState({ open: false, message: "", type: "" });

  const onBtnAdd = (e: any) => {
    setNewRow(e)
  }

  const onBtnCancel = (e: any) => {
    setDeleteRow(e)
    setwarningDialog({ open: true, message: "The transaction has been cancelled", type: "error" })
  }

  const onBtnClear = () => {
    setRows([])
  };

  const [aColumns, setCol] = useState<Array<any>>([
    {
      headerName: "Near LEG",
      minWidth: "140",
      field: "typeA",
      resizable: true,
      flex: 1
    },
    {
      headerName: "FAR LEG",
      field: "typeB",
      minWidth: "140",
      resizable: true,
      flex: 1
    },
    {
      headerName: "value date a",
      field: "dateA",
      minWidth: "140",
      resizable: true,
      flex: 1
    },
    {
      headerName: "value date a",
      field: "dateB",
      minWidth: "140",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Fixing A",
      field: "fixingA",
      minWidth: "140",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Fixing B",
      field: "fixingB",
      minWidth: "140",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "notional",
      field: "notional",
      minWidth: "140",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
    },
    {
      headerName: "Filled",
      field: "filled",
      minWidth: "140",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
    },
    {
      headerName: "my Bid",
      field: "myBid",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Bid",
      field: "bid",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "offer",
      field: "offer",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "My offer",
      field: "myOffer",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Filled",
      field: "filledB",
      minWidth: "140",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
    },
    {
      headerName: "Notional",
      field: "notionalB",
      minWidth: "140",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
    },
    {
      headerName: "",
      field: "action",
      minWidth: "120",
      resizable: true,
      flex: 1,
      cellRenderer: "cellButtonRenderer",
      cellRendererParams: {
        clicked: onBtnCancel,
      },
      headerComponent: button,
      headerComponentParams: {
        clicked: onBtnClear
      }
    },
  ]) ;

  const TableEdit = [
    {
      headerName: "Near LEG",
      minWidth: "140",
      field: "typeA",
      resizable: true,
      flex: 1,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['TD', 'Toms OBS', '1m', '2m', '6m', '12m'],
      },
    },

    {
      headerName: "Far Leg",
      field: "typeB",
      minWidth: "140",
      resizable: true,
      flex: 1,
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['Next OBS', 'Toms OBS', '1m', '2m', '6m', '12m'],
      },
    },
    {
      headerName: "Value Date A",
      field: "dateA",
      minWidth: "140",
      resizable: true,
      flex: 1,
      editable: true,
      cellEditor: "datePickerRenderer",
      cellEditorParams: {
        disablePast: true
      }
    },
    {
      headerName: "Value Date B",
      field: "dateB",
      minWidth: "140",
      resizable: true,
      flex: 1,
      editable: true,
      cellEditor: "datePickerRenderer",
      cellEditorParams: {
        disablePast: true
      }
    },
    {
      headerName: "Fixing A",
      field: "fixingA",
      minWidth: "140",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Fixing B",
      field: "fixingB",
      minWidth: "140",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Notional",
      field: "notional",
      minWidth: "140",
      resizable: true,
      flex: 1,
      editable: true,
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
    },
    {
      headerName: "Filled",
      field: "filled",
      minWidth: "140",
      resizable: true,
      flex: 1,
      notional: "00,000,000",
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
      editable: true,
    },
    {
      headerName: "My Bid",
      field: "myBid",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Bid",
      field: "bid",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Offer",
      field: "offer",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "My Offer",
      field: "myOffer",
      minWidth: "100",
      resizable: true,
      flex: 1,
    },
    {
      headerName: "Filled",
      field: "filledB",
      minWidth: "140",
      flex: 1,
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
      editable: true,
    },
    {
      headerName: "Notional",
      field: "notionalB",
      minWidth: "140",
      resizable: true,
      flex: 1,
      valueFormatter: (params: any) => `$${FormatThousands(params.value)}`,
      editable: true,
    },
    {
      headerName: "",
      field: "action",
      minWidth: "120",
      resizable: true,
      flex: 1,
      cellRenderer: "cellButtonAddRender",
      cellRendererParams: {
        clicked: onBtnAdd,
      }
    },
  ];

  const onCellEdited = useCallback((event) => {
    const oldData = event.data;
    const field = event.colDef.field;
    const newValue = event.newValue;
    const newData = { ...oldData };
    newData[field] = event.newValue;
    console.log('onCellEditRequest, updating ' + field + ' to ' + newValue);
    setRowEdit([newData])
    // const tx = {
    //   update: [newData],
    // };
    // event.api.applyTransaction(tx);
    },[]);

  useEffect(() => {
    if (newRow) {
      const newStore = rows.slice();
      newStore.push({ ...newRow, client_order_id: Math.random() });
      setRows(newStore);
      setNewRow(undefined);
    }
  }, [newRow]);

  useEffect(() => {
    if (deleteRow) {
      const newStore = rows.slice();
      setRows(newStore.filter(e => e.client_order_id !== deleteRow.client_order_id));
    }
  }, [deleteRow])

  return (
    <Content
      display="flex"
      flexDirection="column"
      theme={ctx.state.theme}
    >
      <Components.Table
        rows={rows}
        columns={aColumns}
        height="80%"
      />

      <Components.Table
        rows={rowEdit}
        columns={TableEdit}
        height="20%"
        onCellEdited={onCellEdited}
      //editType={'fullRow'}
      />
      <div>
        <Atoms.Alert
          open={warningDialog.open}
          severity={warningDialog.type}
          onClose={() => setwarningDialog({ open: false, message: "", type: "" })}
        >
          <Atoms.Text size="22" textLeft labelCard>
            {warningDialog.message}
          </Atoms.Text>
        </Atoms.Alert>
      </div>
    </Content>
  )
}

export default Matrix