import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {LoaderBounce} from "../../Styles/Atoms/LoaderCard";
import Atoms from "../../Atoms"
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return ( 
    <LoaderBounce className="wave" {...props} theme={com.state.theme}><div></div><div></div></LoaderBounce>
  );
};