import { Grid } from "@material-ui/core"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useEffect } from "react"
import Atoms from "../../Atoms"


const FooterCardTrades = (props: any) => {

    return (
        <>
            {props.requestStatus === "send" &&
                <Atoms.Card FooterCardHeight fullHeight>
                    <Grid container item xs alignItems="center" justify="center">
                        <Atoms.Button fullWidth onClick={() => props.onStatusChange("canceled")}>CANCEL REQUEST</Atoms.Button>
                    </Grid>
                </Atoms.Card>
            }

            {["canceled", "rejected", "done", "expired"].includes(props.requestStatus) &&
                <Atoms.Card FooterCardHeight fullHeight>
                    <Grid container item xs alignItems="center" justify="center">
                        <Atoms.Button fullWidth startIcon={<ArrowBackIosIcon />} onClick={() => props.onStatusChange("back")}>
                            Back
                        </Atoms.Button>
                    </Grid>
                </Atoms.Card>
            }
            {props.requestStatus === "waiting" &&
                <Atoms.Card FooterCardHeight fullHeight>
                    <Grid container item xs alignItems="center" justify="space-between" direction="row" >
                        {props.requestType==="rfq"&&<Atoms.Button color="success" thirdWidth onClick={() => props.onStatusChange("done")}>
                            ACCEPT
                            <Atoms.Text block white>SELL</Atoms.Text>
                        </Atoms.Button>}
                        <Atoms.Button color="error" thirdWidth onClick={() => props.onStatusChange("rejected")}>REJECT</Atoms.Button>
                        <Atoms.Button color="success" thirdWidth onClick={() => props.onStatusChange("done")}>
                            ACCEPT
                            {props.requestType==="rfq"&&<Atoms.Text block white>BUY</Atoms.Text>}
                        </Atoms.Button>
                    </Grid>
                </Atoms.Card>
            }
        </>

    )
}

export default (FooterCardTrades)