import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {DialogStyle, DialogActionsStyle, DialogContentStyle, DialogTitleStyle} from "../../Styles/Atoms/Dialog"
import Atoms from "../../Atoms"
import IconButton from '@material-ui/core/IconButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { CloseSharp } from "@material-ui/icons";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return (
      <DialogStyle {...props} theme={com.state.theme}>
        <DialogTitleStyle theme={com.state.theme}>
          {props.title}

          {
            props.showButton && (
              <Atoms.Button onClick={props.buttonClose}>
                <CloseSharp />
              </Atoms.Button>
            )
          }
        </DialogTitleStyle>
        <DialogContentStyle theme={com.state.theme}>
          {/* <DialogContentText id="alert-dialog-description"> */}
            {props.children}
          {/* </DialogContentText> */}
        </DialogContentStyle>
      </DialogStyle>
    );
};
