import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {ContentSelect, Select} from "../../Styles/Atoms/Select";
import Text from "../Text"
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return <ContentSelect {...props} theme={com.state.theme} alignItems={props.alignItems} display={props.display}>
          {/* <div>
            { props.column &&
              <span style={{marginLeft: "4px"}} />
            }
            <Text variant="caption" size="12" fontFamily="book" textCenter textNoWrap noMargin>{props.label}</Text>
          </div> */}
          <Select {...props} name={props.name} theme={com.state.theme} headerTop={props.headerTop}>{props.children}</Select>
        </ContentSelect>
      ;
};
