import { Colors, FontSize, Fonts } from "../../Constants";
import Styled, {css} from "styled-components";

const ContentButton = Styled.div<any>`
  display: ${props => props.display ? "inline-block" : "flex"};
  flex-direction: ${props => props.column ? "column" : "row"};
  align-items: ${props => props.alignItems ? "flex-start" : "flex-end"};
  color: #000000;
  margin-right:${props => props.headerTop ? "10px" : "0px"};
  ${FontSize.sz14};
  vertical-align: top;
  margin-bottom: ${props => props.headerTop ? "0px" : "10px"};
  &div:first-child{
    margin-bottom: 0px !important;
  }
`;

const Button = Styled.button <any>`
  border: 1px solid;
  cursor: pointer;
  border-color: ${props => props.headerTop ? "transparent" : "white"};
  border-radius: ${props => props.headerTop ? "4px" : "4px 0px 0px 4px"};
  background-color: ${props => props.headerTop ? "white" : "black"};
  color: ${props => props.headerTop ? "black" : "white"};
  height: 36px;
  padding-left: 7px;
  text-align: ${props => props.headerTop ? "center" : "left"};
  box-sizing: border-box;
  vertical-align: middle;
  width: 110px;
  ${FontSize.sz18};
  text-transform: uppercase;
  font-weight: ${props => props.headerTop ? "normal" : "bold"};
  -webkit-appearance: none;
  appearance: none;
  moz-appearance: none;
  o-appearance: none;
 
  &:focus{
    outline: none !important;
  }
`

export {ContentButton, Button};