import { Grid } from "@material-ui/core"
import { useState } from "react"
import Atoms from "../../../Atoms"
import { ContentText } from "../../../Styles/Atoms/Text"
import { SortBy } from "../../../Utils/Sort";

const AddDialog = (props: any) => {

    const [selected, setSelected] = useState("");

    const onSelect = (e: any) => {
        setSelected(e.target.value)
    }

    const send = () => {
        props.handleClick(selected)
        setSelected("");
    }

    return (
        <Atoms.Dialog
            open={props.open}
            showButton={true}
            buttonClose={props.onClose}
        >
            <Atoms.Card
                HeaderCardHeight
            >
                <Atoms.Text size="20" fontFamily="bold" bold titleCard textLeft>Add</Atoms.Text>
            </Atoms.Card>

            <Atoms.Card
                bodyCard
                BodyCardHeight
                minHeight
            >
                <ContentText {...props}>
                    <Atoms.Text size="22" fontFamily="book" textLeft white>
                        Par:
                    </Atoms.Text>
                    <Atoms.Select name="select" alignItems display headerTop simple lg onChange={onSelect}>
                        <Atoms.Option value="">Choose an option</Atoms.Option>
                        {(props.currencies && props.currencies.length > 0) &&
                            props.currencies.sort(SortBy)
                                .map((currency: any): any => (
                                    <Atoms.Option value={currency.instrument_id}>{currency.symbol}</Atoms.Option >
                                ))}
                    </Atoms.Select>
                </ContentText>
            </Atoms.Card>

            <Atoms.Card
                FooterCardHeight
            >
                <Grid container item xs={12} alignItems="center" justify="space-between" direction="row" >
                    <Atoms.Button color="error" twiceWidth onClick={props.onClose}>CANCEL</Atoms.Button>
                    <Atoms.Button color="success" twiceWidth onClick={send} disabled={selected === "" ? true : false}
                    >SEND</Atoms.Button>
                </Grid>
            </Atoms.Card>
        </Atoms.Dialog>
    )
}

export default AddDialog