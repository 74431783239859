import React, { useContext, useEffect } from "react"
import Components from "../../Components";
import { ContextBase, SocketMethods } from "../../Hooks";
import { Content } from "../../Styles/Pages/Content"

const EDF = () => {
    
    const com: any = useContext(ContextBase);

    useEffect(() => {
        SocketMethods.rfqUpdate(com.state,com.dispatch);
        SocketMethods.rfsOrderUpdate(com.state,com.dispatch);
        if (localStorage.getItem("rfq_theme_default")) {
          com.dispatch({
            type: "CHANGE_THEME_MODE",
            payload: { theme: localStorage.getItem("rfq_theme_default") },
          });
        }
      }, []);

    return (
        <Content theme={com.state.theme}>
            <Components.CardEDF />
        </Content>
    )
}

export default EDF