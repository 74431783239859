import Styled from "styled-components";
import { Colors, FontSize, Fonts } from "../../Constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const DialogStyle = Styled(Dialog)<any>`
  background-color: ${(props) => Colors[props.theme].layer.main} !important;
  z-index: ${(props) => (props.overlay === "top" ? "9999 !important" : "1300")};
  scrollbar-color: ${(props) => Colors[props.theme].background.scrollFront} ${(
    props
  ) => Colors[props.theme].background.scroll};
  scrollbar-width: thin;

  ::-webkit-scrollbar, * ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-thumb, * ::-webkit-scrollbar-thumb {
    background:  ${(props) => Colors[props.theme].background.scrollFront};
  }

  ::-webkit-scrollbar-track, * ::-webkit-scrollbar-track {
    background: ${(props) => Colors[props.theme].background.scroll};
  }

  .MuiDialog-paper{
    background: ${(props) => Colors[props.theme].background.body} !important;
    border: 0px solid;
    border-color: ${(props) => Colors[props.theme].primary.light} !important;
    padding:0px;
    max-width: 445px;
    max-height: 100%;
    border-radius: 0px;
    @media screen and (max-width:460px){
        margin: 0 !important;
        height:100%;
      }
    
    width: 100%;
  }

  .MuiDialog-scrollPaper {
    align-items: self-start;
  }

  .pushRight{
    position: absolute;
    right: 5px;
    top: 5px;
    color: ${(props) => Colors[props.theme].primary.light} !important;
  }
`;

const DialogActionsStyle = Styled(DialogActions)<any>`

`;

const DialogContentStyle = Styled(DialogContent)<any>`
  color: ${(props) => Colors[props.theme].primary.light} !important;
  padding:0px!important;
`;

const DialogContentTextStyle = Styled(DialogContentText)<any>`
  background-color: ${(props) => Colors[props.theme].warning.light} !important;
`;

const DialogTitleStyle = Styled(DialogTitle)<any>`
  color: ${(props) => Colors[props.theme].primary.light} !important;
  text-align: center;
  h2 {
    display: flex;
    font-family:${Fonts.primaryBook};
    ${FontSize.sz24};
  }

  button {
    border: 0 !important;
    height: 28px;
    min-height: 28px;
    min-width: 24px;
    padding: 0;
    line-height: 0 !important;
    position: absolute;
    top: 3px;
    right: 10px;
  }
`;

export {
  DialogStyle,
  DialogActionsStyle,
  DialogContentStyle,
  DialogContentTextStyle,
  DialogTitleStyle,
};
