import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import {CheckLabel, Check} from "../../Styles/Atoms/Check";
export default (props:any) => {
  let com: any = useContext(ContextBase);
  return <CheckLabel
          labelPlacement="end"
          value={props.value}
          theme={com.state.theme}
          control={
            <Check theme={com.state.theme} />
          }
          label={props.label}
        />
      ;
};
