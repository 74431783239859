import React from "react";
import { useContext } from "react";
import { ContextBase } from "../../Hooks";
import Lottie from 'react-lottie';
import CancelCard from './cancel.json';
import ConfirmCard from './confirm.json';
import RequestCard from './request.json';
export default (props:any) => {
  let com: any = useContext(ContextBase);
    const {animation} = props 
    let animationData = null
    switch (animation) {
      case 'cancel': animationData = CancelCard; break;
      case 'confirm': animationData = ConfirmCard; break;
      case 'request': animationData = RequestCard; break;
    }
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    
    return (
      <div>
        <Lottie {...props}
        options={defaultOptions}
        />
      </div>
    );
  }
    
