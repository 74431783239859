import React, { useContext, useEffect } from "react"
import Components from "../../Components";
import { ContextBase, SocketMethods } from "../../Hooks";
import { Content } from "../../Styles/Pages/Content"

const EDF = () => {
    
    const com = useContext(ContextBase);

    useEffect(() => {
        SocketMethods.rfqmaker(com.state, com.dispatch);
        if (localStorage.getItem("rfq_theme_default")) {
          com.dispatch({
            type: "CHANGE_THEME_MODE",
            payload: { theme: localStorage.getItem("rfq_theme_default") },
          });
        }
      }, []);

    return (
        <Content theme={com.state.theme}>
            <Components.CardCP />
        </Content>
    )
}

export default EDF