import { Grid } from "@material-ui/core"
import { AllInclusive, ArrowForward, Stop } from "@material-ui/icons"
import React from "react"
import Atoms from "../../Atoms"

const FooterCardEDF = () => {
    return (
        <Atoms.Card
            FooterCardHeight
            fullHeight
        >
            <Grid container item justifyContent="space-between">
                <Grid item>
                    <Atoms.Button color="secondary" largeIcon>
                        <ArrowForward fontSize="large" />
                    </Atoms.Button>
                </Grid>
                <Grid item>
                    <Atoms.Button color="secondary" largeIcon>
                        <Stop fontSize="large" />
                    </Atoms.Button>
                </Grid>
                <Grid item>
                    <Atoms.Button color="secondary" largeIcon>
                        <AllInclusive fontSize="large" />
                    </Atoms.Button>
                </Grid>
            </Grid>
        </Atoms.Card>
    )
}

export default FooterCardEDF